import moment from 'moment';

import * as meshbot from '../../constants/MeshbotConstant';
import { IS_DATE_ARGS, MESHBOT_SECTION_TYPE } from '../../containers/Ezlo/EzloMeshbot/constants';
import at from '../../constants/ActionTypes/MeshBot';
import { IS_DATE } from '../../constants/MeshbotConstant';

/**
 * Create date template
 * @param { string } name
 * @param {string} item
 * @returns { function } Date template
 * @example
 * blockDateTemplate(nameItem, type)
 */

const blockDateTemplate = (name, item = '', isSupportsUpdatedAdvancedScenesForWeeks, sunState) => {
    const createMethod = (type, item, sunState) => {
        if (type === 'interval') {
            return { method: { args: { interval: 'interval' }, name: 'isInterval' } };
        } else if (type === 'isOnce') {
            return { method: { args: { day: 'day', month: 'month', time: 'time', year: 'year' }, name: 'isOnce' } };
        } else if (type === 'weekdays' || (type === 'isDate' && item === 'weekdays')) {
            return { method: { args: { time: 'time', type: 'type', weekdays: 'weekdays' }, name: 'isDate' } };
        } else if (type === 'days' || (type === 'isDate' && item === 'days')) {
            return { method: { args: { time: 'time', type: 'type', days: 'days' }, name: 'isDate' } };
        } else if (type === 'isDate' || type === 'daily' || type === meshbot.TIME_OF_DAY) {
            return { method: { args: { time: 'time', type: 'type' }, name: 'isDate' } };
        } else if (item === 'custom' && type !== 'between') {
            return {
                method: {
                    args: { [type]: type },
                    name: 'isDateRange',
                },
            };
        } else if (item === 'custom' && type === 'between') {
            return {
                method: {
                    args: { startTime: 'startTime', endTime: 'endTime' },
                    name: 'isDateRange',
                },
            };
        } else if (type === 'isDate' || type === 'endYear') {
            return {
                method: {
                    args: {
                        startYear: 'startYear',
                        endYear: 'endYear',
                    },
                    name: 'isDateRange',
                },
            };
        } else if (type === 'isDate' || type === 'weeks') {
            return {
                method: {
                    args: {
                        type: 'type',
                        time: 'time',
                        weeks: 'weeks',
                    },
                    name: 'isDate',
                },
            };
        } else if (type === 'isDate' || type === 'yearWeeks') {
            return {
                method: {
                    args: {
                        type: 'type',
                        time: 'time',
                        weeks: 'weeks',
                    },
                    name: 'isDate',
                },
            };
        } else if (
            (type === 'sunrise' && item === 'weekdays') ||
            (type === 'beforeSunrise' && item === 'weekdays') ||
            (type === 'afterSunrise' && item === 'weekdays')
        ) {
            return {
                method: {
                    args: { sunstate: 'sunrise', time: 'time', weekdays: 'weekdays' },
                    name: 'isSunState',
                },
            };
        } else if (
            (type === 'sunrise' && item === 'days') ||
            (type === 'beforeSunrise' && item === 'days') ||
            (type === 'afterSunrise' && item === 'days')
        ) {
            return { method: { args: { sunstate: 'sunrise', time: 'time', days: 'days' }, name: 'isSunState' } };
        } else if (
            (type === 'sunset' && item === 'weekdays') ||
            (type === 'beforeSunset' && item === 'weekdays') ||
            (type === 'afterSunset' && item === 'weekdays')
        ) {
            return { method: { args: { sunstate: 'sunset', time: 'time', weekdays: 'weekdays' }, name: 'isSunState' } };
        } else if (
            (type === 'sunset' && item === 'days') ||
            (type === 'beforeSunset' && item === 'days') ||
            (type === 'afterSunset' && item === 'days')
        ) {
            return { method: { args: { sunstate: 'sunset', time: 'time', days: 'days' }, name: 'isSunState' } };
        } else if (
            type === meshbot.SUNRISE ||
            type === meshbot.SUN_STATE.BEFORE_SUNRISE ||
            type === meshbot.SUN_STATE.AFTER_SUNRISE ||
            (type === meshbot.EMPTY_STRING && sunState === meshbot.SUN_STATE.AFTER_SUNRISE) ||
            (type === meshbot.PLUS && sunState === meshbot.SUN_STATE.AFTER_SUNRISE) ||
            (type === meshbot.MINUS && sunState === meshbot.SUN_STATE.AFTER_SUNRISE) ||
            (type === meshbot.EMPTY_STRING && sunState === meshbot.SUN_STATE.BEFORE_SUNRISE) ||
            (type === meshbot.PLUS && sunState === meshbot.SUN_STATE.BEFORE_SUNRISE) ||
            (type === meshbot.MINUS && sunState === meshbot.SUN_STATE.BEFORE_SUNRISE) ||
            (type === meshbot.EMPTY_STRING && sunState === meshbot.SUNRISE) ||
            (type === meshbot.PLUS && sunState === meshbot.SUNRISE) ||
            (type === meshbot.MINUS && sunState === meshbot.SUNRISE)
        ) {
            return {
                method: {
                    args: { sunstate: meshbot.SUNRISE, time: meshbot.FIELD_NAME, range: meshbot.RANGE_FIELD_NAME },
                    name: meshbot.TRIGGER_TYPES.IS_SUN_STATE,
                },
            };
        } else if (
            type === meshbot.SUNSET ||
            type === meshbot.SUN_STATE.BEFORE_SUNSET ||
            type === meshbot.SUN_STATE.AFTER_SUNSET ||
            (type === meshbot.EMPTY_STRING && sunState === meshbot.SUN_STATE.AFTER_SUNSET) ||
            (type === meshbot.PLUS && sunState === meshbot.SUN_STATE.AFTER_SUNSET) ||
            (type === meshbot.MINUS && sunState === meshbot.SUN_STATE.AFTER_SUNSET) ||
            (type === meshbot.EMPTY_STRING && sunState === meshbot.SUN_STATE.BEFORE_SUNSET) ||
            (type === meshbot.PLUS && sunState === meshbot.SUN_STATE.BEFORE_SUNSET) ||
            (type === meshbot.MINUS && sunState === meshbot.SUN_STATE.BEFORE_SUNSET) ||
            (type === meshbot.EMPTY_STRING && sunState === meshbot.SUNSET) ||
            (type === meshbot.PLUS && sunState === meshbot.SUNSET) ||
            (type === meshbot.MINUS && sunState === meshbot.SUNSET)
        ) {
            return {
                method: {
                    args: { sunstate: meshbot.SUNSET, time: meshbot.FIELD_NAME, range: meshbot.RANGE_FIELD_NAME },
                    name: meshbot.TRIGGER_TYPES.IS_SUN_STATE,
                },
            };
        } else {
            return false;
        }
    };

    const createFields = (type, item, isSupportsUpdatedAdvancedScenesForWeeks, sunState) => {
        const m = moment().utcOffset(0);
        m.set({ hour: 24, minute: 0, second: 0, millisecond: 0 });
        m.toISOString();

        if (type === 'interval') {
            return [{ name: 'interval', startTime: moment().unix(), type: 'interval', value: '1s' }];
        } else if (type === 'isOnce') {
            return [
                { name: 'time', type: '24_hours_time', value: moment().format('hh:mm') },
                { name: 'day', type: 'int', value: Number(moment().format('DD')) },
                { name: 'month', type: 'int', value: Number(moment().format('MM')) },
                { name: 'year', type: 'int', value: Number(moment().format('YYYY')) },
            ];
        } else if (type === 'weekdays' || (type === 'isDate' && item === 'weekdays')) {
            return [
                { name: 'type', type: 'string', value: 'weekly' },
                { name: 'time', type: '24_hours_time_array', value: [moment().format(meshbot.CURRENT_TIME_FORMAT)] },
                { name: 'weekdays', type: 'int_array', value: [moment().isoWeekday()] },
            ];
        } else if (type === 'days' || (type === 'isDate' && item === 'days')) {
            return [
                { name: 'type', type: 'string', value: 'monthly' },
                { name: 'time', type: '24_hours_time_array', value: [moment().format(meshbot.CURRENT_TIME_FORMAT)] },
                { name: 'days', type: 'int_array', value: [moment().date()] },
            ];
        } else if (type === 'isDate' || type === 'daily' || type === meshbot.TIME_OF_DAY) {
            return [
                { name: 'type', type: 'string', value: 'daily' },
                {
                    name: 'time',
                    type: '24_hours_time_array',
                    value: [moment().format(meshbot.CURRENT_TIME_FORMAT)],
                },
            ];
        } else if (item === 'custom' && type !== 'between') {
            if (type === 'startTime' || type === 'endTime') {
                return [
                    {
                        name: type,
                        type: '24_hours_time',
                        value: moment().format(meshbot.CURRENT_TIME_FORMAT),
                    },
                ];
            } else {
                return [{ name: type, type: 'int', value: 0 }];
            }
        } else if (item === 'custom' && type === 'between') {
            return [
                { name: 'startTime', type: '24_hours_time', value: moment().format(meshbot.CURRENT_TIME_FORMAT) },
                { name: 'endTime', type: '24_hours_time', value: moment().format(meshbot.CURRENT_TIME_FORMAT) },
            ];
        } else if (type === 'isDate' || type === 'endYear') {
            return [
                {
                    name: 'startYear',
                    type: 'int',
                    value: meshbot.YEAR,
                },
                {
                    name: 'endYear',
                    type: 'int',
                    value: meshbot.YEAR,
                },
            ];
        } else if (type === 'isDate' || type === 'weeks') {
            if (!isSupportsUpdatedAdvancedScenesForWeeks) {
                return [
                    {
                        name: 'type',
                        type: 'string',
                        value: 'weeks',
                    },
                    {
                        name: 'time',
                        type: '24_hours_time_array',
                        value: [moment().format(meshbot.CURRENT_TIME_FORMAT)],
                    },
                    {
                        name: 'weeks',
                        type: 'int_array',
                        value: [],
                    },
                ];
            }

            return [
                {
                    name: 'type',
                    type: 'string',
                    value: 'weeks',
                },
                {
                    name: 'weeks',
                    type: 'int_array',
                    value: [],
                },
            ];
        } else if (type === 'isDate' || type === 'yearWeeks') {
            if (!isSupportsUpdatedAdvancedScenesForWeeks) {
                return [
                    {
                        name: 'type',
                        type: 'string',
                        value: 'yearWeeks',
                    },
                    {
                        name: 'time',
                        type: '24_hours_time_array',
                        value: [moment().format(meshbot.CURRENT_TIME_FORMAT)],
                    },
                    {
                        name: 'weeks',
                        type: 'int_array',
                        value: [],
                    },
                ];
            }

            return [
                {
                    name: 'type',
                    type: 'string',
                    value: 'yearWeeks',
                },
                {
                    name: 'weeks',
                    type: 'int_array',
                    value: [],
                },
            ];
        } else if (type === 'sunrise' && item === 'weekdays') {
            return [
                { name: 'sunrise', type: 'string', value: 'intime' },
                { name: 'weekdays', type: 'int_array', value: [] },
            ];
        } else if (type === 'sunrise' && item === 'days') {
            return [
                { name: 'sunrise', type: 'string', value: 'intime' },
                { name: 'days', type: 'int_array', value: [] },
            ];
        } else if (type === meshbot.SUNRISE || (type === meshbot.EMPTY_STRING && sunState === meshbot.SUNRISE)) {
            return [
                { name: meshbot.SUNRISE, type: meshbot.STRING, value: meshbot.IN_TIME },
                { name: meshbot.RANGE_FIELD_NAME, type: meshbot.VALUE_TYPES.TOKEN, value: meshbot.AT },
            ];
        } else if (type === meshbot.PLUS && sunState === meshbot.SUNRISE) {
            return [
                { name: meshbot.SUNRISE, type: meshbot.STRING, value: meshbot.AFTER },
                {
                    name: meshbot.FIELD_NAME,
                    type: meshbot.TIME_TYPE_HMS_INTERVAL,
                    value: m.format(meshbot.TIME_FORMAT),
                },
                { name: meshbot.RANGE_FIELD_NAME, type: meshbot.VALUE_TYPES.TOKEN, value: meshbot.AT },
            ];
        } else if (type === meshbot.MINUS && sunState === meshbot.SUNRISE) {
            return [
                { name: meshbot.SUNRISE, type: meshbot.STRING, value: meshbot.BEFORE },
                {
                    name: meshbot.FIELD_NAME,
                    type: meshbot.TIME_TYPE_HMS_INTERVAL,
                    value: m.format(meshbot.TIME_FORMAT),
                },
                { name: meshbot.RANGE_FIELD_NAME, type: meshbot.VALUE_TYPES.TOKEN, value: meshbot.AT },
            ];
        } else if (type === 'beforeSunrise' && item === 'weekdays') {
            return [
                { name: 'sunrise', type: 'string', value: 'before' },
                { name: 'time', type: 'hms_interval', value: m.format('HH:mm:ss') },
                { name: 'weekdays', type: 'int_array', value: [] },
            ];
        } else if (type === 'beforeSunrise' && item === 'days') {
            return [
                { name: 'sunrise', type: 'string', value: 'before' },
                { name: 'time', type: 'hms_interval', value: m.format('HH:mm:ss') },
                { name: 'days', type: 'int_array', value: [] },
            ];
        } else if (
            type === meshbot.SUN_STATE.BEFORE_SUNRISE ||
            (type === meshbot.EMPTY_STRING && sunState === meshbot.SUN_STATE.BEFORE_SUNRISE)
        ) {
            return [
                { name: meshbot.SUNRISE, type: meshbot.STRING, value: meshbot.IN_TIME },
                { name: meshbot.RANGE_FIELD_NAME, type: meshbot.VALUE_TYPES.TOKEN, value: meshbot.BEFORE },
            ];
        } else if (type === meshbot.PLUS && sunState === meshbot.SUN_STATE.BEFORE_SUNRISE) {
            return [
                { name: meshbot.SUNRISE, type: meshbot.STRING, value: meshbot.AFTER },
                {
                    name: meshbot.FIELD_NAME,
                    type: meshbot.TIME_TYPE_HMS_INTERVAL,
                    value: m.format(meshbot.TIME_FORMAT),
                },
                { name: meshbot.RANGE_FIELD_NAME, type: meshbot.VALUE_TYPES.TOKEN, value: meshbot.BEFORE },
            ];
        } else if (type === meshbot.MINUS && sunState === meshbot.SUN_STATE.BEFORE_SUNRISE) {
            return [
                { name: meshbot.SUNRISE, type: meshbot.STRING, value: meshbot.BEFORE },
                {
                    name: meshbot.FIELD_NAME,
                    type: meshbot.TIME_TYPE_HMS_INTERVAL,
                    value: m.format(meshbot.TIME_FORMAT),
                },
                { name: meshbot.RANGE_FIELD_NAME, type: meshbot.VALUE_TYPES.TOKEN, value: meshbot.BEFORE },
            ];
        } else if (type === 'afterSunrise' && item === 'weekdays') {
            return [
                { name: 'sunrise', type: 'string', value: 'after' },
                { name: 'time', type: 'hms_interval', value: m.format('HH:mm:ss') },
                { name: 'weekdays', type: 'int_array', value: [] },
            ];
        } else if (type === 'afterSunrise' && item === 'days') {
            return [
                { name: 'sunrise', type: 'string', value: 'after' },
                { name: 'time', type: 'hms_interval', value: m.format('HH:mm:ss') },
                { name: 'days', type: 'int_array', value: [] },
            ];
        } else if (
            type === meshbot.SUN_STATE.AFTER_SUNRISE ||
            (type === meshbot.EMPTY_STRING && sunState === meshbot.SUN_STATE.AFTER_SUNRISE)
        ) {
            return [
                { name: meshbot.SUNRISE, type: meshbot.STRING, value: meshbot.IN_TIME },
                { name: meshbot.RANGE_FIELD_NAME, type: meshbot.VALUE_TYPES.TOKEN, value: meshbot.AFTER },
            ];
        } else if (type === meshbot.PLUS && sunState === meshbot.SUN_STATE.AFTER_SUNRISE) {
            return [
                { name: meshbot.SUNRISE, type: meshbot.STRING, value: meshbot.AFTER },
                {
                    name: meshbot.FIELD_NAME,
                    type: meshbot.TIME_TYPE_HMS_INTERVAL,
                    value: m.format(meshbot.TIME_FORMAT),
                },
                { name: meshbot.RANGE_FIELD_NAME, type: meshbot.VALUE_TYPES.TOKEN, value: meshbot.AFTER },
            ];
        } else if (type === meshbot.MINUS && sunState === meshbot.SUN_STATE.AFTER_SUNRISE) {
            return [
                { name: meshbot.SUNRISE, type: meshbot.STRING, value: meshbot.BEFORE },
                {
                    name: meshbot.FIELD_NAME,
                    type: meshbot.TIME_TYPE_HMS_INTERVAL,
                    value: m.format(meshbot.TIME_FORMAT),
                },
                { name: meshbot.RANGE_FIELD_NAME, type: meshbot.VALUE_TYPES.TOKEN, value: meshbot.AFTER },
            ];
        } else if (type === 'sunset' && item === 'weekdays') {
            return [
                { name: 'sunset', type: 'string', value: 'intime' },
                { name: 'weekdays', type: 'int_array', value: [] },
            ];
        } else if (type === 'sunset' && item === 'days') {
            return [
                { name: 'sunset', type: 'string', value: 'intime' },
                { name: 'days', type: 'int_array', value: [] },
            ];
        } else if (type === meshbot.SUNSET || (type === meshbot.EMPTY_STRING && sunState === meshbot.SUNSET)) {
            return [
                { name: meshbot.SUNSET, type: meshbot.STRING, value: meshbot.IN_TIME },
                { name: meshbot.RANGE_FIELD_NAME, type: meshbot.VALUE_TYPES.TOKEN, value: meshbot.AT },
            ];
        } else if (type === meshbot.PLUS && sunState === meshbot.SUNSET) {
            return [
                { name: meshbot.SUNSET, type: meshbot.STRING, value: meshbot.AFTER },
                {
                    name: meshbot.FIELD_NAME,
                    type: meshbot.TIME_TYPE_HMS_INTERVAL,
                    value: m.format(meshbot.TIME_FORMAT),
                },
                { name: meshbot.RANGE_FIELD_NAME, type: meshbot.VALUE_TYPES.TOKEN, value: meshbot.AT },
            ];
        } else if (type === meshbot.MINUS && sunState === meshbot.SUNSET) {
            return [
                { name: meshbot.SUNSET, type: meshbot.STRING, value: meshbot.BEFORE },
                {
                    name: meshbot.FIELD_NAME,
                    type: meshbot.TIME_TYPE_HMS_INTERVAL,
                    value: m.format(meshbot.TIME_FORMAT),
                },
                { name: meshbot.RANGE_FIELD_NAME, type: meshbot.VALUE_TYPES.TOKEN, value: meshbot.AT },
            ];
        } else if (type === 'beforeSunset' && item === 'weekdays') {
            return [
                { name: 'sunset', type: 'string', value: 'before' },
                { name: 'time', type: 'hms_interval', value: m.format('HH:mm:ss') },
                { name: 'weekdays', type: 'int_array', value: [] },
            ];
        } else if (type === 'beforeSunset' && item === 'days') {
            return [
                { name: 'sunset', type: 'string', value: 'before' },
                { name: 'time', type: 'hms_interval', value: m.format('HH:mm:ss') },
                { name: 'days', type: 'int_array', value: [] },
            ];
        } else if (
            type === meshbot.SUN_STATE.BEFORE_SUNSET ||
            (type === meshbot.EMPTY_STRING && sunState === meshbot.SUN_STATE.BEFORE_SUNSET)
        ) {
            return [
                { name: meshbot.SUNSET, type: meshbot.STRING, value: meshbot.IN_TIME },
                { name: meshbot.RANGE_FIELD_NAME, type: meshbot.VALUE_TYPES.TOKEN, value: meshbot.BEFORE },
            ];
        } else if (type === meshbot.PLUS && sunState === meshbot.SUN_STATE.BEFORE_SUNSET) {
            return [
                { name: meshbot.SUNSET, type: meshbot.STRING, value: meshbot.AFTER },
                {
                    name: meshbot.FIELD_NAME,
                    type: meshbot.TIME_TYPE_HMS_INTERVAL,
                    value: m.format(meshbot.TIME_FORMAT),
                },
                { name: meshbot.RANGE_FIELD_NAME, type: meshbot.VALUE_TYPES.TOKEN, value: meshbot.BEFORE },
            ];
        } else if (type === meshbot.MINUS && sunState === meshbot.SUN_STATE.BEFORE_SUNSET) {
            return [
                { name: meshbot.SUNSET, type: meshbot.STRING, value: meshbot.BEFORE },
                {
                    name: meshbot.FIELD_NAME,
                    type: meshbot.TIME_TYPE_HMS_INTERVAL,
                    value: m.format(meshbot.TIME_FORMAT),
                },
                { name: meshbot.RANGE_FIELD_NAME, type: meshbot.VALUE_TYPES.TOKEN, value: meshbot.BEFORE },
            ];
        } else if (type === 'afterSunset' && item === 'weekdays') {
            return [
                { name: 'sunset', type: 'string', value: 'after' },
                { name: 'time', type: 'hms_interval', value: m.format('HH:mm:ss') },
                { name: 'weekdays', type: 'int_array', value: [] },
            ];
        } else if (type === 'afterSunset' && item === 'days') {
            return [
                { name: 'sunset', type: 'string', value: 'after' },
                { name: 'time', type: 'hms_interval', value: m.format('HH:mm:ss') },
                { name: 'days', type: 'int_array', value: [] },
            ];
        } else if (
            type === meshbot.SUN_STATE.AFTER_SUNSET ||
            (type === meshbot.EMPTY_STRING && sunState === meshbot.SUN_STATE.AFTER_SUNSET)
        ) {
            return [
                { name: meshbot.SUNSET, type: meshbot.STRING, value: meshbot.IN_TIME },
                { name: meshbot.RANGE_FIELD_NAME, type: meshbot.VALUE_TYPES.TOKEN, value: meshbot.AFTER },
            ];
        } else if (type === meshbot.PLUS && sunState === meshbot.SUN_STATE.AFTER_SUNSET) {
            return [
                { name: meshbot.SUNSET, type: meshbot.STRING, value: meshbot.AFTER },
                {
                    name: meshbot.FIELD_NAME,
                    type: meshbot.TIME_TYPE_HMS_INTERVAL,
                    value: m.format(meshbot.TIME_FORMAT),
                },
                { name: meshbot.RANGE_FIELD_NAME, type: meshbot.VALUE_TYPES.TOKEN, value: meshbot.AFTER },
            ];
        } else if (type === meshbot.MINUS && sunState === meshbot.SUN_STATE.AFTER_SUNSET) {
            return [
                { name: meshbot.SUNSET, type: meshbot.STRING, value: meshbot.BEFORE },
                {
                    name: meshbot.FIELD_NAME,
                    type: meshbot.TIME_TYPE_HMS_INTERVAL,
                    value: m.format(meshbot.TIME_FORMAT),
                },
                { name: meshbot.RANGE_FIELD_NAME, type: meshbot.VALUE_TYPES.TOKEN, value: meshbot.AFTER },
            ];
        }
    };

    return {
        blockOptions: createMethod(name, item, sunState),
        blockType: 'when',
        fields: createFields(name, item, isSupportsUpdatedAdvancedScenesForWeeks, sunState),
    };
};

export default blockDateTemplate;

/**
 * Updates time property of a field in a global restriction block if its method is 'IS_DATE'.
 *
 * @param {object} block - An object representing the block which could be updated.
 */
const updateGlobalRestrictionDataTimeBlock = (block) => {
    if (block.blockOptions.method.name !== IS_DATE) {
        return;
    }
    const timeField = block.fields?.find((field) => field.name === IS_DATE_ARGS.TIME);
    if (timeField) {
        timeField.value = [];
    }
};

/**
 * Returns a data block based on the given value and actionId.
 * If value is neither 'CUSTOM' nor 'DAILY' and actionId matches 'GLOBAL_RESTRICTION', the block date template is updated.
 *
 * @param {string} value - Value that specifies the operation type.
 * @param {string} actionId - Action identifier that may affect the generated data block.
 * @return {object[]} - The updated block as part of an array or null.
 */
export const getDataTimeBlock = (value, actionId) => {
    if (value === at.CUSTOM || value === at.DAILY) {
        return null;
    }
    const isGlobalRestriction = actionId === MESHBOT_SECTION_TYPE.GLOBAL_RESTRICTION;
    const block = blockDateTemplate(value);

    if (isGlobalRestriction) {
        updateGlobalRestrictionDataTimeBlock(block);
    }

    return [block];
};
