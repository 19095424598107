import * as types from '../constants/ActionTypes';
import * as controllerTypes from '../constants/ActionTypes/Controller';
import DeviceActions from './DeviceActions';
import GenericActions from './GenericActions';
import MeshBotActions from './MeshBotAction';
import { SubscriptionActions } from '.';
import { DEFAULT_EZLO_LOCATION } from '../constants/GeoLocation';
import wsm from '../helpers/wsm';
import { apiDeviceInfo, apiDevices } from '../api/apiDevices';
import { getDefaultControllerInGroupData } from '../helpers/groupUtils';
import { isControllerSupportedBySerial } from '../helpers/validation';
import { apiRemoveController } from '../api/apiController';
import GroupsActions from './GroupsActions';
// import { t } from '../helpers/language';
import { allSettled, getAllSettledValues } from '../helpers/ezloUtils';
import { bugsnagNotify, bugsnagNotifyWrapper } from '../containers/ErrorBoundary/utils';
import { toast, TOAST_TYPE } from '../components/Toast';
import { PK_ACCOUNT_CHILD_EMPTY } from '../constants/Devices';
import DeviceGroupsActions from './DeviceGroupsActions';
import ExpressionsActions from './ExpressionsActions';
import ItemGroupsActions from './ItemGroupsActions';
import { SUBSCRIPTIONS_STATUS } from '../constants/Subscription';
import { EZLOGIC_TOAST_MESHBOT_SUCCESSFULLY_DELETED } from '../constants/language_tokens';
import { t } from '../helpers/language';
import { TOAST_MESSAGE_ON_ERROR } from '../constants/toasts';
import { ADVANCED_SCENES } from '../constants/ControllerFeatures';
import { buildItemByHubItemDictionaryUpdatedBroadcast, getOnlineControllers } from '../containers/Ezlo/utils';
import { hub, logsCommandNames } from '../services/hub';
import { buildLogsParams } from '../containers/Ezlo/EzloGroups/utils';
import { isObject } from 'lodash';
import ItemActions from './ItemActions';
import { sortById } from '../containers/Ezlo/EzloMeshbots/utils';

//------------------------------------------- API ACTIONS SECTION --------------------------------------------------//

const actions = {
    register: (serial) => () =>
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'register',
                { serial },
                (result) => resolve(result),
                (error) => {
                    bugsnagNotify(error, { serial });
                    toast(error, { type: TOAST_TYPE.ERROR });
                    reject(error);
                },
            );
        }),

    isEzloConnected: (serial) => (dispatch) =>
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'isEzloConnected',
                { serial: serial },
                (data) => resolve(data),
                (error) => {
                    bugsnagNotify(error, { serial });
                    toast(error, { type: TOAST_TYPE.ERROR });
                    dispatch(GenericActions.showError(error));
                    reject();
                },
            );
        }),

    isEzloRegistered: (serial) => (dispatch) =>
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'isEzloRegistered',
                { serial: serial },
                (data) => resolve(data),
                (error) => {
                    bugsnagNotify(error, { serial });
                    toast(error, { type: TOAST_TYPE.ERROR });
                    dispatch(GenericActions.showError(error));
                    reject();
                },
            );
        }),

    getEzloInfo: (serial) => () =>
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'hub.info.get',
                {},
                (success) => resolve(success),
                (error) => {
                    bugsnagNotify(error, { serial });
                    reject(error);
                },
            );
        }),

    getEzloFeatures: (serial) => () =>
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'hub.features.list',
                {},
                (success) => resolve(success),
                (error) => {
                    bugsnagNotify(error, { serial });
                    reject(error);
                },
            );
        }),

    getEzloDeviceSettings: (serial) => () =>
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'hub.device.settings.list',
                {},
                (success) => {
                    resolve(success);
                },
                (error) => {
                    bugsnagNotify(error, { serial });
                    reject(error);
                },
            );
        }),

    getEzloSettings: (serial) => () =>
        new Promise((resolve) => {
            wsm.send(
                serial,
                'hub.settings.list',
                {},
                (success) => {
                    resolve(success);
                },
                (error) => {
                    bugsnagNotify(error, { serial });
                    resolve([]);
                },
            );
        }),

    setEzloOrder: (serial, ezlos) => (dispatch) =>
        new Promise((resolve, reject) => {
            const serials = ezlos.map((ezlo) => ezlo.serial);

            wsm.send(
                serial,
                'setEzloOrder',
                { serials },
                () => {
                    dispatch(actions.updateEzlos(ezlos));
                },
                (error) => {
                    bugsnagNotify(error, { serial });
                    reject(error);
                },
            );
        }),

    disableEzlo: (serial, disabled) => (dispatch) =>
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'setDisabled',
                {
                    disabled,
                    serial,
                },
                () => {
                    resolve();
                },
                (error) => {
                    bugsnagNotify(error, { serial, disabled });
                    dispatch(GenericActions.showError(error));
                    reject(error);
                },
            );
        }),

    cleanEzlo: (serial) => () =>
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'cleanEzlo',
                {},
                (success) => resolve(success),
                (error) => {
                    bugsnagNotify(error, { serial });
                    reject(error);
                },
            );
        }),

    powerOffEzlo: (serial) => () =>
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'powerOffEzlo',
                { serial },
                (success) => resolve(success),
                (error) => {
                    bugsnagNotify(error, { serial });
                    reject(error);
                },
            );
        }),

    rebootEzlo: (serial) => (dispatch) =>
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'hub.reboot',
                { serial },
                (success) => {
                    dispatch(actions.disconnectEzloSuccess(serial));
                    resolve(success);
                },
                (error) => {
                    bugsnagNotify(error, { serial });
                    reject(error);
                },
            );
        }),

    unbindOfflineEzlo: (serial) => () =>
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'unbindOfflineEzlo',
                {
                    serial,
                },
                (success) => resolve(success),
                (error) => {
                    bugsnagNotify(error, { serial });
                    reject(error);
                },
            );
        }),

    removeController: (serial, group, isControllerOnline, deleteGroup, groupKey, t) => async (dispatch) => {
        try {
            if (group) {
                const controllers = group.controllers.filter(({ id }) => id !== serial);
                const params = {
                    ...group,
                    controllers: controllers.map(({ id }) => ({ id: Number(id) })),
                };

                if (controllers.length >= 2) {
                    await dispatch(actions.deletingGroupController(serial, isControllerOnline, params, groupKey));

                    dispatch(GenericActions.showNotification(t`dialog_title_hub_removed`));
                } else {
                    const message =
                        'If the group has less than 2 controllers it will be deleted and its controllers will become unassigned.';
                    const stayedController = controllers[0].id;
                    const deletingGroupController = async () => {
                        await dispatch(
                            actions.deletingController(serial, isControllerOnline, groupKey, stayedController),
                        );

                        dispatch(GenericActions.showNotification(t`dialog_title_hub_removed`));
                    };
                    deleteGroup(group, message, deletingGroupController);
                }
            } else {
                await dispatch(actions.deletingController(serial, isControllerOnline));
                dispatch(GenericActions.showNotification(t`dialog_title_hub_removed`));
            }
        } catch (e) {
            bugsnagNotify(e, { serial, group, isControllerOnline, deleteGroup, groupKey });
            dispatch(GenericActions.showError(e));
        }
    },

    deletingGroupController: (serial, isControllerOnline, params, groupKey) => async (dispatch) => {
        await dispatch(GroupsActions.updateControllerGroup(params));
        await dispatch(actions.deletingController(serial, isControllerOnline, groupKey));
        await dispatch(GroupsActions.connectActiveControllerGroup(params));
    },

    clearReduxState: (serial, groupKey, stayedController) => (dispatch) => {
        dispatch(GroupsActions.clearGroupsStateFromController(serial, groupKey));
        dispatch(actions.removeEzloFromState(serial, stayedController));
    },

    deletingController: (serial, isControllerOnline, groupKey, stayedController) => async (dispatch) => {
        await apiRemoveController(serial);

        if (isControllerOnline) {
            await wsm.close(serial);
        }

        await dispatch(actions.clearReduxState(serial, groupKey, stayedController));
    },

    setDeviceName: (deviceId, name, serial) => () => {
        return wsm.send(
            serial,
            'hub.device.name.set',
            { _id: deviceId, name },
            () => {},
            (error) => {
                bugsnagNotify(error, { serial, deviceId, name });
            },
        );
    },

    setEzloProperties:
        (properties, serial, callback = () => {}) =>
        (dispatch) =>
            new Promise((resolve, reject) => {
                wsm.send(
                    serial,
                    'hub.setting.value.set',
                    { properties, serial },
                    (data) => {
                        dispatch(actions.updateEzloProperties(serial, properties));
                        resolve(data);
                        callback(data);
                    },
                    (error) => {
                        bugsnagNotify(error, { serial, properties });
                        reject(error);
                        callback(error);
                    },
                );
            }),

    /**
     * Action creator for setting an Ezlo hub property.
     *
     * @function
     * @param {string} serial - Serial number of the Ezlo hub.
     * @param {Object} params - Parameters for setting the Ezlo property.
     * @param {string} params.name - Name of the property to be set.
     * @param {string|boolean} params.value - Value to set for the property.
     *
     * @returns {Function} A Redux Thunk function that dispatches actions or performs asynchronous operations.
     */
    setEzloProperty:
        (serial, params = {}) =>
        (dispatch, getState) => {
            /**
             * If the property to be set is related to logs, invoke the local log setting method.
             * @see { https://log.ezlo.com/new/hub/logging/#hubloglocalset }
             */
            if (logsCommandNames.includes(params.name)) {
                const settings = getState().ezlo?.data?.[serial]?.properties?.settings || [];

                /**
                 * Set local logs on the hub using the hub.log.local.set method.
                 */
                return hub.log.local.set(
                    serial,
                    buildLogsParams(settings, params),
                    () => dispatch(actions.loadEzloDataNew(serial)),
                    (error) => bugsnagNotify(error, { serial, params }),
                );
            }

            /**
             * If the property is not related to logs, set it using the WebSocket Manager (wsm) send method.
             */
            return new Promise((resolve, reject) => {
                wsm.send(
                    serial,
                    'hub.setting.value.set',
                    params,
                    (data) => {
                        dispatch(actions.loadEzloDataNew(serial));
                        resolve(data);
                    },
                    (error) => {
                        bugsnagNotify(error, { serial, params });
                        reject(error);
                    },
                );
            });
        },

    setEzloImage: (imageId, serial) => (dispatch) =>
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'setEzloImage',
                { imageId, serial },
                (data) => {
                    dispatch(actions.updateEzloImage(imageId));
                    resolve(data);
                },
                (error) => {
                    bugsnagNotify(error, { serial, imageId });
                    reject(error);
                },
            );
        }),

    dispatchEzloImage: (imageId) => (dispatch) => dispatch(actions.updateEzloImage(imageId)),

    // todo
    findEzlo: () => () =>
        new Promise(() => {
            // $.get('https://api.ipify.org/').then(ezlo_ip => {
            //     wsm.send(serial, 'findEzlo',
            //         { ezlo_ip },
            //         success => resolve(success.ezlos),
            //     );
            // });
        }),

    blinkEzlo: (serial) => () => {
        wsm.send(serial, 'setLed', {
            serial,
            effect: 'selectEzlo',
        });
    },

    restoreReplication: () => () =>
        new Promise((resolve) => {
            resolve({});
            // wsm.send(restoreFromSerial, 'restoreReplication',
            //     { restoreFromSerial },
            //     () => {
            //         resolve();
            //     },
            //     (error) => {
            //         dispatch(GenericActions.showError(error));
            //         reject(error);
            //     });
        }),

    updateEzloFirmware: (serial, version) => (dispatch) => {
        wsm.send(
            serial,
            'updateEzloFirmware',
            {
                serial,
                version,
            },
            () => {},
            (error) => {
                bugsnagNotify(error, { serial, version });
                dispatch(GenericActions.showError(error));
            },
        );
    },

    setAutoFirmwareUpdate: (serial, autoFirmwareUpdate) => (dispatch) => {
        wsm.send(
            serial,
            'setAutoFirmwareUpdate',
            {
                serial,
                autoFirmwareUpdate,
            },
            () => {
                dispatch(actions.updateAutoFirmwareUpdateState(autoFirmwareUpdate));
            },
            (error) => {
                bugsnagNotify(error, { serial, autoFirmwareUpdate });
                dispatch(GenericActions.showError(error));
            },
        );
    },

    getRooms: (serial) => () =>
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'hub.room.list',
                {},
                (success) => resolve(success),
                (error) => {
                    bugsnagNotify(error, { serial });
                    reject(error);
                },
            );
        }),

    getGateways: (serial) => () =>
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'hub.gateways.list',
                {},
                (success) => resolve(success),
                (error) => {
                    bugsnagNotify(error, { serial });
                    reject(error);
                },
            );
        }),

    getDevices: (serial) => () =>
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'hub.devices.list',
                {},
                (success) => resolve(success),
                (error) => {
                    bugsnagNotify(error, { serial });
                    reject(error);
                },
            );
        }),

    getItems: (serial) => () =>
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'hub.items.list',
                {},
                (success) => resolve(success),
                (error) => {
                    bugsnagNotify(error, { serial });
                    reject(error);
                },
            );
        }),

    getNodes: (serial) => () =>
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'hub.custom.nodes.list',
                {
                    showMeta: true,
                    showAdvanced: true,
                },
                (success) => resolve(success),
                (error) => {
                    bugsnagNotify(error, { serial });
                    reject(error);
                },
                true,
            );
        }),

    getReplication: (serial) => () =>
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'hub.modes.get',
                {},
                (success) => resolve(success),
                (error) => {
                    bugsnagNotify(error, { serial });
                    reject(error);
                },
            );
        }),

    getListRules: (serial) => (dispatch) =>
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'hub.scenes.list',
                {},
                (result) => {
                    if (result && result.scenes) {
                        const sortedData = result.scenes.sort(sortById);
                        dispatch(actions.updateEzlosListScenes(serial, sortedData));
                    }
                    resolve(result);
                },
                (result) => resolve(result),
                (error) => {
                    bugsnagNotify(error, { serial });
                    reject(error);
                },
            );
        }),

    getNetworkInterfaces: (serial) => () =>
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'hub.network.get',
                {},
                (success) => resolve(success),
                (error) => {
                    bugsnagNotify(error, { serial });
                    reject(error);
                },
            );
        }),

    setTemperatureUnits: (serial, temperatureUnits) => (dispatch) =>
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'setTemperatureUnits',
                {
                    serial,
                    temperatureUnits,
                },
                (success) => {
                    resolve(success);
                    dispatch(actions.updateTemperatureUnits(serial, temperatureUnits));
                },
                (error) => {
                    bugsnagNotify(error, { serial });
                    reject(error);
                },
            );
        }),

    listRuleBlocks:
        (serial, { room_id, block_type, condition_filter }) =>
        () =>
            new Promise((resolve, reject) => {
                wsm.send(
                    serial,
                    'hub.scenes.list',
                    { room_id, block_type, condition_filter },
                    (blocks) => resolve(blocks),
                    (error) => {
                        bugsnagNotify(error, { serial });
                        reject(error);
                    },
                );
            }),

    createRule: (serial, rule) => (dispatch) => {
        return new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'hub.scenes.create',
                rule,
                () => {
                    dispatch(actions.getListRules(serial));
                    resolve();
                },
                (error) => {
                    bugsnagNotify(error, { serial, rule });
                    toast(error.message, { type: TOAST_TYPE.ERROR });
                    reject(error);
                },
            );
        });
    },

    editRule: (serial, rule) => (dispatch) =>
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'hub.scenes.edit',
                { _id: rule._id, eo: rule },
                () => {
                    // dispatch(actions.updateRule(rule._id, { ...rule }));
                    dispatch(actions.getListRules(serial));

                    resolve();
                },
                (error) => {
                    bugsnagNotify(error, { serial, rule });
                    reject(error);
                },
            );
        }),

    duplicateRule: (serial, rule) => (dispatch) => {
        return new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'hub.scenes.clone',
                rule,
                () => {
                    dispatch(actions.getListRules(serial));
                    resolve();
                },
                (error) => {
                    bugsnagNotifyWrapper(error, { serial, rule });
                    toast(error.message, { type: TOAST_TYPE.ERROR });
                    reject(error);
                },
            );
        });
    },

    setRuleImage: (serial, _id, imageId) => (dispatch) =>
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'hub.scenes.icon.set',
                {
                    _id,
                    imageId,
                },
                () => {
                    dispatch(actions.updateRule(serial, _id, { imageId }));

                    resolve();
                },
                (error) => {
                    bugsnagNotify(error, { serial, _id, imageId });
                    reject(error);
                },
            );
        }),

    deleteRule: (serial, rule) => (dispatch) =>
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'hub.scenes.delete',
                { _id: rule._id },
                () => {
                    dispatch(actions.removeRule(serial, rule._id));
                    const name = rule.name ? rule.name : '';
                    const errorMessage = `${name} ${t(EZLOGIC_TOAST_MESHBOT_SUCCESSFULLY_DELETED)}`;
                    toast(errorMessage, { type: TOAST_TYPE.SUCCESS });

                    resolve();
                },
                (error) => {
                    bugsnagNotify(error, { serial, rule });
                    reject(error);
                },
            );
        }),

    /**
     * Thunk creator that return thunk that delete local MeshBot
     * @param {Object} meshBot - local meshbot data
     * @returns {Function} returns thunk that delete local MeshBot
     * @example
     * dispatch(deleteLocalMeshBot({id:'412', name: 'newMeshbot', serial: '900745'}))
     * */
    deleteLocalMeshBot:
        ({ id, name, serial }) =>
        async (dispatch) => {
            try {
                const params = { _id: id, name };
                await dispatch(actions.deleteRule(serial, params));
            } catch (error) {
                const errorMessage = error.message || JSON.stringify(error) || t(TOAST_MESSAGE_ON_ERROR);
                toast(`${name}. ${errorMessage}`, { type: TOAST_TYPE.ERROR });
            }
        },

    onRule: (serial, _id) => (dispatch) =>
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'hub.scenes.enabled.set',
                {
                    _id,
                    enabled: true,
                },
                () => {
                    dispatch(actions.updateRule(serial, _id, { enabled: true }));

                    resolve();
                },
                (error) => {
                    bugsnagNotify(error, { serial, _id });
                    reject(error);
                },
            );
        }),

    offRule: (serial, _id) => (dispatch) =>
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'hub.scenes.enabled.set',
                {
                    _id,
                    enabled: false,
                },
                () => {
                    dispatch(actions.updateRule(serial, _id, { enabled: false }));

                    resolve();
                },
                (error) => {
                    bugsnagNotify(error, { serial, _id });
                    reject(error);
                },
            );
        }),

    onRuleRun: (serial, _id) => () =>
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'hub.scenes.run',
                {
                    sceneId: _id,
                },
                () => {
                    resolve();
                },
                (error) => {
                    bugsnagNotify(error, { serial, _id });
                    reject(error);
                },
            );
        }),

    // TODO: apply refactoring
    getEzlos: (userData) => (dispatch) =>
        new Promise((resolve, reject) => {
            const { PK_Account, PK_AccountChild } = userData;
            const currentPK_Account =
                PK_Account && PK_AccountChild != PK_ACCOUNT_CHILD_EMPTY ? PK_AccountChild : PK_Account;

            if (currentPK_Account) {
                apiDevices(currentPK_Account)
                    .then((res) => {
                        if (res) {
                            const resDevices = res.data.Devices;
                            const data =
                                resDevices && resDevices.filter((i) => isControllerSupportedBySerial(i.PK_Device));
                            const ezloInfoData = [];

                            Array.isArray(resDevices) &&
                                resDevices.map((device) => {
                                    const { PK_Device } = device;

                                    dispatch(actions.getEzlosInfo(PK_Device))
                                        .then((res) => {
                                            if (res) {
                                                const ezloDeviceData = {
                                                    ...device,
                                                    ...res,
                                                    serial: PK_Device,
                                                    connected: !!res.NMAControllerStatus,
                                                };

                                                ezloInfoData.push(ezloDeviceData);

                                                if (ezloInfoData.length === data.length) {
                                                    dispatch(actions.receiveEzlos(ezloInfoData));
                                                    resolve(ezloInfoData);
                                                }
                                            }
                                        })
                                        .catch((err) => {
                                            bugsnagNotify(err, { userData });
                                            dispatch(GenericActions.showError(err));
                                            reject(err);
                                        });
                                });
                        }
                    })
                    .catch((err) => {
                        bugsnagNotify(err, { userData });
                        dispatch(GenericActions.showError(err));
                        reject(err);
                    });
            }
        }),

    getEzlosInfo: (serial) => async () => {
        const res = await apiDeviceInfo(serial);

        return (res && res.data) || {};
    },
    //TODO
    getRoomTypes: (serial) => () =>
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'getRoomTypes',
                {},
                (result) => resolve(result.roomTypes),
                (error) => {
                    bugsnagNotify(error, { serial });
                    reject(error);
                    GenericActions.showError(error);
                },
            );
        }),

    updateRooms: (serial, rooms, roomsToRemove) => (dispatch) => {
        const pagesId = rooms.map((room) => room._id);
        wsm.send(serial, 'hub.room.order.set', { pagesId }, () => {
            if (roomsToRemove) {
                roomsToRemove.forEach((room) => dispatch(actions.deleteRoom(serial, room)));
            }

            dispatch(actions.updateRoomsState(serial, rooms));
        });
    },

    deleteRoom: (serial, _id) => (dispatch) =>
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'hub.room.delete',
                { _id },
                () => {
                    resolve();

                    dispatch({
                        type: types.DELETE_ROOM,
                        serial,
                        roomId: _id,
                    });
                },
                (error) => {
                    bugsnagNotify(error, { serial, _id });
                    reject(error);
                },
            );
        }),

    addRoom:
        (serial, name, subtype = name) =>
        (dispatch) =>
            new Promise((resolve, reject) => {
                wsm.send(
                    serial,
                    'hub.room.create',
                    {
                        name,
                        subtype: subtype.replace(/^\[\[(.*)\]\]$/, '$1'),
                        type: 'room',
                    },
                    () => {
                        wsm.send(serial, 'hub.room.list', {}, (pagesList) => {
                            dispatch(actions.updateRoomsState(serial, pagesList));
                            resolve(pagesList);
                        });
                    },
                    (error) => {
                        bugsnagNotify(error, { serial, name, subtype });
                        reject(error);
                    },
                );
            }),

    removeAllDevices: (serial) => () =>
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'removeAllDevices',
                {},
                (success) => resolve(success),
                (error) => {
                    bugsnagNotify(error, { serial });
                    reject(error);
                },
            );
        }),

    bindUser:
        (serial, email, profile = 'user') =>
        (dispatch) =>
            new Promise((resolve, reject) => {
                wsm.send(
                    serial,
                    'bindUser',
                    {
                        email,
                        serial,
                        set_profile: profile,
                    },
                    (success) => {
                        dispatch(actions.updateSharedUser(email, { profile }));
                        resolve(success);
                    },
                    (error) => {
                        bugsnagNotify(error, { serial, email, profile });
                        reject(error);
                    },
                );
            }),

    unbindUser: (email, serial) => () =>
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'unbindUser',
                {
                    email,
                    serial,
                },
                (success) => resolve(success),
                (error) => {
                    bugsnagNotify(error, { serial, email });
                    reject(error);
                },
            );
        }),

    assignUser: (serial, _id, user) => () =>
        new Promise((resolve, reject) => {
            wsm.send(
                serial,
                'assignUser',
                {
                    _id,
                    user,
                    set_permission: 'x',
                    serial,
                },
                (success) => resolve(success),
                (error) => {
                    bugsnagNotify(error, { serial, _id, user });
                    reject(error);
                },
            );
        }),

    //----------------------------------------- REDUX ACTIONS SECTION -----------------------------------------------//
    addDevice: (serial, data) => ({
        type: types.ADD_DEVICE,
        serial,
        data,
    }),

    addNewDevice: (serial, result) => (dispatch, getState) => {
        const state = getState();
        const { ezlo } = state;

        dispatch(actions.addDevice(serial, result));

        if (state.meshBotV2.local.flagsContainer.subscriptionStatus === SUBSCRIPTIONS_STATUS.IN_PROGRESS) {
            dispatch(SubscriptionActions.getDevicesList([...ezlo.data[serial].devices, result]));
        }
    },

    removeDevice: (serial, _id) => ({
        type: types.REMOVE_DEVICE,
        serial,
        _id,
    }),

    updateEzlosListScenes: (serial, data) => ({
        type: types.UPDATE_EZLO_LIST_SCENES,
        serial,
        data,
    }),

    receiveEzlos: (data) => ({
        type: types.RECEIVE_EZLOS,
        data,
    }),

    requestEzlos: () => ({
        type: types.REQUEST_EZLOS,
    }),

    disconnectEzloSuccess: (serial) => ({
        type: types.DISCONNECT_EZLO,
        serial,
    }),

    connectEzloControllerSuccess: (serial) => ({
        type: controllerTypes.CONNECT_EZLO_CONTROLLER_SUCCESS,
        serial,
    }),

    connectEzloControllerError: (serial, error) => ({
        type: controllerTypes.CONNECT_EZLO_CONTROLLER_ERROR,
        serial,
        error,
    }),

    connectEzloControllerStart: (serial) => ({
        type: controllerTypes.CONNECT_EZLO_CONTROLLER,
        serial,
    }),

    updateEzloFirmwareProgress: (data) => ({
        type: types.UPDATE_FIRMWARE_PROGRESS,
        data,
    }),

    setEzloFirmware: (serial, data) => ({
        type: types.UPDATE_FIRMWARE_VERSION,
        serial,
        data,
    }),

    grantAccessAllow: (accessId) => ({
        type: types.GRANT_ACCESS_ALLOW,
        accessId,
    }),

    grantAccessDisallow: () => ({
        type: types.GRANT_ACCESS_DISALLOW,
    }),

    updateEzloDisable: (serial, disabled) => ({
        type: types.UPDATE_EZLO_DISABLED,
        serial,
        disabled,
    }),

    updateAutoFirmwareUpdateState: (state) => ({
        type: types.UPDATE_AUTO_FIRMWARE_UPDATE_STATE,
        state,
    }),

    connectEzloController: (serial) => async (dispatch) => {
        try {
            dispatch(actions.connectEzloControllerStart(serial));
            await dispatch(actions.register(serial));
            await dispatch(actions.loadEzloDataNew(serial));
            dispatch(actions.connectEzloControllerSuccess(serial));
        } catch (error) {
            bugsnagNotify(error, { serial });
            dispatch(actions.connectEzloControllerError(serial, error));
        }
    },

    selectDefaultController: () => async (dispatch, getState) => {
        const state = getState();
        const serial = getDefaultControllerInGroupData(state.ezlo.data);

        dispatch({ type: controllerTypes.SELECT_EZLO_CONTROLLER, serial: serial });
    },

    selectController: (serial) => async (dispatch) => {
        dispatch({ type: controllerTypes.SELECT_EZLO_CONTROLLER, serial });
    },

    updateGatewayData: (serial) => async (dispatch) => {
        dispatch({ type: controllerTypes.UPDATE_DATA_GATEWAY.pending, serial });
        try {
            await dispatch(actions.loadEzloDataNew(serial));
            dispatch(actions.connectEzloControllerSuccess(serial));

            dispatch({ type: controllerTypes.UPDATE_DATA_GATEWAY.success });
        } catch (error) {
            bugsnagNotify(error, { serial, type: controllerTypes.UPDATE_DATA_GATEWAY.rejected });
            dispatch(actions.connectEzloControllerError(serial, error));
            dispatch({ type: controllerTypes.UPDATE_DATA_GATEWAY.rejected });
        }
    },

    loadEzloDataByPluginUpdate: (serial) => async (dispatch) => {
        dispatch({ type: controllerTypes.GET_NEW_EZLO_DATA.pending });

        try {
            const getEzloData = (data) => {
                const ezloData = { ...data[0] };

                // eslint-disable-next-line
                ezloData.info = data[1];
                // eslint-disable-next-line
                ezloData.properties = data[2];
                // eslint-disable-next-line
                ezloData.devices = [...data[3].devices];
                ezloData.items = [...data[4].items];
                ezloData.gateways = [...data[5].gateways];
                ezloData.nodes = [...data[6].nodes];

                return ezloData;
            };

            const ezloPayload = await allSettled(
                [
                    actions.getEzloDeviceSettings(serial),
                    actions.getEzloInfo(serial),
                    actions.getEzloSettings(serial),
                    actions.getDevices(serial),
                    actions.getItems(serial),
                    actions.getGateways(serial),
                    actions.getNodes(serial),
                ].map((opts) => dispatch(opts)),
            );

            const ezloNewData = getEzloData(getAllSettledValues(ezloPayload));

            dispatch({ type: controllerTypes.GET_NEW_EZLO_DATA.success, ezloNewData, serial });
        } catch (error) {
            bugsnagNotify(error, { serial });
            dispatch({ type: controllerTypes.GET_NEW_EZLO_DATA.rejected });
        }
    },

    setOnlineControllerList: async (dispatch, getState) => {
        const data = getState().ezlo?.data;
        if (!data) {
            return;
        }

        const onlineControllerList = getOnlineControllers(data);
        dispatch({ type: controllerTypes.SET_ONLINE_CONTROLLER_LIST, onlineControllerList });
    },

    loadEzloDataNew: (serial) => async (dispatch, getState) => {
        const permissions = {
            devices: 's',
            ezlo: 's',
            rules: 's',
            ui: 's',
            users: 's',
        };
        const state = getState();

        function getEzloData(data) {
            // TODO: need to check and apply refactoring, why do we have duplication?
            const ezloData = { ...data[0], ...data[1], isEzloData: true };

            ezloData.permissions = permissions;
            // eslint-disable-next-line
            ezloData.info = data[1];
            ezloData.uuid = data[1]?.uuid || state.ezlo.data[serial].uuid;
            // eslint-disable-next-line
            ezloData.properties = data[2];
            // eslint-disable-next-line
            ezloData.devices = data[3]?.devices || [];
            // eslint-disable-next-line
            ezloData.items = data[4]?.items?.map((item) => {
                // TODO: need to check and apply refactoring, why do we have it?
                if (item.name === 'rgbcolor') {
                    item.show = true;
                }

                // TODO: need to check and apply refactoring, why do we have it?
                if (item.name === 'siren_strobe') {
                    item.isSetting = true;
                }

                return item;
            });
            // eslint-disable-next-line
            ezloData.pages = data[5];
            // eslint-disable-next-line
            ezloData.replication = data[6];
            // eslint-disable-next-line
            ezloData.rules = data[7]?.scenes;
            // eslint-disable-next-line
            ezloData.scenes = data[7]?.scenes;

            // eslint-disable-next-line
            ezloData.gateways = data[8]?.gateways;
            ezloData.interfaces = data[9]?.interfaces;
            ezloData.favorites = data[10]?.favorites;
            ezloData.nodes = data[11]?.nodes;
            ezloData.features = data[12]?.features;
            ezloData.expressions = data[13]?.expressions || [];

            return ezloData;
        }

        try {
            const ezloPayloadPromiseArr = await allSettled(
                [
                    actions.getEzloDeviceSettings(serial),
                    actions.getEzloInfo(serial),
                    actions.getEzloSettings(serial),
                    actions.getDevices(serial),
                    actions.getItems(serial),
                    actions.getRooms(serial),
                    actions.getReplication(serial),
                    actions.getListRules(serial),
                    actions.getGateways(serial),
                    actions.getNetworkInterfaces(serial),
                    DeviceActions.getFavorites(serial),
                    actions.getNodes(serial),
                    actions.getEzloFeatures(serial),
                    ExpressionsActions.getExpressions(serial),
                ].map((opts) => dispatch(opts)),
            );

            const ezloData = getEzloData(getAllSettledValues(ezloPayloadPromiseArr));
            // TODO refactoring this
            const ezloLocation = (ezloData.properties && ezloData.properties.ezloLocation) || null;

            // update ezlo location, legacy workaround?
            if (!ezloLocation || !ezloLocation.latitude || !ezloLocation.longitude) {
                ezloData.properties = {
                    ...ezloData.properties,
                    ezloLocation: {
                        ...DEFAULT_EZLO_LOCATION,
                    },
                };
            }

            const currentAdvancedScenesVersion = ezloData?.features?.advanced_scenes.version;

            dispatch(actions.updateEzloData(serial, ezloData));
            dispatch(MeshBotActions.getBlockDataList(serial));
            if (Number(currentAdvancedScenesVersion) >= Number(ADVANCED_SCENES.VERSION_1_67)) {
                dispatch(DeviceGroupsActions.fetchDeviceGroupsList(serial));
                dispatch(ItemGroupsActions.fetchItemGroupsList(serial));
            }
        } catch (error) {
            bugsnagNotify(error, { serial });
            dispatch(GenericActions.showError(error));
        }
    },

    updateFavorites: (serial, favorites) => ({
        type: types.UPDATE_FAVORITES,
        serial,
        favorites,
    }),

    updateControllerFavorites: (serial, favorites) => ({
        type: controllerTypes.UPDATE_CONTROLLER_FAVORITES,
        serial,
        favorites,
    }),

    updateEzlos: (data) => ({
        type: types.UPDATE_EZLOS,
        data,
    }),

    updateTemperatureUnits: (serial, temperatureUnits) => ({
        type: types.UPDATE_TEMPERATURE_UNITS,
        serial,
        temperatureUnits,
    }),

    addRule: (serial, data) => ({
        type: types.ADD_RULE,
        serial,
        data,
    }),

    updateRule: (serial, _id, data) => ({
        type: types.UPDATE_RULE,
        serial,
        _id,
        data,
    }),

    removeRule: (serial, _id) => ({
        type: types.REMOVE_RULE,
        serial,
        _id,
    }),

    updateThenRuleBlocks: (serial, thenRuleBlocks) => ({
        type: types.UPDATE_THEN_RULE_BLOCKS,
        serial,
        thenRuleBlocks,
    }),

    updateRules: (serial, rules) => ({
        type: types.UPDATE_RULES,
        serial,
        rules,
    }),

    resetSelectedAutomation: () => ({
        type: types.RESET_SELECTED_AUTOMATION,
    }),

    updateSelectedAutomation: (data) => ({
        type: types.UPDATE_SELECTED_AUTOMATION,
        data,
    }),

    resetSelectedRule: () => ({
        type: types.RESET_SELECTED_RULE,
    }),

    updateSelectedRule: (serial, data, devices = []) => ({
        type: types.UPDATE_SELECTED_RULE,
        data: { rule: data, devices: devices, serial },
    }),

    resetSelectedRuleBlock: () => ({
        type: types.RESET_SELECTED_RULE_BLOCK,
    }),

    updateSelectedRuleBlock: (data) => ({
        type: types.UPDATE_SELECTED_RULE_BLOCK,
        data,
    }),

    setRuleBlocks: (ruleBlocks, blockType) => ({
        type: types.SET_RULE_BLOCK,
        ruleBlocks,
        blockType,
    }),

    addRuleBlock: (ruleBlock) => ({
        type: types.ADD_RULE_BLOCK,
        ruleBlock,
    }),

    updateEzlo:
        ({ ezloColor, ezloLocation, description, image }) =>
        (dispatch) =>
            new Promise((resolve, reject) => {
                Promise.all([
                    dispatch(actions.setDescription(description)),
                    dispatch(
                        actions.setEzloProperties({
                            ezloColor,
                            ezloLocation,
                        }),
                    ),
                ])
                    .then(() => {
                        if (!image) {
                            resolve();

                            return;
                        }

                        dispatch(actions.uploadEzloImage(image))
                            .then(() => resolve())
                            .catch((error) => {
                                bugsnagNotify(error, { ezloColor, ezloLocation, description, image });
                                reject(error);
                            });
                    })
                    .catch((error) => {
                        bugsnagNotify(error, { ezloColor, ezloLocation, description, image });
                        reject(error);
                    });
            }),

    uploadEzloImage: () => () =>
        new Promise((resolve) => {
            resolve({});
            // uploadDataToStaticServer(image, serial)
            //     .then((response) => {
            //         dispatch(actions.setEzloImage(response.data.id, serial))
            //             .then(() => resolve())
            //             .catch(() => reject());
            //     })
            //     .catch(() => reject());
        }),

    updateEzloData: (serial, data) => ({
        type: types.UPDATE_EZLO_DATA,
        serial,
        data,
    }),

    updateEzloExpressions: (serial, expressions) => ({
        type: types.UPDATE_EZLO_EXPRESSIONS,
        serial,
        expressions,
    }),

    updateEzloLocation: (serial, location) => ({
        type: types.UPDATE_EZLO_LOCATION,
        serial,
        location,
    }),

    updateEzloImage: (id) => ({
        type: types.UPDATE_EZLO_IMAGE,
        id,
    }),

    updateEzlosImage: (serial, id) => ({
        type: types.UPDATE_EZLOS_IMAGE,
        serial,
        id,
    }),

    updateEzloName: (serial, name) => ({
        type: types.UPDATE_EZLO_NAME,
        serial,
        name,
    }),

    updateEzloDescription: (serial, description) => ({
        type: types.UPDATE_EZLO_DESCRIPTION,
        serial,
        description,
    }),

    updateEzloProperties: (serial, props) => ({
        type: types.UPDATE_EZLO_PROPERTIES,
        serial,
        props,
    }),

    updateEzloDeviceSettings: (serial, props) => ({
        type: types.UPDATE_DEVICE_SETTINGS,
        serial,
        props,
    }),
    updateEzloDeviceDictionarySettings: (serial, props) => ({
        type: types.UPDATE_DEVICE_DICTIONARY_SETTINGS,
        serial,
        props,
    }),
    updateEzlosProperties: (serial, props) => ({
        type: types.UPDATE_EZLOS_PROPERTIES,
        serial,
        props,
    }),

    updateEzloConnection: ({ serial, connected }) => ({
        type: types.UPDATE_EZLO_CONNECTION,
        connected,
        serial,
    }),

    updateCurrentEzloConnection: ({ serial, connected }) => ({
        type: types.UPDATE_CURRENT_EZLO_CONNECTION,
        serial,
        connected,
    }),

    updateRoomsState: (serial, rooms) => ({
        type: types.UPDATE_ROOMS,
        serial,
        rooms,
    }),

    updateRoomImage: (_id, imageId) => ({
        type: types.UPDATE_ROOM_IMAGE,
        _id,
        imageId,
    }),

    uploadRoomImage: () => () => {
        return Promise.resolve({});
        // uploadDataToStaticServer(formData)
        //     .then((response) => {
        //         dispatch(actions.saveRoomImage(_id, response.data.id));
        //     });
    },

    updatePageName: (_id, name) => ({
        type: types.UPDATE_ROOM_NAME,
        _id,
        name,
    }),

    uploadImagesForRooms: (roomMap) => (dispatch) => {
        roomMap.forEach((formData, room) => {
            dispatch(actions.uploadRoomImage(room._id, formData));
        });
    },

    updateBackupProgress: (date, data) => ({
        type: types.UPDATE_BACKUP_PROGRESS,
        data,
        date,
    }),

    handleBackupProgress: (date, data, callback) => (dispatch) => {
        dispatch(actions.updateBackupProgress(date, data));

        if (callback && data && data.completed === '100%') {
            callback();
        }
    },

    updateRestoreProgress: (data) => ({
        type: types.UPDATE_RESTORE_PROGRESS,
        data,
    }),

    handleRestoreProgress: (data, callback) => (dispatch) => {
        dispatch(actions.updateRestoreProgress(data));

        if (callback && data && data.completed === '100%') {
            callback();
        }
    },

    removeEzloFromState: (serial, stayedController) => ({
        type: types.REMOVE_EZLO,
        serial,
        stayedController,
    }),

    receiveSharedUsers: (sharedUsers) => ({
        type: types.RECEIVE_SHARED_USERS,
        sharedUsers,
    }),

    receiveUsersToShare: (usersToShare) => ({
        type: types.RECEIVE_USERS_TO_SHARE,
        usersToShare,
    }),

    removeUserFromRoom: (roomId, userEmail) => ({
        type: types.REMOVE_USER_FROM_ROOM,
        roomId,
        userEmail,
    }),

    addUserToRoom: (roomId, user) => ({
        type: types.ADD_USER_TO_ROOM,
        roomId,
        user,
    }),

    addSharedUser: (user) => ({
        type: types.ADD_SHARED_USER,
        user,
    }),

    removeSharedUser: (user) => ({
        type: types.REMOVE_SHARED_USER,
        user,
    }),

    updateSharedUser: (email, data) => ({
        type: types.UPDATE_SHARED_USER,
        email,
        data,
    }),

    updateGateway: (serial, gateway) => ({
        type: types.UPDATE_GATEWAY,
        serial,
        gateway,
    }),

    removeGateway: (serial, gateway) => ({
        type: types.REMOVE_GATEWAY,
        serial,
        gateway,
    }),

    addGateway: (serial, gateway) => ({
        type: types.ADD_GATEWAY,
        serial,
        gateway,
    }),

    subscribeEzloStateChanged: (serial, callback) => () => {
        wsm.subscribe(serial, 'hub.device.updated', callback);
    },

    unsubscribeEzloStateChanged: (serial, callback) => () => {
        wsm.unsubscribe(serial, 'hub.device.updated', callback);
    },

    subscribeDeviceAdded: (serials, callback) => () => {
        serials.map((serial) => wsm.subscribe(serial, 'hub.device.added', (data) => callback({ serial, ...data })));
    },

    unsubscribeDeviceAdded: (serials) => () => {
        serials.map((item) => wsm.unsubscribe(item, 'hub.device.added'));
    },

    subscribeDeviceRemoved: (serials, callback) => () => {
        serials.map((item) => wsm.subscribe(item, 'hub.device.removed', (data) => callback(data.result)));
    },

    unsubscribeDeviceRemoved: (serials) => () => {
        serials.map((item) => wsm.unsubscribe(item, 'hub.device.removed'));
    },

    subscribeToGatewayMethod: (serial, callback) => () => {
        wsm.subscribe(serial, 'hub.gateway.updated', callback);
    },

    subscribeToGatewayRemoved: (serial, callback) => () => {
        wsm.subscribe(serial, 'hub.gateway.removed', callback);
    },

    unsubscribeToGatewayMethod: (serial, callback) => () => {
        wsm.unsubscribe(serial, 'hub.gateway.updated', callback);
    },

    unsubscribeToGatewayRemoved: (serial, callback) => () => {
        wsm.unsubscribe(serial, 'hub.gateway.removed', callback);
    },

    subscribeEzloCreateScenes: (serial, callback) => () => {
        wsm.subscribe(serial, 'hub.scene.added', callback);
    },
    subscribeItemAdded: (serials, callback) => () => {
        serials.map((serial) => wsm.subscribe(serial, 'hub.item.added', (data) => callback(data.result, serial)));
    },
    unsubscribeItemAdded: (serials) => () => {
        serials.map((item) => wsm.unsubscribe(item, 'hub.item.added'));
    },
    subscribeItemUpdated: (serials, callback) => () => {
        serials.map((item) => wsm.subscribe(item, 'hub.item.updated', (data) => callback(data.result)));
    },
    unsubscribeItemUpdated: (serials) => () => {
        serials.map((item) => wsm.unsubscribe(item, 'hub.item.updated'));
    },

    /**
     * Redux Thunk function for handling updates in the hub item dictionary when a broadcast is received.
     *
     * @function
     * @name onHubItemDictionaryUpdated
     *
     * @param {string} serial - The serial number of the hub.
     * @param {Object} broadcastData - The broadcast data containing the result of the update.
     *
     * @returns {Function} A Redux Thunk function that dispatches actions to update item.
     */
    onHubItemDictionaryUpdated:
        (serial, broadcastData = {}) =>
        (dispatch, getState) => {
            const ezloData = getState()?.ezlo?.data;
            const { result } = broadcastData;
            const items = ezloData?.[serial]?.items;
            const obsoleteItem = items?.find((item) => item._id === result?._id);
            const updatedItem = buildItemByHubItemDictionaryUpdatedBroadcast(obsoleteItem, result);
            if (!isObject(updatedItem)) {
                return;
            }
            dispatch(ItemActions.setUpdatedItem(serial, updatedItem, result._id));
        },
    updateLocalMeshBotStatus: (serial, id, isMeshBotActive) => (dispatch) => {
        if (isMeshBotActive) {
            dispatch(actions.onRule(serial, id));
        } else {
            dispatch(actions.offRule(serial, id));
        }
    },
};

export default actions;
