import {
    ALLOWED_ITARIAN_SSO_MENU_ITEMS,
    ALLOWED_MENU_ITEMS_ONLY_ON_INTERNAL_DOMAIN,
    MENU_ITEMS,
    MENU_ITEMS_IDS,
    UNSUPPORTED_MENU_ITEMS_IDS_FOR_RESIDENT_UNDER_PARTNER,
} from './constants';
import { CUSTOMIZATION, NOTIFICATIONS, ZERO } from '../Ezlo/EzloCustomization/constants';
import { USER_TYPE } from '../../constants/localStorageKeys';
import { ITARIAN_SSO_TYPE } from '../../services/sso/src/ItarianService/constants';
import { isArray } from 'lodash';
import { isEzlogicInternalDomain } from 'services/utilityService';
import { ZERO_INT, ONE_INT } from 'constants/Variables';
import { getControllers } from 'helpers/helpersPlugins';
import { isControllerTypeMatch } from 'containers/Ezlo/EzloGroups/utils';
import { CONTROLLER_MODELS, MESHBOT_TYPES } from 'containers/Ezlo/EzloMeshbots/constants';
import { PK_PERMISSION_ROLE } from 'services/permission';
import { isFeatureSupportByEzlopiFirmwareVersion } from 'containers/Ezlo/EzloMeshbot/utils';
import { EZLOPI_MENU_ITEMS_IDS } from 'features/ezlopi/ezlopiConstants';

export const buildMenuItemsById = (menuBlock, totalItems) => {
    if (!isArray(menuBlock)) {
        return;
    }

    menuBlock.forEach((item) => {
        totalItems[item.id] = item;
        if (isArray(item.subItems)) {
            buildMenuItemsById(item.subItems, totalItems);
        }
    });
};
export const getMenuItemsById = (menuData) => {
    const menuItemsById = {};

    buildMenuItemsById(menuData, menuItemsById);

    return menuItemsById;
};

export const getMenuCustomizationData = (menuBarData, isControllerAvailable) => {
    const menuItemsById = getMenuItemsById(menuBarData);

    const updateMenu = (menuItems) => {
        return menuItems
            .map((item) => {
                const menuItemFromCustomization = menuItemsById.hasOwnProperty(item.id) && menuItemsById[item.id];

                if (menuItemFromCustomization?.checked === false) {
                    return null;
                }

                if (isArray(item.subItems)) {
                    const subItems = updateMenu(item.subItems);

                    return { ...item, subItems };
                }

                return item;
            })
            .filter(Boolean);
    };
    const filteredMenuItemsByInternalDomain = filterMenuItemsByInternalDomain(MENU_ITEMS);
    const updatedMenu = updateMenu(filteredMenuItemsByInternalDomain);

    if (!isControllerAvailable) {
        const updatedControllerMenuItems = getControllerIndependentMenuItems(updatedMenu);

        return updatedControllerMenuItems;
    }

    return updatedMenu;
};

const getControllerIndependentMenuItems = (menuItems) => {
    const controllerIndependentMenuItems = menuItems.map((menuItem) => {
        if (!menuItem?.isControllerDependent) {
            return {
                ...menuItem,
                subItems:
                    menuItem?.subItems?.length > ZERO ? getControllerIndependentMenuItems(menuItem?.subItems) : null,
            };
        }
    });

    return controllerIndependentMenuItems.filter((menu) => menu != undefined);
};
const getMenuItems = (menuItem, isUser, isOemTemplateMasterUser) => {
    if ((isUser || menuItem?.id !== CUSTOMIZATION) && (isOemTemplateMasterUser || menuItem?.id !== NOTIFICATIONS)) {
        return customizationMenuItems(menuItem, isUser, isOemTemplateMasterUser);
    }
};

const customizationMenuItems = (menuItem, isUser, isOemTemplateMasterUser) => {
    return {
        ...menuItem,
        subItems:
            menuItem?.subItems?.length > ZERO
                ? subMenuItems(menuItem?.subItems, isUser, isOemTemplateMasterUser).filter(
                      (menuSubItem) => menuSubItem !== undefined,
                  )
                : null,
    };
};
const subMenuItems = (menuList, isUser, isOemTemplateMasterUser) => {
    return menuList?.map((subItem) => {
        return getMenuItems(subItem, isUser, isOemTemplateMasterUser);
    });
};

export const updatedMenuListItems = (menuItems, isUser, isOemTemplateMasterUser) => {
    return menuItems
        .map((menuItem) => getMenuItems(menuItem, isUser, isOemTemplateMasterUser))
        .filter((item) => !item?.subItems || item?.subItems?.length > ZERO);
};

export const checkVeraUser = () => {
    return Boolean(localStorage.getItem(USER_TYPE));
};

/**
 * Returns update navigation menu list, updated by Itarian SSO type
 * @param {Object} menuItems - array of menu items
 * @returns {array|undefined} updated menu list
 * */
export const updateNavigationMenuItemsByItarianSsoType = (menuItems) => {
    if (!Array.isArray(menuItems)) {
        return;
    }

    const updatedMenuItems = [];

    for (const menuItem of menuItems) {
        if (ALLOWED_ITARIAN_SSO_MENU_ITEMS.includes(menuItem && menuItem.id)) {
            updatedMenuItems.push(menuItem);
            if (menuItem && menuItem.subItems) {
                menuItem.subItems = updateNavigationMenuItemsByItarianSsoType(menuItem.subItems);
            }
        }
    }

    return updatedMenuItems;
};

/**
 * Filters menu items based on internal domain permissions.
 *
 * @function
 * @param {Array<Object>} menuItems - The array of navigation menu items to be updated.
 * @returns {Array<Object>} The updated array of menu items without restricted internal domain items.
 * @description This function iterates through the provided array of menu items,
 *              filtering out those that are restricted to internal domains.
 *              It also recursively updates sub-items if present.
 * @example
 * const menuItems = [
 *   { id: 'geofencing', title: 'Geofencing', subItems: [{ id: 'subItem1', title: 'Sub Item 1' }] },
 *   { id: 'dashboard', title: 'Dashboard' },
 *   // ...
 * ];
 * const filteredMenuItems = filterMenuItemsByInternalDomain(menuItems);
 * // Result: [{ id: 'dashboard', title: 'Dashboard' }]
 */
export const filterMenuItemsByInternalDomain = (menuItems) => {
    if (!Array.isArray(menuItems)) {
        return [];
    }

    if (isEzlogicInternalDomain()) {
        return menuItems;
    }

    const updatedMenuItems = [];

    for (const menuItem of menuItems) {
        if (!ALLOWED_MENU_ITEMS_ONLY_ON_INTERNAL_DOMAIN.includes(menuItem?.id)) {
            updatedMenuItems.push(menuItem);
            if (menuItem?.subItems) {
                menuItem.subItems = filterMenuItemsByInternalDomain(menuItem.subItems);
            }
        }
    }

    return updatedMenuItems;
};

/**
 * Returns update navigation menu list, updated by SSO type
 * @param {array} menuItems - array of menu items
 * @param {string|undefined} ssoType - current sso type
 * @returns {array} updated menu list
 * */
export const updateNavigationMenuItemsBySsoType = (menuItems, ssoType) => {
    if (ssoType && ssoType === ITARIAN_SSO_TYPE) {
        return updateNavigationMenuItemsByItarianSsoType(menuItems);
    }

    return menuItems;
};

/**
 * Conditionally updates the navigation menu items based on device availability.
 * If addressableLedDevices are present (and not empty), returns the original menuItems;
 * otherwise, filters out the 'platforms' item from the menuItems.
 *
 * @param {array} menuItems - The array of menu items.
 * @param {array} addressableLedDevices - The array of addressableLedDevices.
 * @returns {array} The updated menu list.
 */
export const getAddressableMenuItem = (menuItems, addressableLedDevices) => {
    if (addressableLedDevices?.length > ZERO_INT) {
        return menuItems;
    }

    return menuItems?.filter((item) => item?.id !== MENU_ITEMS_IDS.PLATFORMS);
};

/**
 * This function excludes the ezlopi controllers from the general controller's list
 * @param {array} controllers - all online controllers
 * @returns {array}
 */
export const excludeEzlopiControllers = (controllers) => {
    return controllers.filter((controller) => !isControllerTypeMatch(controller, CONTROLLER_MODELS.EZLOPI));
};

/**
 * This function returns the ezlopi controllers
 * @param {array} controllers - all online controllers
 * @returns {array} - the ezlopi controllers
 */
export const getEzlopiControllers = (controllers) => {
    return controllers.filter((controller) => isControllerTypeMatch(controller, MESHBOT_TYPES.EZLOPI));
};

/**
 * This function returns the max ezlopi version
 * @param {array} controllers - the ezlopi controllers
 * @returns {string} - the max ezlopi version
 */
export const getMaxEzlopiVersion = (controllers) => {
    const versions = controllers?.map((controller) => controller.firmware);

    if (versions.length === ONE_INT) {
        return versions[ZERO_INT];
    }

    return versions?.reduce((maxVersion, currentVersion) => {
        const maxVersionNumbers = maxVersion.split('.').map(Number);
        const currentVersionNumbers = currentVersion.split('.').map(Number);

        for (let i = ZERO_INT; i < maxVersionNumbers.length; i++) {
            if (maxVersionNumbers[i] > currentVersionNumbers[i]) {
                return maxVersion;
            } else if (maxVersionNumbers[i] < currentVersionNumbers[i]) {
                return currentVersion;
            }
        }

        return maxVersion.length >= currentVersion.length ? maxVersion : currentVersion;
    });
};

/**
 * This function filters the menu items by ezlopi
 * @param {array} menuItems - the left menu items
 * @param {array} controllers - the ezlopi controllers
 * @returns {array} - the filtered menu items by ezlopi
 */
export const filterMenuItemsByEzlopi = (menuItems, controllers) => {
    if (!Array.isArray(menuItems)) {
        return [];
    }

    const updatedMenuItems = [];
    const maxVersion = getMaxEzlopiVersion(controllers);

    for (const menuItem of menuItems) {
        const ezlopiMenuItem = EZLOPI_MENU_ITEMS_IDS[menuItem?.id];

        if (!ezlopiMenuItem || isFeatureSupportByEzlopiFirmwareVersion(ezlopiMenuItem?.firmwareVersion, maxVersion)) {
            updatedMenuItems.push(menuItem);
        }

        if (menuItem?.subItems) {
            menuItem.subItems = filterMenuItemsByEzlopi(menuItem.subItems, controllers);
        }
    }

    return updatedMenuItems;
};

/**
 * This function handles the menu items by ezlopi
 * @param {object} data - the data of the ezlo state
 * @param {array} finalMenu - the final menu
 * @returns {array}
 */
export const handleMenuItemsByEzloPi = (data, finalMenu) => {
    const controllers = getControllers(data);
    const ezlopiControllers = getEzlopiControllers(controllers);

    if (ezlopiControllers.length) {
        return filterMenuItemsByEzlopi(finalMenu, ezlopiControllers);
    }

    return finalMenu;
};

/**
 * Filters menu items based on the user type under a partner.
 * This function excludes specific menu items if the resident is under a partner.
 *
 * @param {Array} menuItems - The array of menu items to filter.
 * @returns {Array} The filtered array of menu items.
 */
// TODO: fix typo: parnter
export const filterMenuItemsByResidentUnderParnter = (menuItems) => {
    if (!Array.isArray(menuItems)) {
        return [];
    }

    const updatedMenuItems = [];

    for (const menuItem of menuItems) {
        if (!UNSUPPORTED_MENU_ITEMS_IDS_FOR_RESIDENT_UNDER_PARTNER.includes(menuItem?.id)) {
            updatedMenuItems.push(menuItem);
            if (menuItem?.subItems) {
                menuItem.subItems = filterMenuItemsByResidentUnderParnter(menuItem.subItems);
            }
        }
    }

    return updatedMenuItems;
};

/**
 * Filters menu items based on the resident type under a partner.
 * This function applies additional filtering if the resident is under a partner.
 *
 * @param {boolean} isResidentUnderPartner - Indicates if the resident is under a partner.
 * @param {Array} finalMenu - The array of menu items to filter.
 * @returns {Array} The filtered array of menu items.
 */
export const getMenuItemsByResidentUnderPartner = (isResidentUnderPartner, finalMenu) => {
    if (isResidentUnderPartner) {
        return filterMenuItemsByResidentUnderParnter(finalMenu);
    }

    return finalMenu;
};

/**
 * Checks if a resident is under a partner based on the provided parameters.
 *
 * @param {string} partnerUuid - The UUID of the partner.
 * @param {string | number} PK_PermissionRole - The permission role of the resident.
 * @returns {boolean} True if the resident is under a partner, false otherwise.
 */
export const checkIsResidentUnderPartner = (partnerUuid, PK_PermissionRole) =>
    Boolean(partnerUuid?.length) && Number(PK_PermissionRole) === PK_PERMISSION_ROLE.END_USER;
