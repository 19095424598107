import { isArray, isEmpty, isObject } from 'lodash';
import {
    DEVICE_SETTINGS_UPDATED,
    DEVICE_SETTINGS_UPDATED_DICTIONARY,
    SETTING_TYPE_DICTIONARY,
    DEVICE_DESCRIPTION,
    INDEX_MISSING_ELEMENT,
    VALUE_TO_UNASSIGN_ROOM,
    ABSTRACT_TYPE,
} from '../../../constants/Devices';
import {
    EZLOGIC_DEVICES_PAGE_MODEL,
    EZLOGIC_DEVICES_PAGE_DEVICE_ID,
    EZLOGIC_DEVICES_PAGE_Z_WAVE_NODE_ID,
    EZLOGIC_DEVICES_PAGE_MANUFACTURER,
    EZLOGIC_DEVICES_PAGE_PROTOCOL,
    EZLOGIC_DEVICES_PAGE_EZLO,
    EZLOGIC_DEVICES_PAGE_PLUGIN,
    EZLOGIC_DEVICES_PAGE_2GIGTXID,
} from '../../../constants/language_tokens';
import { CATEGORIES_OF_UNAVAILABLE_DEVICES, TX_ID_PROP_NAME } from './constants';
import { LED_STRIP } from 'constants/SystemHistory';
import { getAddressableLedSettingData } from 'features/EzloAddressableLed/utils';

/**
 * Getting all rooms from the account
 * @param { object } data
 * @returns { node } Array of all rooms
 * @example
 * prepareDevices(state.ezlo.data)
 */

export const prepareRooms = (data) => {
    return Object.entries(data).reduce((acc, [serial, controller]) => {
        if (controller && controller.pages && controller.pages.length > 0) {
            return acc.concat(
                controller.pages.map((controllerPage) => {
                    return { ...controllerPage, serial, isConnected: controller.isConnected };
                }),
            );
        }

        return acc;
    }, []);
};

/**
 * Getting all devices from the account
 * @param { object } data
 * @returns { node } Array of all devices
 * @example
 * prepareDevices(state.ezlo.data)
 */

export const prepareDevices = (data = {}) => {
    return Object.entries(data).reduce((acc, [serial, controller]) => {
        if (controller && controller.devices && controller.devices.length > 0) {
            return acc.concat(
                controller.devices.map((device) => {
                    return {
                        ...device,
                        serial,
                        isConnected: controller.isConnected,
                        isFavorite:
                            controller.favorites &&
                            controller.favorites.devices &&
                            controller.favorites.devices.some((favorite) => favorite === device._id),
                        items:
                            controller.items &&
                            controller.items.filter((item) => item.deviceId === device._id && item.show),
                        gateway:
                            controller.gateways &&
                            controller.gateways.find((gateway) => gateway._id === device.gatewayId),
                        settings:
                            controller.settings &&
                            controller.settings.filter((setting) => setting.deviceId === device._id),
                    };
                }),
            );
        }

        return acc;
    }, []);
};

/**
 * Get name for subscribe update
 * @param { object } data
 * @returns { string } Name for subscriber
 * @example
 * getNameSubscribe(currentFunction)
 */

export const getNameSubscribe = (data) => {
    return data.setting.valueType === SETTING_TYPE_DICTIONARY
        ? DEVICE_SETTINGS_UPDATED_DICTIONARY
        : DEVICE_SETTINGS_UPDATED;
};

export const removeInfoText =
    'Are you sure you want to reset your device? After reset your device will return to it’s default Z-Wave parameters,' +
    ' all parameters added manually by you will be deleted. Please note that for the battery operated device you need to manually activate it after you confirm the reset.';

/**
 * This function filterings the settings list of the device by condition
 * @param {array} settings - the settings list of the device
 * @returns {array} - the filtered settings list of the device
 */
export const filterSettings = (settings = []) => {
    return settings.filter((setting) => setting?.valueType !== DEVICE_DESCRIPTION.CAMERA_STREAM_VALUE_TYPE);
};

export const isDeviceHasParentRoomFalse = (devicesList) => {
    return !!devicesList.filter((device) => device?.parentRoom === false).length;
};

export const getSeparateChildDevices = (devicesList, deviceId) => {
    return devicesList.filter((device) => device?.parentDeviceId === deviceId && device?.parentRoom === false);
};

export const getParentDevice = (devicesList, deviceParentId) => {
    return devicesList.find((device) => device?._id === deviceParentId);
};

export const getCheckedDevices = (childDevicesList, roomId) => {
    return childDevicesList.filter((device) => device?.roomId === roomId);
};

export const getDevicesWithId = (devices) => {
    return devices.map((device) => device?._id);
};

export const getUncheckedDevices = (childDevicesList, checkedOptions) => {
    return childDevicesList.filter((device) => !getDevicesWithId(checkedOptions).includes(device?._id));
};

export const getIdFromUncheckedDevices = (uncheckedDevices) => {
    return getDevicesWithId(uncheckedDevices);
};

export const isDeviceCannotBeMoved = ({ parentDeviceId, parentRoom }) => {
    return (parentDeviceId && parentRoom === true) || (parentDeviceId && parentRoom === undefined);
};

export const getRoomsWithValidData = ({ roomsListBySerial }) => {
    if (!isArray(roomsListBySerial)) {
        return [];
    }

    return roomsListBySerial.filter((room) => room?._id && room?.name);
};

export const getPropertyByRegex = (data, regex) => {
    const propNames = Object.keys(data);
    for (let i = 0; i < propNames.length; i++) {
        const propName = propNames[i];
        if (regex.test(propName)) {
            return propName;
        }
    }
};

//Todo: Discuss the feasibility of displaying in TooltipInfo all information about the device coming from the controller.
// Todo: Replace with a solution that won't use a loop each time to determine the property name.
/**
 * Returns array of device's info
 * @param {object} data - device's info object
 * @param {string} deviceId - device id
 * @param {object} gatewayInfo - gatewayInfo
 * @returns {array} result
 * */
export const getInfoData = (data, deviceId, gatewayInfo) => {
    if (!data) {
        return [];
    }

    const regexForModelName = /model/i;
    const regexForManufacturerName = /manufacturer/i;
    const regexForProtocolName = /protocol/i;
    const regexForZwaveNodeName = /zwave\.node/i;

    const result = [
        { id: EZLOGIC_DEVICES_PAGE_DEVICE_ID, label: EZLOGIC_DEVICES_PAGE_DEVICE_ID, value: deviceId },
        {
            id: EZLOGIC_DEVICES_PAGE_MODEL,
            label: EZLOGIC_DEVICES_PAGE_MODEL,
            value: isEmpty(data) ? gatewayInfo : data[getPropertyByRegex(data, regexForModelName)],
        },
        {
            id: EZLOGIC_DEVICES_PAGE_Z_WAVE_NODE_ID,
            label: !isEmpty(data) ? EZLOGIC_DEVICES_PAGE_Z_WAVE_NODE_ID : null,
            value: !isEmpty(data) ? data[getPropertyByRegex(data, regexForZwaveNodeName)] : null,
        },
        {
            id: EZLOGIC_DEVICES_PAGE_MANUFACTURER,
            label: EZLOGIC_DEVICES_PAGE_MANUFACTURER,
            value: isEmpty(data) ? EZLOGIC_DEVICES_PAGE_EZLO : data[getPropertyByRegex(data, regexForManufacturerName)],
        },
        {
            id: EZLOGIC_DEVICES_PAGE_PROTOCOL,
            label: EZLOGIC_DEVICES_PAGE_PROTOCOL,
            value: isEmpty(data) ? EZLOGIC_DEVICES_PAGE_PLUGIN : data[getPropertyByRegex(data, regexForProtocolName)],
        },
    ];

    if (data[TX_ID_PROP_NAME]) {
        result.push({
            id: EZLOGIC_DEVICES_PAGE_2GIGTXID,
            label: EZLOGIC_DEVICES_PAGE_2GIGTXID,
            value: data[TX_ID_PROP_NAME],
        });
    }

    return result;
};

export const getCameraStreamItems = (device) => {
    if (device?.category === DEVICE_DESCRIPTION.CAMERA) {
        return device?.items.filter((item) => item.name === DEVICE_DESCRIPTION.CAMERA_STREAM_NAME);
    }

    return [];
};

export const getDevicesByControllers = (data) => {
    if (!data || (data && !Object.keys(data).length)) {
        return;
    }

    const devicesByAccount = {};

    for (const serial in data) {
        if (data[serial]?.devices && data[serial].devices.length) {
            devicesByAccount[serial] = data[serial].devices.map((device) => {
                return {
                    ...device,
                    serial,
                    isConnected: data[serial].isConnected,
                    isFavorite:
                        data[serial].favorites &&
                        data[serial].favorites.devices &&
                        data[serial].favorites.devices.some((favorite) => favorite === device._id),
                    items:
                        data[serial].items &&
                        data[serial].items.filter((item) => item.deviceId === device._id && item.show),
                    gateway:
                        data[serial].gateways &&
                        data[serial].gateways.find((gateway) => gateway._id === device.gatewayId),
                    settings:
                        data[serial].settings &&
                        data[serial].settings.filter((setting) => setting.deviceId === device._id),
                };
            });
        } else {
            devicesByAccount[serial] = [];
        }
    }

    return devicesByAccount;
};

/**
 * Filters out devices by category which are included in 'CATEGORIES_OF_UNAVAILABLE_DEVICES'.
 * @function filterDevicesByCategory
 * @param {Object[]} devices - Array of devices.
 * @return {Object[]} availableDevices - Array of devices not listed in 'CATEGORIES_OF_UNAVAILABLE_DEVICES'
 */
const filterDevicesByCategory = (devices) => {
    if (!isArray(devices)) {
        return [];
    }

    return devices.filter((device) => !CATEGORIES_OF_UNAVAILABLE_DEVICES.includes(device?.category));
};

/**
 * Filters devices data by hub, if devicesByHub is an object.
 * @function filterDevices
 * @param {Object} devicesByHub - Devices categorized by Hub.
 * @return {Object|undefined} Available devices by hub or undefined if input is not an object.
 */
const filterDevices = (devicesByHub) => {
    if (!isObject(devicesByHub)) {
        return;
    }

    return Object.entries(devicesByHub).reduce(
        (acc, [hubSerial, hubDevices]) => ({
            ...acc,
            [hubSerial]: filterDevicesByCategory(hubDevices),
        }),
        {},
    );
};

/**
 * Retrieves available devices by controller from the provided data.
 * @function getAvailableDevicesByController
 * @param {Object} ezloData - Data containing devices by controllers.
 * @return {Object} - Available devices by controller.
 * @export
 */
export const getAvailableDevicesByController = (ezloData) => {
    const allDevicesByHub = getDevicesByControllers(ezloData);

    return filterDevices(allDevicesByHub);
};

export const filterDevicesByName = (value, devicesContainer) => {
    if (!devicesContainer || (devicesContainer && !Object.keys(devicesContainer).length)) {
        return;
    }

    if (value) {
        const result = {};
        for (const serial in devicesContainer) {
            result[serial] = devicesContainer[serial].filter((device) => {
                const searchValue = device.name.toLowerCase();

                return searchValue.indexOf(value.toLowerCase()) !== INDEX_MISSING_ELEMENT;
            });
        }

        return result;
    } else {
        return devicesContainer;
    }
};

export const filterDevicesByRoom = (roomId, devices) => {
    if (roomId === VALUE_TO_UNASSIGN_ROOM) {
        return devices;
    } else {
        const result = devices.filter((device) => device.roomId === roomId);

        return result;
    }
};

export const filterDevicesByType = (devices) => {
    if (!devices?.length) {
        return [];
    }

    const result = devices.filter((device) => device.type !== ABSTRACT_TYPE);

    return result;
};

/**
 * Determines whether the Addressable led configuration page should be shown for a specific device.
 * Checks if the device is of LED_STRIP category and has valid addressable LED settings.
 *
 * @param {object} device - The device for which to determine Addressable led configuration page visibility.
 * @param {array} settings - The array of settings for all devices.
 * @returns {boolean} True if the Addressable led configuration page should be shown; otherwise, false.
 */
export const shouldShowAddressableLedPage = (device, settings) => {
    const addressableLedSettingData = getAddressableLedSettingData(settings, device?._id);

    return device?.category === LED_STRIP && !!addressableLedSettingData;
};
