import { combineReducers } from 'redux';
import app from './app';
import account from './account';
import backups from './backups';
import main from './main';
import ezlos from './ezlos';
import ezlo from './ezlo';
import ezloCustomization from './ezloCustomization';
import geofenceSettings from './geofenceSettings';
import supportData from './ezloSupport';
import systemHistory from './ezloSystemHistory';
import oem from './ezloInitialization';
import customizationData from './customizationData';
import ezloPolicy from '../features/EzloPolicies/reducers/EzloPoliciesReducer';
import { installerAccessStoreReducer } from '../features/InstallerAccess/reducers/installerAccessRights';
import gateways from './gateways';
import groups from './groups';

import modals from './modals';
import ezloRules from './reducerRules';
import meshBot from './meshBot';
import plugins from './plugins';
import virtualDeviceManager from './virtualDeviceManager';
import integrations from './integrations';
import notifications from './notifications';
import navigation from './navigation';
import toasts from './toasts';
import geofencing from './geofencing';
import users from './users';
import deviceSettings from './deviceSettings';
import subscriptions from './subscriptions';
import deviceGroups from '../containers/Ezlo/EzloDeviceGroups/reducer/deviceGroups';
import meshBotV2 from './meshBotV2';
import trackRunScenes from './trackRunScenes';
import staticData from './staticData';

import { mediaStoreReducer } from '../features/MediaStoragePage/reducer/media';
import { meshbotDuplicateStoreReducer } from 'containers/Ezlo/EzloMeshbots/reducers/meshbotDuplicate';
import { KVS_NAME as KVS, kvsReducer } from './kvs';
import { LABELS, labelsReducer } from './labels';
import { migrationReducer } from '../features/Migration/reducer/migration';
import tokensFlows from '../features/tokensFlows/reducer';
import { meshbotsFiltersReducer } from './meshBotsFilters';

const rootReducer = combineReducers({
    app,
    account,
    ezlos,
    ezlo,
    gateways,
    groups,
    ezloRules,
    modals,
    backups,
    virtualDeviceManager,
    main,
    meshBot,
    notifications,
    plugins,
    toasts: toasts.reducer,
    tokensFlows,
    users,
    integrations,
    deviceSettings,
    ezloCustomization,
    mediaStore: mediaStoreReducer,
    migration: migrationReducer,
    geofenceSettings: geofenceSettings.reducer,
    geofencing,
    customizationData,
    ezloPolicy,
    supportData,
    subscriptions,
    navigation,
    deviceGroups,
    oem,
    systemHistory,
    meshBotV2,
    [KVS]: kvsReducer,
    [LABELS]: labelsReducer,
    trackRunScenes: trackRunScenes.reducer,
    staticData,
    installerAccessStore: installerAccessStoreReducer,
    duplicateMeshBotStore: meshbotDuplicateStoreReducer,
    meshBotsFiltersStore: meshbotsFiltersReducer,
});

export default rootReducer;
