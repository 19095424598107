import * as types from '../constants/ActionTypes';
import * as meshbot from '../constants/MeshbotConstant';
import { CloudMeshbotActionTypes } from '../constants/ActionTypes/CloudMeshbot';
import {
    apiGetMeshSceneDetails,
    apiGetMeshScenes,
    apiRunMeshScenes,
    apiSetMeshScenes,
    apiGetOneMeshScene,
    apiGetMeshSceneCapabilities,
} from '../api/apiScenes';
import { CloudMeshbotActions } from './CloudMeshbotActions';
import { bugsnagNotify, bugsnagNotifyWrapper } from '../containers/ErrorBoundary/utils';
import GenericActions from './GenericActions';
import at from '../constants/ActionTypes/MeshBot';
import { ERROR_STATUS, SUCCESS_STATUS } from '../constants/api';
import { toast, TOAST_TYPE } from '../components/Toast';
import { EZLOGIC_TOAST_MESHBOT_SUCCESSFULLY_DELETED } from '../constants/language_tokens';
import { t } from '../helpers/language';
import { TOAST_MESSAGE_ON_ERROR } from '../constants/toasts';
import { MESHBOT_TYPES } from '../containers/Ezlo/EzloMeshbots/constants';
import { BOOLINT } from '../constants/App';
import { SCENE_RUN_STATUSES } from '../constants/MeshbotConstant';
import { MeshBotAction } from './index';

const actions = {
    getMeshScenes: () => (dispatch) => {
        dispatch(GenericActions.setLineLoading(true));

        return new Promise((resolve, reject) => {
            apiGetMeshScenes()
                .then((res) => {
                    if (res.data) {
                        dispatch(actions.updateMeshScenes(res.data.data.list));
                        dispatch({ type: at.GET_CLOUD_MESHBOT_LIST.success, data: res.data.data.list });
                    }

                    resolve(res);
                })
                .catch((err) => {
                    bugsnagNotifyWrapper(err, {});
                    reject(err);
                })
                .finally(() => dispatch(GenericActions.setLineLoading(false)));
        });
    },

    getPromiseWithTimeout: (timeout, thunk) => (dispatch) => {
        return new Promise((resolve) => {
            setTimeout(() => {
                if (thunk) {
                    dispatch(thunk);
                }
                resolve();
            }, timeout);
        });
    },

    getOneMeshScene: (uuid) => (dispatch) =>
        new Promise((resolve) => {
            apiGetOneMeshScene(uuid)
                .then((res) => {
                    if (res.data.status === 0) {
                        throw new Error(res.data.data.error_text);
                    } else if (res.data.status === 1) {
                        dispatch(CloudMeshbotActions.updateCurrentCloudMeshscene(res.data.data));
                    }

                    resolve(res.data.data);
                })
                .catch((err) => {
                    bugsnagNotifyWrapper(err, { uuid });
                });
        }),

    runMeshScenes: (uuid) => (dispatch) =>
        new Promise((resolve) => {
            apiRunMeshScenes(uuid)
                .then((res) => {
                    const status =
                        res?.data?.status === SUCCESS_STATUS ? SCENE_RUN_STATUSES.FINISHED : SCENE_RUN_STATUSES.FAILED;
                    dispatch(MeshBotAction.setCloudBroadcastStatus({ status, scene_id: uuid }));

                    if (res?.data?.status === ERROR_STATUS) {
                        const message = res.data.data?.error_text || res.data.data?.error_message;
                        throw new Error(message);
                    }
                    resolve(res);
                })
                .catch((err) => {
                    bugsnagNotifyWrapper(err, { uuid });
                });
        }),

    setMeshScene: (data, action) => (dispatch) =>
        new Promise((resolve) => {
            let params = {};

            if (action === meshbot.DELETE_MESHBOT) {
                params = {
                    uuid: data.uuid,
                    delete: 1,
                };
            }

            if (action === meshbot.SET_MESHBOT) {
                params = data;
            }
            dispatch(actions.setSceneProgress());
            apiSetMeshScenes(params)
                .then((res) => {
                    if (res?.data?.status === 0) {
                        dispatch(actions.setSceneSetCatched());
                        throw new Error(res.data.data.error_text);
                    } else if (res?.data?.status === 1) {
                        resolve(res);
                        dispatch(actions.getMeshScenes());
                        dispatch(actions.setSceneSetSuccessfullyFinished());
                    }
                })
                .catch((err) => {
                    dispatch(actions.setSceneSetCatched());
                    bugsnagNotifyWrapper(err, { data, action });
                });
        }),
    /**
     * Thunk creator that return thunk that delete cloud MeshBot
     * @param {Object} meshBot - cloud meshbot data
     * @returns {Function} returns thunk that delete cloud MeshBot
     * @example
     * dispatch(deleteCloudMeshBot({id:'412', name: 'newMeshbot'}))
     * */
    deleteCloudMeshBot:
        ({ id, name }) =>
        async (dispatch) => {
            const params = { uuid: id, delete: BOOLINT.TRUE };

            try {
                const response = await apiSetMeshScenes(params);
                if (response?.data?.status === ERROR_STATUS) {
                    throw new Error(response.data.data.error_text);
                }

                dispatch(actions.clearCloudMeshBotListFromDeletedMeshBot(id));
                toast(`${name} ${t(EZLOGIC_TOAST_MESHBOT_SUCCESSFULLY_DELETED)}`, { type: TOAST_TYPE.SUCCESS });
            } catch (err) {
                const errorMessage = err.message || JSON.stringify(err) || t(TOAST_MESSAGE_ON_ERROR);
                toast(`${name}. ${errorMessage}`, { type: TOAST_TYPE.ERROR });
                bugsnagNotify(err, { params, callFn: 'deleteCloudMeshBot' });
            }
        },
    /**
     * Thunk creator that return thunk that clear Cloud MeshBot list(in redux) from the deleted MeshBot
     * @param {String} deletedMeshBotId - deleted MeshBot id
     * @returns {Function} returns thunk that clear Cloud MeshBot list(in redux) from the deleted MeshBot
     * @example
     * dispatch(clearCloudMeshBotListFromDeletedMeshBot('412'))
     * */
    clearCloudMeshBotListFromDeletedMeshBot: (deletedMeshBotId) => (dispatch, getState) => {
        const {
            meshBot: {
                [MESHBOT_TYPES.CLOUD]: { cloudMeshBot },
            },
        } = getState();
        const updatedCloudMeshBotsList = cloudMeshBot.filter(({ uuid }) => uuid !== deletedMeshBotId);
        dispatch({ type: at.SET_CLOUD_MESHBOT_LIST, updatedCloudMeshBotsList });
    },

    getMeshSceneDetails: () => (dispatch) =>
        new Promise((resolve) => {
            apiGetMeshSceneDetails()
                .then((res) => {
                    if (res.data) {
                        dispatch(actions.updateMeshAbstracts(res.data.data.abstracts));
                    }

                    resolve(res);
                })
                .catch((err) => {
                    bugsnagNotifyWrapper(err, {});
                });
        }),

    getMeshSceneCapabilities: (uuid) => (dispatch) =>
        new Promise((resolve) => {
            apiGetMeshSceneCapabilities(uuid)
                .then((res) => {
                    if (res.data) {
                        dispatch(actions.updateMeshCapabilities(res.data.data.capabilities));
                    }

                    resolve(res);
                })
                .catch((err) => {
                    bugsnagNotifyWrapper(err, { uuid });
                });
        }),

    //----------------------------------------- REDUX ACTIONS SECTION -----------------------------------------------//
    setSceneProgress: () => ({
        type: CloudMeshbotActionTypes.CLOUD_SCENE_SET,
    }),
    setSceneSetSuccessfullyFinished: () => ({
        type: CloudMeshbotActionTypes.CLOUD_SCENE_SUCCESSFULLY_FINISHED,
    }),
    setSceneSetCatched: () => ({
        type: CloudMeshbotActionTypes.CLOUD_SCENE_SET_CAUGHT,
    }),

    updateMeshScenes: (data) => ({
        type: types.UPDATE_MESH_LIST_SCENES,
        data,
    }),

    updateMeshAbstracts: (data) => ({
        type: types.UPDATE_MESH_SCENES_ABSTRACTS,
        data,
    }),

    updateMeshCapabilities: (data) => ({
        type: types.UPDATE_MESH_SCENES_CAPABILITIES,
        data,
    }),

    updateMeshTrigger: (data) => ({
        type: types.UPDATE_MESH_TRIGGER,
        data,
    }),

    updateMeshAction: (data) => ({
        type: types.UPDATE_MESH_ACTION,
        data,
    }),
};

export default actions;
