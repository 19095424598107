import { isString, isEqual, isObject, cloneDeep, get, isArray } from 'lodash';
import * as meshBot from '../../../../../constants/MeshbotConstant';
import { ABSTRACT_COMMAND } from '../../../../../constants/cloudCallNames';
import hash from '../../../../../constants/uniqueHash';
import { checkedEmptyKey, collectSubscriptionData, generateDeviceAdvancedWhenBlock, isDelayValues } from '../../utils';
import { getMinMax } from '../../../../../components/DynamicFields/utils';
import { toast, TOAST_TYPE } from '../../../../../components/Toast';
import {
    ACTION_THEN,
    BOOLEAN_VALUES_AS_STRING,
    CAPABILITY_SETTINGS,
    COMPARATOR,
    COMPARISON_DATA,
    DELAY,
    FOR,
    INPUT_PROPS,
    NAME_ARGUMENT_FIELDS,
    SET_ITEM_VALUE,
    SET_HTTP_REQUEST,
    STATE,
    THEN_SWITCH,
    VALUE,
    VIDOO_CAMERA_CAPABILITIES,
    GROUP_LENGTH,
    OPERATOR_NOT,
    MESHBOT_BLOCKS,
    TRIGGER_TYPES,
} from '../../../../../constants/MeshbotConstant';
import { REPEAT } from '../../../../../constants/MeshbotConstant';
import { FOLLOW } from '../../../../../constants/MeshbotConstant';
import { PULSE } from '../../../../../constants/MeshbotConstant';
import { LATCH } from '../../../../../constants/MeshbotConstant';
import at from '../../../../../constants/ActionTypes/MeshBot';
import {
    BLOCK_FIELD_TYPES,
    OBJECT,
    INDEX_SELECTED_BLOCKS_ELEMENT,
    INDEX_SELECTED_FIELDS_ELEMENT,
    HTTP_REQUEST_FIELD_KIND,
    SELECTED_VALUE_FIELD_INDEX,
    SELECTED_TYPE_FIELD_INDEX,
    SELECTED_METADATA_FIELD_INDEX,
    ZERO_INT,
    ARMED_SELECT_VALUES,
    REACHABLE_SELECT_VALUES,
} from '../../../../../constants/MeshbotConstant';
import { isAllObjectValuesNonEmpty } from '../../../../../helpers/common';
import { buildArrayOfParsedCodeFromString } from '../../../EzloRule/EditForm/RuleSettings/components/PAAS/utils';
import { CLOUD_VARIABLES_META_TYPE, CURRENT_BLOCK_INDEX } from '../../constant';
import {
    ACTION_EXECUTION_POLICY_BY_DEFAULT,
    ACTION_EXECUTION_POLICY_LEVELS,
    CLOUD_VARIABLES,
    CODE_KEY,
    EXECUTION_POLICIES_OF_ALL_ACTIONS,
    EXECUTION_POLICIES_OF_EACH_ACTION,
    MESHBOT_SECTION_TYPE,
} from '../../constants';
import { DATA_TYPES_LIST, DATA_TYPES_OBJ_VALID_LENGTH } from '../../../../../constants/Variables';
import { CUSTOM_PREFIX } from '../../components/SaveOutputBlock/constants';
import { getControllerNodeValueForTrigger } from '../../components/ControllerNodeForTrigger/utils';
import { getControllerNodeValue } from '../../components/ControllerActionNode/utils';
import {
    findSelectedExpression,
    getExpressionComparisonInitialValuesByFieldName,
    isExpressionComparisonTrigger,
} from '../../components/ExpressionComparison/utils';
import { COMPARED_TYPE_VALUE, EXPRESSIONS_TYPE, VALUE_TYPE } from '../../../../../constants/Expressions';
import * as meshbot from '../../../../../constants/MeshbotConstant';
import { ITEM_GROUPS_STATUS, FIELDS_NAMES } from '../../../../../constants/ItemGroups';
import { SUBSCRIPTIONS_STATUS } from '../../../../../constants/Subscription';
import {
    EZLOGIC_TITLE_ACTION_EXECUTION_POLICY_INFO,
    EZLOGIC_TITLE_ACTIONS_EXECUTION_POLICY_INFO,
    EZLOGIC_TITLE_ADD_CONDITION,
    EZLOGIC_TITLE_ADD_EXCEPTION,
    EZLOGIC_TITLE_ADD_TRIGGER,
    EZLOGIC_TOAST_WARNING_MULTIPLE_DATE_TIME_EZLOPI,
    EZLOGIC_TOAST_WARNING_MULTIPLE_DATE_TIME_LOCAL,
} from 'constants/language_tokens';
import { MESHBOT_TYPES } from '../../../../../containers/Ezlo/EzloMeshbots/constants';
import { store } from 'store/configureStore';
import { SWITCH } from '../../../../../constants/SystemHistory';
import { t } from 'helpers/language';

export const findValidatedBlock = (id, nameSection, validationState) => {
    if (validationState[nameSection]) {
        return validationState[nameSection].find((block) => block.id === id);
    }
};

export const findValidatedExceptionWhenBlock = (id, validationState) =>
    findValidatedBlock(id, at.MESHBOT_LOCAL_TRIGGER_BLOCKS.exceptionWhen, validationState);

export const isActionDelayValid = (currentBlock) => {
    const [block] = currentBlock?.blocks;

    if (block.delay) {
        return Object.values(block.delay).some((delayTime) => delayTime > ZERO_INT);
    }
};

const isNotSingleDateAndTimeBlock = (list) => {
    if (
        list.filter((trigger) => trigger.selectedFieldTrigger === meshBot.DATE_AND_TIME).length >
        meshBot.SINGLE_ELEMENT_ARRAY_LENGTH
    ) {
        return true;
    }

    return false;
};

const isIsOnceNode = (list) => {
    if (list.some((trigger) => trigger.selectedFieldDate === meshBot.IS_ONCE)) {
        return true;
    }

    return false;
};

const checkForPresenceOfIsOnceNode = (list, value) => {
    if (value === meshBot.DATE_AND_TIME) {
        return isIsOnceNode(list);
    }

    if (value === meshBot.IS_ONCE) {
        return isNotSingleDateAndTimeBlock(list);
    }
};

const isIntervalNode = (list) => {
    if (list.some((trigger) => trigger.selectedFieldDate === meshBot.INTERVAL)) {
        return true;
    }

    return false;
};

const checkForPresenceOfIntervalNode = (list, value) => {
    if (value === meshBot.DATE_AND_TIME) {
        return isIntervalNode(list);
    }

    if (value === meshBot.INTERVAL) {
        return isNotSingleDateAndTimeBlock(list);
    }
};

const isDateAndTimeBlock = (list) => {
    if (list.some((trigger) => trigger.selectedFieldTrigger === meshBot.DATE_AND_TIME)) {
        return true;
    }

    return false;
};

const checkNodeForDateAndTime = (list, isMultipleSupportedDateAndTime, optionType, value) => {
    if (!isMultipleSupportedDateAndTime) {
        return isDateAndTimeBlock(list);
    }

    if (optionType && optionType === meshBot.OPERATOR_AND) {
        if (checkForPresenceOfIsOnceNode(list, value)) {
            return meshBot.IS_ONCE;
        }

        if (checkForPresenceOfIntervalNode(list, value)) {
            return meshBot.INTERVAL;
        }
    }
};

const getTriggerType = (trigger) => {
    if (trigger && trigger.blocks && trigger.blocks[0]) {
        return trigger.blocks[0]?.blockOptions?.method?.name;
    }
};

// TODO: change name or logic, return true for all zero values?
const isDelayValid = (currentBlock) => {
    const [block] = currentBlock;

    if (block.delay && Object.values(block.delay).every((number) => number === 0)) {
        return true;
    }
};

const getActionType = (action) => {
    if (action && action.blocks && action.blocks[0]) {
        return action.blocks[0]?.blockOptions?.method?.name;
    }
};

const addTriggerInExceptionGroup = (list, data, idGroup) => {
    return list.map((item) => {
        if (item.id === idGroup) {
            item.blocks = [...item.blocks, data];
        } else {
            if (item.type === meshBot.GROUP) {
                addTriggerInExceptionGroup(item.blocks, data, idGroup);
            }
        }

        return item;
    });
};

const removingExceptionTriggerInGroup = (list, id, idGroup) => {
    return list.map((item) => {
        if (item.id === idGroup) {
            item.blocks = item.blocks.filter((block) => block.id !== id);
        } else {
            if (item.type === meshBot.GROUP) {
                removingExceptionTriggerInGroup(item.blocks, id, idGroup);
            }
        }

        return item;
    });
};

export const triggerFunctionsValidation = (currentDeviceBlock) => {
    const triggerFunction = currentDeviceBlock?.function;

    return (
        triggerFunction?.for?.seconds >= 1 ||
        triggerFunction?.follow?.delayReset >= 1 ||
        // Check rules for pulse function
        (triggerFunction?.pulse?.truePeriod > 0 &&
            triggerFunction?.pulse?.falsePeriod > 0 &&
            triggerFunction?.pulse?.times > 0 &&
            Object.keys(triggerFunction?.pulse).length === 3) ||
        (triggerFunction?.pulse?.truePeriod > 0 && Object.keys(triggerFunction?.pulse).length === 1) ||
        (triggerFunction?.pulse?.truePeriod > 0 &&
            triggerFunction?.pulse?.falsePeriod > 0 &&
            Object.keys(triggerFunction?.pulse).length === 2) ||
        //
        (triggerFunction?.repeat?.times >= 2 && triggerFunction?.repeat?.seconds > 0) ||
        (triggerFunction?.latch?.name?.length > 0 && triggerFunction?.latch.enabled)
    );
};

const isFilledField = (fieldNamedValue) => {
    if (
        (fieldNamedValue.type === meshBot.BLOCK_FIELD_TYPES.INT ||
            fieldNamedValue.type === meshBot.BLOCK_FIELD_TYPES.STRING) &&
        fieldNamedValue.value === meshBot.EMPTY_STRING
    ) {
        return false;
    }

    return true;
};

/**
 * This function checks the presence of the device
 * @param {object} currentBlock - "When" block trigger with node
 * @param {array} controllerItems - all controller devices
 * @returns {boolean}
 */
export const isExistingDevice = (currentBlock, controllerDevices) => {
    const deviceId = currentBlock.blocks?.[meshBot.INDEX_OF_ZERO]?.blockMeta?.ruleTrigger?.deviceId;

    return !!controllerDevices.find((device) => device._id === deviceId);
};

/**
 * This function checks the presence of the device group
 * @param {object} currentBlock - "When" block trigger with node
 * @param {array} controllerItems - all controller device groups
 * @returns {boolean}
 */
export const isExistingDeviceGroup = (currentBlock, controllerDeviceGroups) => {
    const deviceGroupId = currentBlock.blocks?.[meshBot.INDEX_OF_ZERO]?.blockMeta?.ruleTrigger?.deviceGroupId;

    return !!controllerDeviceGroups.find((deviceGroup) => deviceGroup._id === deviceGroupId);
};

/**
 * This function checks the presence of the item
 * @param {object} currentBlock - "Action" block action with node
 * @param {array} controllerItems - all controller items
 * @returns {boolean}
 */
export const isExistingItem = (currentBlock, controllerItems) => {
    const blockField = currentBlock.blocks?.[meshBot.INDEX_OF_ZERO]?.fields?.find(
        (field) => field.name === BLOCK_FIELD_TYPES.ITEM,
    );
    const actionItem = blockField?.value;

    return !!controllerItems.find((item) => item._id === actionItem);
};

/**
 * This function checks the presence of the PIN code
 * @param {object} currentBlock - "When" block trigger with node
 * @param {array} controllerItems - all controller items
 * @returns {boolean}
 */
export const isExistingPinCode = (currentBlock, controllerItems) => {
    const blockField = currentBlock.blocks?.[meshBot.INDEX_OF_ZERO]?.fields?.find(
        (field) => field.name === meshbot.WHEN_BLOCK.USER,
    );
    const optionItemValue = blockField?.optionItemValue?.itemId;
    const dictionaryIdValue = blockField?.value;
    const userCodeItem = controllerItems.find((item) => item._id === optionItemValue) || {};

    return userCodeItem?.value === null ? false : userCodeItem?.value.hasOwnProperty(dictionaryIdValue);
};

/**
 * This function checks the presence of the node
 * @param {object} currentBlock - "When" block trigger with node
 * @returns {boolean}
 */
const isExistingNode = (currentBlock) => {
    const state = store.getState();
    const { serial } = state.ezlo;
    const nodeType = currentBlock.blocks?.[meshBot.INDEX_OF_ZERO]?.blockMeta?.metaType;
    const methodName = currentBlock.blocks?.[meshBot.INDEX_OF_ZERO]?.blockOptions?.method?.name;

    if (methodName === COMPARISON_DATA.METHOD.IS_USER_LOCK_OPERATION) {
        const controllerItems = state.ezlo.data?.[serial]?.items || [];

        return isExistingPinCode(currentBlock, controllerItems);
    }

    if (nodeType === meshbot.WHEN_BLOCK.META_TYPE.DEVICE) {
        const controllerDevices = state.ezlo.data?.[serial]?.devices || [];

        return isExistingDevice(currentBlock, controllerDevices);
    }

    if (nodeType === meshbot.WHEN_BLOCK.META_TYPE.DEVICE_GROUP) {
        const controllerDeviceGroups = state.ezlo.data?.[serial]?.deviceGroups?.deviceGroupsList || [];

        return isExistingDeviceGroup(currentBlock, controllerDeviceGroups);
    }

    if (methodName === meshBot.ACTION_TYPES.SET_ITEM_VALUE || methodName === meshBot.ACTION_TYPES.TOGGLE_VALUE) {
        const controllerItems = state.ezlo.data?.[serial]?.items || [];

        return isExistingItem(currentBlock, controllerItems);
    }
};

const validationStateWhenTriggerDeviceIsValid = (currentDeviceBlock, triggerType) => {
    const nodeType = currentDeviceBlock.blocks[meshBot.INDEX_OF_ZERO]?.blockMeta?.metaType;

    if (currentDeviceBlock?.function && !triggerFunctionsValidation(currentDeviceBlock)) {
        return false;
    }

    if (
        (nodeType === meshbot.WHEN_BLOCK.META_TYPE.DEVICE || nodeType === meshbot.WHEN_BLOCK.META_TYPE.DEVICE_GROUP) &&
        !isExistingNode(currentDeviceBlock)
    ) {
        return false;
    }

    if (triggerType === meshBot.TRIGGER_TYPES.COMPARE_NUMBER_RANGE && currentDeviceBlock.blocks[0]) {
        const startValue = currentDeviceBlock.blocks[0].fields.find(
            (field) => field.name === meshBot.COMPARISON_DATA.START_VALUE,
        ).value;
        const endValue = currentDeviceBlock.blocks[0].fields.find(
            (field) => field.name === meshBot.COMPARISON_DATA.END_VALUE,
        ).value;

        return startValue < endValue;
    }

    if (
        (triggerType === meshBot.TRIGGER_TYPES.COMPARE_STRINGS ||
            triggerType === meshBot.TRIGGER_TYPES.STRING_OPERATION ||
            triggerType === meshBot.TRIGGER_TYPES.COMPARE_NUMBERS ||
            triggerType === meshBot.TRIGGER_TYPES.IS_ITEM_STATE) &&
        currentDeviceBlock.blocks[0]
    ) {
        const fieldNamedValue = currentDeviceBlock.blocks[0].fields.find(
            (field) => field.name === meshBot.COMPARISON_DATA.VALUE,
        );

        return isFilledField(fieldNamedValue);
    }

    if (triggerType === meshBot.TRIGGER_TYPES.IS_DEVICE_STATE && currentDeviceBlock.blocks[meshBot.INDEX_OF_ZERO]) {
        const isExistField = currentDeviceBlock.blocks[meshBot.INDEX_OF_ZERO].fields.find(
            (field) => field.type === meshBot.WHEN_BLOCK.FIELDS.TYPE.BOOL,
        );

        return !!isExistField;
    }

    return !!currentDeviceBlock.blocks[0];
};

const validationStateWhenTriggerMeshBotIsValid = (currentDeviceBlock) => {
    if (currentDeviceBlock?.function && !triggerFunctionsValidation(currentDeviceBlock)) {
        return false;
    }

    if (currentDeviceBlock.blocks[0]) {
        return true;
    }

    return false;
};

const validationStateWhenTriggerFunctionIsValid = (currentDeviceBlock) => {
    return !!currentDeviceBlock.blocks[0];
};

const validationStateWhenTriggerHouseModeIsValid = (currentDeviceBlock) => {
    if (currentDeviceBlock?.function && !triggerFunctionsValidation(currentDeviceBlock)) {
        return false;
    }

    return !!currentDeviceBlock?.blocks[0]?.fields[0]?.value?.length > 0;
};

const defineDateAndTimeBlockType = (currentBlock) => {
    return currentBlock.blockOptions.method.name;
};

const validationStateWhenTriggerDateAndTimeIsSunStateIsValid = (currentBlock) => {
    if (!currentBlock.fields.find((field) => field.name === meshBot.TIME_NODE)) {
        return true;
    }

    if (
        currentBlock.fields.find(
            (field) => field.name === meshBot.TIME_NODE && field.type === meshBot.TIME_TYPE_HMS_INTERVAL,
        ) &&
        currentBlock.fields
            .find((field) => field.name === meshBot.TIME_NODE && field.type === meshBot.TIME_TYPE_HMS_INTERVAL)
            ?.value?.split(':')
            .find((elem) => elem !== meshBot.ZERO)
    ) {
        return true;
    }

    return false;
};

const validationStateWhenTriggerDateAndTimeIsDateIsValid = (currentBlock) => {
    if (
        currentBlock.fields?.find((field) => field.name === meshBot.WEEKDAYS)?.type === meshBot.INT_ARRAY_TYPE &&
        currentBlock.fields?.find((field) => field.name === meshBot.WEEKDAYS)?.value.length
    ) {
        return true;
    }

    if (
        currentBlock.fields?.find((field) => field.name === meshBot.DAYS)?.type === meshBot.INT_ARRAY_TYPE &&
        currentBlock.fields?.find((field) => field.name === meshBot.DAYS)?.value.length
    ) {
        return true;
    }

    if (
        currentBlock.fields?.find((field) => field.name === meshBot.WEEKS)?.type === meshBot.INT_ARRAY_TYPE &&
        currentBlock.fields?.find((field) => field.name === meshBot.WEEKS)?.value.length
    ) {
        return true;
    }

    if (currentBlock.fields?.find((field) => field.value === meshBot.DAILY)) {
        return true;
    }

    return false;
};

const validationStateWhenTriggerDateAndTimeIsValid = (currentDateAndTimeBlock) => {
    const [currentBlock] = currentDateAndTimeBlock.blocks;

    if (!currentBlock) {
        return false;
    }
    const dateAndTimeBlockName = defineDateAndTimeBlockType(currentBlock);

    switch (dateAndTimeBlockName) {
        case meshBot.TRIGGER_TYPES.IS_SUN_STATE: {
            return validationStateWhenTriggerDateAndTimeIsSunStateIsValid(currentBlock);
        }
        case meshBot.IS_DATE: {
            return validationStateWhenTriggerDateAndTimeIsDateIsValid(currentBlock);
        }
        case meshBot.TRIGGER_TYPES.IS_DATE_RANGE: {
            if (currentBlock.fields?.find((field) => field.name === meshBot.EMPTY_STRING)) {
                return false;
            }

            return true;
        }
        case meshBot.TRIGGER_TYPES.IS_INTERVAL: {
            return true;
        }
        case meshBot.TRIGGER_TYPES.IS_ONCE: {
            return true;
        }
        default:
            return false;
    }
};

const validationDynamicFields = (item, values) => {
    for (const input of item?.inputs) {
        // todo: simplify
        if (values[input.name] !== '' && values.hasOwnProperty(input.name)) {
            let fieldMinMax = {
                min: input.minimum,
                max: input.maximum,
            };
            if (input.type === 'integer') {
                fieldMinMax = getMinMax(input);
            }

            if (
                !!fieldMinMax.min &&
                !!fieldMinMax.max &&
                values[input.name] < fieldMinMax.min &&
                values[input.name] > fieldMinMax.max
            ) {
                return false;
            }

            if (!!fieldMinMax.min && values[input.name] < fieldMinMax.min) {
                return false;
            }

            if (!!fieldMinMax.max && values[input.name] > fieldMinMax.max) {
                return false;
            }
        }

        if (!!input.required && values.hasOwnProperty(input.name) && values[input.name] === '') {
            return false;
        }
    }

    return true;
};
/**
 * Get validation for the "Make Recording" field in a selected block.
 *
 * This function checks if the value of the "Make Recording" field in the provided selected block falls within the
 * specified range defined by INPUT_PROPS.min = 15 and INPUT_PROPS.max = 60. It returns true if the value is within the range, and
 * false otherwise.
 *
 * @param {Object} selectedBlock - The selected block containing the "Make Recording" field.
 * @returns {boolean} True if the value is within the valid range; otherwise, false.
 *
 * @example
 * const selectedBlock = {
 *   fields: [
 *     { name: 'item', value: 25 },
 *     { name: 'value', value: 5 }
 *   ]
 * };
 * const isValid = getMakeRecordingFieldValidation(selectedBlock);
 * // Result: false (if the value of field with name: 'value' is not within the valid range)
 */
export const getMakeRecordingFieldValidation = (selectedBlock) => {
    const valueField = selectedBlock.fields.find((field) => field.name === VALUE);
    const value = valueField?.value;

    return value >= INPUT_PROPS.min && value <= INPUT_PROPS.max;
};

const validationStateActionIsValid = (currentDeviceBlock, interfaceJSONItems) => {
    const selectedBlock = currentDeviceBlock.blocks[INDEX_SELECTED_BLOCKS_ELEMENT];

    if (isExistingNode(currentDeviceBlock) === false) {
        return false;
    }

    if (selectedBlock) {
        const blockLabel = selectedBlock.label?.text;

        if (interfaceJSONItems && interfaceJSONItems.length > 0) {
            const item = interfaceJSONItems.find((item) => {
                return !!item.apply.find(({ when }) => when.value === blockLabel);
            });

            if (item) {
                const values = currentDeviceBlock?.blocks[0]?.fields[1]?.value;

                if (!values) {
                    const isRequiredFields = !!item.inputs.find((input) => input.required && input.required === true);

                    return !isRequiredFields;
                }

                return !!values && item ? validationDynamicFields(item, values) : false;
            }
        }

        if (blockLabel === VIDOO_CAMERA_CAPABILITIES.MAKE_RECORDING) {
            return getMakeRecordingFieldValidation(selectedBlock);
        }

        if (isDelayValid(currentDeviceBlock.blocks)) {
            return false;
        }

        if (
            currentDeviceBlock.blocks[INDEX_SELECTED_BLOCKS_ELEMENT].fields[INDEX_SELECTED_FIELDS_ELEMENT]?.type ===
                BLOCK_FIELD_TYPES.DICTIONARY &&
            typeof currentDeviceBlock.blocks[INDEX_SELECTED_BLOCKS_ELEMENT].fields[INDEX_SELECTED_FIELDS_ELEMENT]
                .value !== OBJECT
        ) {
            return false;
        }

        const valueField = currentDeviceBlock.blocks[INDEX_SELECTED_BLOCKS_ELEMENT]?.fields?.find(
            (field) => field.type === BLOCK_FIELD_TYPES.TEMPERATURE || field.type === BLOCK_FIELD_TYPES.TOKEN,
        );

        if (valueField) {
            return valueField.value !== '';
        }

        return true;
    }

    return false;
};

const validationStateNotificationIsValid = (currentNotificationBlock) => {
    if (
        currentNotificationBlock &&
        currentNotificationBlock.blocks[0] &&
        currentNotificationBlock.blocks[0].fields[2] &&
        !isDelayValid(currentNotificationBlock.blocks)
    ) {
        const currentBlock = JSON.parse(currentNotificationBlock.blocks[0]?.fields[2].value);
        if (
            currentBlock.notification &&
            currentBlock.notification.parameters[0].body &&
            currentBlock.notification.parameters[1].targets[0].uuid
        ) {
            return true;
        }
    }

    return false;
};

const validationStateActionScriptIsValid = (currentScriptBlock) => {
    if (currentScriptBlock[0]) {
        if (isDelayValid(currentScriptBlock)) {
            return false;
        }

        return true;
    }

    return false;
};

const httpRequestSaveResultIsValid = (blocks) => {
    return Boolean(blocks[0]?.saveResult && !blocks[0]?.saveResult?.args?.name);
};

// TODO: refactoring needed
const httpRequestIsValid = (blocks) => {
    // TODO: move outside
    const isHttp = new RegExp(/^(ftp|http|https):\/\/.+$/);
    const arrayForValidation = blocks[0].fields.filter(
        (item) => item.name === 'url' || (item.name === 'headers' && Object.keys(item.value).length !== 0),
    );

    if (httpRequestSaveResultIsValid(blocks)) {
        return false;
    }

    // TODO: simplify
    const result = arrayForValidation.map((block) => {
        if (block.name === HTTP_REQUEST_FIELD_KIND.URL && isHttp.test(block.value) && !block.value?.endsWith('.')) {
            return true;
        }

        if (block.name === 'headers' && Object.keys(block.value).length !== 0) {
            const keys = Object.keys(block.value);

            const isValidated = keys.map((key) => {
                if (checkedEmptyKey(key)) {
                    return true;
                }

                return false;
            });

            if (isValidated.some((elem) => !elem)) {
                return false;
            }

            return true;
        }

        return false;
    });

    // TODO: simplify
    if (result.some((elem) => !elem) || isDelayValid(blocks)) {
        return false;
    }

    return true;
};

const validationBatteryLevelIsValid = (currentBlock) => {
    if (currentBlock?.function && !triggerFunctionsValidation(currentBlock)) {
        return false;
    }

    return (
        !!getControllerNodeValueForTrigger(currentBlock, VALUE) &&
        getControllerNodeValueForTrigger(currentBlock, COMPARATOR)
    );
};

const validationBatteryLevelIsEdited = (currentLocalVariableBlock, initialLocalVariableBlock) => {
    if (isTriggerFunctionBlockEdited(currentLocalVariableBlock, initialLocalVariableBlock)) {
        return true;
    }

    return (
        getControllerNodeValueForTrigger(currentLocalVariableBlock, COMPARATOR) !==
            getControllerNodeValueForTrigger(initialLocalVariableBlock, COMPARATOR) ||
        Number(getControllerNodeValueForTrigger(currentLocalVariableBlock, VALUE)) !==
            Number(getControllerNodeValueForTrigger(initialLocalVariableBlock, VALUE)) ||
        currentLocalVariableBlock?.not !== initialLocalVariableBlock?.not
    );
};

const validationCloudConnectionIsValid = (currentLocalVariableBlock) => {
    if (currentLocalVariableBlock?.function && !triggerFunctionsValidation(currentLocalVariableBlock)) {
        return false;
    }

    return !!getControllerNodeValueForTrigger(currentLocalVariableBlock, STATE);
};
/**
 * Checks whether the function property of the current block and the initial block are not equal.
 *
 * @param {object} currentBlock - The current block object. It may or may not have a `function` property.
 * @param {object} initialBlock - The initial block object. It may or may not have a `function` property.
 *
 * @returns {boolean} - Returns true if the `function` properties are not equal, false otherwise.
 */
const isTriggerFunctionBlockEdited = (currentBlock, initialBlock) => {
    return !isEqual(currentBlock?.function, initialBlock?.function);
};

const validationCloudConnectionIsEdited = (currentLocalVariableBlock, initialLocalVariableBlock) => {
    if (isTriggerFunctionBlockEdited(currentLocalVariableBlock, initialLocalVariableBlock)) {
        return true;
    }

    return (
        getControllerNodeValueForTrigger(currentLocalVariableBlock, STATE) !==
            getControllerNodeValueForTrigger(initialLocalVariableBlock, STATE) ||
        currentLocalVariableBlock?.not !== initialLocalVariableBlock?.not
    );
};

const validationStateLocalVariableIsValid = (currentLocalVariableBlock) => {
    const currentLocalVariableValueBlock =
        currentLocalVariableBlock?.blocks[INDEX_SELECTED_BLOCKS_ELEMENT]?.fields[SELECTED_VALUE_FIELD_INDEX]?.value;
    const currentLocalVariableType =
        currentLocalVariableBlock?.blocks[INDEX_SELECTED_BLOCKS_ELEMENT]?.fields[SELECTED_TYPE_FIELD_INDEX]?.type;
    const scalableType =
        currentLocalVariableBlock?.blocks[INDEX_SELECTED_BLOCKS_ELEMENT]?.fields[meshBot.SELECTED_METADATA_FIELD_INDEX]
            ?.value?.scalableType;
    const isDelayValid =
        Object.keys(currentLocalVariableBlock?.blocks[INDEX_SELECTED_BLOCKS_ELEMENT]).includes(DELAY) &&
        currentLocalVariableBlock?.blocks[INDEX_SELECTED_BLOCKS_ELEMENT]?.delay
            ? isDelayValues(currentLocalVariableBlock?.blocks[INDEX_SELECTED_BLOCKS_ELEMENT])
            : true;
    const isVariableValuesNotEmpty = Object.values(currentLocalVariableValueBlock)?.every((value) => value !== '');
    const isTokenVariableValuesNotEmpty = Object.values(currentLocalVariableValueBlock)?.every(
        (el) => el.value !== '' && el.text !== '',
    );
    const isTokenVariableValueChecked = Object.values(currentLocalVariableValueBlock)?.some((el) => el.checked);
    const isValidVariableValueObjectLength =
        Object.keys(currentLocalVariableValueBlock).length === DATA_TYPES_OBJ_VALID_LENGTH;

    switch (currentLocalVariableType) {
        case DATA_TYPES_LIST.TYPE_ACTION:
            return isValidVariableValueObjectLength && isVariableValuesNotEmpty && isDelayValid;
        case DATA_TYPES_LIST.TYPE_TOKEN:
            return isTokenVariableValuesNotEmpty && isTokenVariableValueChecked && isDelayValid;
        default:
            if (scalableType && scalableType === DATA_TYPES_LIST.TYPE_SCALABLE) {
                return isValidVariableValueObjectLength && isVariableValuesNotEmpty && isDelayValid;
            }

            return currentLocalVariableValueBlock !== '' && isDelayValid;
    }
};

export const validationStateLocalVariableIsEdited = (currentLocalVariableBlock, initialLocalVariableBlock) => {
    if (
        !isEqual(
            currentLocalVariableBlock?.blocks[INDEX_SELECTED_BLOCKS_ELEMENT]?.fields[SELECTED_TYPE_FIELD_INDEX]?.value,
            initialLocalVariableBlock?.blocks[INDEX_SELECTED_BLOCKS_ELEMENT]?.fields[SELECTED_TYPE_FIELD_INDEX]?.value,
        ) ||
        !isEqual(
            currentLocalVariableBlock?.blocks[INDEX_SELECTED_BLOCKS_ELEMENT]?.fields[SELECTED_VALUE_FIELD_INDEX]?.value,
            initialLocalVariableBlock?.blocks[INDEX_SELECTED_BLOCKS_ELEMENT]?.fields[SELECTED_VALUE_FIELD_INDEX]?.value,
        ) ||
        !isEqual(
            currentLocalVariableBlock?.blocks[INDEX_SELECTED_BLOCKS_ELEMENT]?.delay,
            initialLocalVariableBlock?.blocks[INDEX_SELECTED_BLOCKS_ELEMENT]?.delay,
        )
    ) {
        return true;
    }

    if (
        currentLocalVariableBlock?.blocks[INDEX_SELECTED_BLOCKS_ELEMENT]?.fields[SELECTED_METADATA_FIELD_INDEX].type ===
            DATA_TYPES_LIST.TYPE_TOKEN &&
        !isEqual(
            currentLocalVariableBlock?.blocks[INDEX_SELECTED_BLOCKS_ELEMENT]?.fields[SELECTED_METADATA_FIELD_INDEX]
                .value?.enumValue,
            initialLocalVariableBlock?.blocks[INDEX_SELECTED_BLOCKS_ELEMENT]?.fields[SELECTED_METADATA_FIELD_INDEX]
                .value?.enumValue,
        )
    ) {
        return true;
    }

    return validationExecPolicyIsEdited(currentLocalVariableBlock, initialLocalVariableBlock);
};

const validationStateWhenTriggerDeviceIsEdited = (currentDeviceBlock, initialDeviceBlock) => {
    if (
        !isEqual(currentDeviceBlock?.function, initialDeviceBlock?.function) ||
        !isEqual(currentDeviceBlock?.not, initialDeviceBlock?.not) ||
        !isEqual(currentDeviceBlock?.blocks[0]?.fields, initialDeviceBlock?.blocks[0]?.fields)
    ) {
        return true;
    }

    return false;
};

const validationStateControllerNodeIsValid = (currentControllerBlock) => {
    const controllerNodeValue = getControllerNodeValue(currentControllerBlock);

    if (currentControllerBlock?.blocks[INDEX_SELECTED_BLOCKS_ELEMENT].delay) {
        return isActionDelayValid(currentControllerBlock);
    }

    if (
        currentControllerBlock?.blocks[INDEX_SELECTED_BLOCKS_ELEMENT].blockOptions?.method.name ===
        meshBot.CONTROLLER_CAPABILITY_RESET
    ) {
        return !!controllerNodeValue;
    } else {
        return !!currentControllerBlock?.blocks[INDEX_SELECTED_BLOCKS_ELEMENT].blockOptions?.method.name;
    }
};

export const validationStateControllerNodeIsEdited = (currentControllerBlock, initialControllerBlock) => {
    const currentControllerNodeValue = getControllerNodeValue(currentControllerBlock);
    const initialControllerNodeValue = getControllerNodeValue(initialControllerBlock);
    const isControllerNodeCapabilityEdited = !isEqual(
        currentControllerBlock?.blocks[INDEX_SELECTED_BLOCKS_ELEMENT].blockOptions?.method.name,
        initialControllerBlock?.blocks[INDEX_SELECTED_BLOCKS_ELEMENT].blockOptions?.method.name,
    );
    const isControllerNodeValueEdited = !isEqual(currentControllerNodeValue, initialControllerNodeValue);
    const isActionDelayEdited = !isEqual(
        currentControllerBlock?.blocks[INDEX_SELECTED_BLOCKS_ELEMENT].delay,
        initialControllerBlock?.blocks[INDEX_SELECTED_BLOCKS_ELEMENT].delay,
    );
    const isExecPolicyEdited = validationExecPolicyIsEdited(currentControllerBlock, initialControllerBlock);

    return isControllerNodeCapabilityEdited || isControllerNodeValueEdited || isActionDelayEdited || isExecPolicyEdited;
};

const excludeBlockMeta = (block) => {
    const updatedBlock = cloneDeep(block);

    if (block?.optionType) {
        delete updatedBlock.optionType;
    }

    if (block?.blocks?.[0]?.blockMeta) {
        delete updatedBlock.blocks[0].blockMeta;
    }

    if (
        block?.blocks?.[meshBot.INDEX_SELECTED_VALUE]?.fields?.[meshBot.INDEX_SELECTED_VALUE]?.name === meshBot.INTERVAL
    ) {
        delete updatedBlock.blocks[meshBot.INDEX_SELECTED_VALUE].fields[meshBot.INDEX_SELECTED_VALUE].startTime;
    }

    if (
        block?.blocks?.[meshBot.INDEX_SELECTED_VALUE]?._id &&
        block?.blocks?.[meshBot.INDEX_SELECTED_VALUE]?.actionGroup
    ) {
        delete updatedBlock.blocks[meshBot.INDEX_SELECTED_VALUE]._id;
        delete updatedBlock.blocks[meshBot.INDEX_SELECTED_VALUE].actionGroup;
    }

    return updatedBlock;
};

/**
 * Checks if any data has been edited within the fieldsPaths between two objects.
 *
 * @param {Object} currentBlock - The current object block to check for edits.
 * @param {Object} initialBlock - The initial object block to compare with.
 * @param {Array} fieldsPaths - The array of paths to be compared.
 * @returns {boolean} - Returns true if any data has been edited, otherwise false.
 */
export const hasDataBeenEdited = (currentBlock, initialBlock, fieldsPaths) => {
    const pathOfEditedData = fieldsPaths.find((path) => {
        const value = get(currentBlock, path);
        const initValue = get(initialBlock, path);

        return !isEqual(value, initValue);
    });

    return Boolean(pathOfEditedData);
};

/**
 * Validates the state when a Trigger MeshBot is edited by checking specific fields for changes.
 *
 * @param {Object} currentTrigger - The current state of the Trigger MeshBot.
 * @param {Object} initialTrigger - The initial state of the Trigger MeshBot for comparison.
 * @returns {boolean} - Returns true if any data in the specified fields has been edited, otherwise false.
 */
export const validationStateWhenTriggerMeshBotIsEdited = (currentTrigger, initialTrigger) => {
    const pathsOfDataToVerify = [TRIGGER_TYPES.FUNCTION, OPERATOR_NOT, MESHBOT_BLOCKS];

    return hasDataBeenEdited(currentTrigger, initialTrigger, pathsOfDataToVerify);
};

const validationStateWhenTriggerFunctionIsEdited = (currentDeviceBlock, initialDeviceBlock) => {
    return JSON.stringify(currentDeviceBlock) !== JSON.stringify(initialDeviceBlock);
};

const validationStateWhenTriggerHouseModeIsEdited = (currentDeviceBlock, initialDeviceBlock) => {
    const currentBlock = currentDeviceBlock?.blocks[INDEX_SELECTED_BLOCKS_ELEMENT];
    const initialBlock = initialDeviceBlock?.blocks[INDEX_SELECTED_BLOCKS_ELEMENT];
    const touchedNotOperator = currentDeviceBlock?.not !== initialDeviceBlock?.not;
    const touchedBlockOptions = currentBlock?.blockOptions?.method?.name !== initialBlock?.blockOptions?.method?.name;
    const touchedFieldsValue = !isEqual(currentBlock?.fields, initialBlock?.fields);

    if (isTriggerFunctionBlockEdited(currentDeviceBlock, initialDeviceBlock)) {
        return true;
    }

    return Boolean(touchedNotOperator || touchedBlockOptions || touchedFieldsValue);
};

const validationStateWhenTriggerDateAndTimeIsEdited = (currentDateAndTimeBlock, initialDateAndTimeBlock) => {
    return !isEqual(excludeBlockMeta(currentDateAndTimeBlock), excludeBlockMeta(initialDateAndTimeBlock));
};
//TODO ignore blocks > fields > key orders
/**
 * Function that determines if the current action block has been modified in edit mode.
 * @param {Object} currentActionBlock - current action block
 * @param {Object} initialActionBlock - initial action block
 * @return {boolean} Returns true if the current action's block has been changed
 * */
export const validationStateActionIsEdited = (currentActionBlock, initialActionBlock) => {
    const currentBlock = currentActionBlock?.blocks[INDEX_SELECTED_BLOCKS_ELEMENT];
    const initialBlock = initialActionBlock?.blocks[INDEX_SELECTED_BLOCKS_ELEMENT];
    const isEqualFieldsBlock = isEqual(currentBlock?.fields, initialBlock?.fields);
    const isEqualDelayBlock = isEqual(currentBlock?.delay, initialBlock?.delay);
    const isExecPolicyEdited = validationExecPolicyIsEdited(currentActionBlock, initialActionBlock);

    return !isEqualFieldsBlock || !isEqualDelayBlock || isExecPolicyEdited;
};

export const validationStateNotificationIsEdited = (currentNotificationBlock, initialNotificationBlock) => {
    if (
        currentNotificationBlock &&
        currentNotificationBlock.blocks[INDEX_SELECTED_BLOCKS_ELEMENT] &&
        currentNotificationBlock.blocks[INDEX_SELECTED_BLOCKS_ELEMENT].fields[SELECTED_VALUE_FIELD_INDEX] &&
        initialNotificationBlock &&
        initialNotificationBlock.blocks &&
        initialNotificationBlock.blocks[INDEX_SELECTED_BLOCKS_ELEMENT].fields[SELECTED_VALUE_FIELD_INDEX]
    ) {
        const currentValue =
            currentNotificationBlock.blocks[INDEX_SELECTED_BLOCKS_ELEMENT].fields[SELECTED_VALUE_FIELD_INDEX].value;
        const initialValue =
            initialNotificationBlock.blocks[INDEX_SELECTED_BLOCKS_ELEMENT].fields[SELECTED_VALUE_FIELD_INDEX].value;
        const currentDelay = currentNotificationBlock.blocks[INDEX_SELECTED_BLOCKS_ELEMENT].delay;
        const initialDelay = initialNotificationBlock.blocks[INDEX_SELECTED_BLOCKS_ELEMENT].delay;
        const currentBlock = isString(currentValue) ? JSON.parse(currentValue) : {};
        const initialBlock = isString(initialValue) ? JSON.parse(initialValue) : {};
        const isExecPolicyEdited = validationExecPolicyIsEdited(currentNotificationBlock, initialNotificationBlock);
        const isEqualNotification = isEqual(currentBlock.notification, initialBlock.notification);
        const isEqualDelay = isEqual(currentDelay, initialDelay);

        return !isEqualNotification || isExecPolicyEdited || !isEqualDelay;
    }

    return true;
};

export const validationStateActionScriptIsEdited = (currentScriptBlock, initialScriptBlock) => {
    const currentBlock = currentScriptBlock?.blocks[INDEX_SELECTED_BLOCKS_ELEMENT];
    const initialBlock = initialScriptBlock?.blocks[INDEX_SELECTED_BLOCKS_ELEMENT];
    const isEqualScripFields = isEqual(currentBlock?.fields, initialBlock?.fields);
    const isExecPolicyEdited = validationExecPolicyIsEdited(currentScriptBlock, initialScriptBlock);

    return !isEqualScripFields || isExecPolicyEdited;
};

const validationStateActionSendHttpRequestIsEdited = (currentSendHttpRequestBlock, initialSendHttpRequestBlock) => {
    const currentBlock = currentSendHttpRequestBlock?.blocks[INDEX_SELECTED_BLOCKS_ELEMENT];
    const initialBlock = initialSendHttpRequestBlock?.blocks[INDEX_SELECTED_BLOCKS_ELEMENT];
    const isEqualFieldsBlock = isEqual(currentBlock?.fields, initialBlock?.fields);
    const isEqualDelayBlock = isEqual(currentBlock?.delay, initialBlock?.delay);
    const isExecPolicyEdited = validationExecPolicyIsEdited(currentSendHttpRequestBlock, initialSendHttpRequestBlock);

    return !isEqualFieldsBlock || !isEqualDelayBlock || isExecPolicyEdited;
};

const validationStateWhenExpressionComparisonIsValid = (currentExpressionComparisonBlock, triggerType) => {
    if (currentExpressionComparisonBlock?.function && !triggerFunctionsValidation(currentExpressionComparisonBlock)) {
        return false;
    }

    const expression = getExpressionComparisonInitialValuesByFieldName(
        currentExpressionComparisonBlock,
        EXPRESSIONS_TYPE.EXPRESSION,
    );
    const expressionValueType = getExpressionComparisonInitialValuesByFieldName(
        currentExpressionComparisonBlock,
        VALUE_TYPE,
    );
    const expressionComparator = getExpressionComparisonInitialValuesByFieldName(
        currentExpressionComparisonBlock,
        COMPARATOR,
    );
    const comparedExpressionValue = getExpressionComparisonInitialValuesByFieldName(
        currentExpressionComparisonBlock,
        COMPARED_TYPE_VALUE,
    );
    const selectedComparedValueType =
        currentExpressionComparisonBlock?.blocks[INDEX_SELECTED_BLOCKS_ELEMENT]?.blockMeta.comparedValue;
    const expressionList =
        currentExpressionComparisonBlock?.blocks[INDEX_SELECTED_BLOCKS_ELEMENT]?.blockMeta?.expressionList;

    if (
        expression &&
        expressionValueType &&
        expressionComparator &&
        selectedComparedValueType &&
        comparedExpressionValue !== '' &&
        comparedExpressionValue !== undefined &&
        findSelectedExpression(expression, expressionList)
    ) {
        return true;
    }

    if (
        triggerType === meshBot.TRIGGER_TYPES.COMPARE_NUMBER_RANGE &&
        currentExpressionComparisonBlock.blocks[INDEX_SELECTED_BLOCKS_ELEMENT]
    ) {
        const startValue = currentExpressionComparisonBlock.blocks[INDEX_SELECTED_BLOCKS_ELEMENT].fields.find(
            (field) => field.name === meshBot.COMPARISON_DATA.START_VALUE,
        ).value;
        const endValue = currentExpressionComparisonBlock.blocks[INDEX_SELECTED_BLOCKS_ELEMENT].fields.find(
            (field) => field.name === meshBot.COMPARISON_DATA.END_VALUE,
        ).value;

        return startValue < endValue;
    }

    return false;
};

const validationStateWhenExpressionComparisonIsEdited = (
    currentExpressionComparisonBlock,
    initialExpressionComparisonBlock,
) => {
    if (isTriggerFunctionBlockEdited(currentExpressionComparisonBlock, initialExpressionComparisonBlock)) {
        return true;
    }

    return (
        !isEqual(
            currentExpressionComparisonBlock?.blocks[INDEX_SELECTED_BLOCKS_ELEMENT]?.fields,
            initialExpressionComparisonBlock?.blocks[INDEX_SELECTED_BLOCKS_ELEMENT]?.fields,
        ) || !isEqual(currentExpressionComparisonBlock?.not, initialExpressionComparisonBlock?.not)
    );
};

const validationStateWhenTriggerDevice = ({ trigger, initialTrigger, triggerType }) => {
    return {
        id: trigger.id,
        isValid: validationStateWhenTriggerDeviceIsValid(trigger, triggerType), // вынести в отдельную функцию
        isEdited: validationStateWhenTriggerDeviceIsEdited(trigger, initialTrigger), // вынести в отдельную функцию
    };
};

const validationStateWhenTriggerMeshBot = (currentDeviceBlock, initialDeviceBlock) => {
    return {
        id: currentDeviceBlock.id,
        isValid: validationStateWhenTriggerMeshBotIsValid(currentDeviceBlock), // вынести в отдельную функцию
        // eslint-disable-next-line
        isEdited: validationStateWhenTriggerMeshBotIsEdited(currentDeviceBlock, initialDeviceBlock), // вынести в отдельную функцию
    };
};

const validationStateWhenTriggerFunction = (currentDeviceBlock, initialDeviceBlock) => {
    return {
        id: currentDeviceBlock.id,
        isValid: validationStateWhenTriggerFunctionIsValid(currentDeviceBlock), // вынести в отдельную функцию
        // eslint-disable-next-line
        isEdited: validationStateWhenTriggerFunctionIsEdited(currentDeviceBlock, initialDeviceBlock), // вынести в отдельную функцию
    };
};

/**
 * Determines if there are required fields in the trigger and returns them
 * @param {Object} currentTrigger - current trigger
 * @returns {object} an object with the key isRequiredFields and with requiredFields array.
 * */
const getRequiredFields = (currentTrigger) => {
    const requiredFields = currentTrigger?.blockMeta?.ruleTrigger?.requiredFields;

    return { isRequiredFields: Boolean(requiredFields?.length), requiredFields };
};

/**
 * Provides a function that determines if a required field is filled
 * @param {Object} fields - current trigger's fields
 * @returns {function} function checks that a required field is filled. function returns a boolean value
 * */
const getIsValidRequiredField = (fields) => (requiredFieldName) => {
    return fields.hasOwnProperty(requiredFieldName) && !!fields[requiredFieldName];
};

/**
 * Determines if the when block with 'IsItemChanged' parameter is valid
 * @param {Object} currentTriggerBlock - current trigger's block
 * @returns {Boolean} returns true if all fields in the block are valid otherwise false
 * */
const validationStateWhenIsItemChangedIsValid = (currentTriggerBlock) => {
    if (currentTriggerBlock?.function && !triggerFunctionsValidation(currentTriggerBlock)) {
        return false;
    }

    const currentTrigger = currentTriggerBlock?.blocks[0];
    const { isRequiredFields, requiredFields } = getRequiredFields(currentTrigger);

    if (isRequiredFields) {
        const fields = currentTrigger?.blockMeta?.ruleTrigger?.fields;

        return requiredFields.every(getIsValidRequiredField(fields));
    }

    return Boolean(currentTrigger);
};

/**
 * Prepares trigger block data for validation.
 *
 * @param {Object} triggerBlock - The trigger block to be prepared.
 * @returns {Object} The prepared trigger block with certain properties modified.
 */
export const prepareIsItemChangedDataToValidate = (triggerBlock) => {
    if (!isObject(triggerBlock)) {
        return triggerBlock;
    }
    const preparedBlock = cloneDeep(triggerBlock);
    delete preparedBlock?.optionType;

    preparedBlock.blocks = preparedBlock.blocks.map((block) => {
        if (block?.blockMeta?.metaType === at.DEVICE) {
            const { blockOptions, blockMeta, fields } = block;

            return {
                blockMeta: { metaType: blockMeta.metaType },
                blockOptions,
                fields,
            };
        }

        return block;
    });

    return preparedBlock;
};
/**
 * Determines if the when block with 'IsItemChanged' parameter was edited
 * @param {Object} currentTriggerBlock - current trigger's block
 * @param {Object} initialTriggerBlock - initial trigger's block
 * @returns {Boolean} returns true if the fields have changed otherwise returns false
 * */
const validationStateWhenIsItemChangedIsEdit = (currentTriggerBlock, initialTriggerBlock) => {
    const currentTrigger = prepareIsItemChangedDataToValidate(currentTriggerBlock);
    const initialTrigger = prepareIsItemChangedDataToValidate(initialTriggerBlock);

    return !isEqual(currentTrigger, initialTrigger);
};

/**
 * Builds the validation state for trigger with 'IsItemChanged' parameter
 * @param {Object} currentTriggerBlock - current trigger's block
 * @param {Object} initialTriggerBlock - initial trigger's block
 * @returns {Object} Returns a state with properties: id, isValid, isEdited
 * */
const validationStateWhenIsItemChanged = (currentTriggerBlock, initialTriggerBlock) => ({
    id: currentTriggerBlock.id,
    isValid: validationStateWhenIsItemChangedIsValid(currentTriggerBlock),
    isEdited: validationStateWhenIsItemChangedIsEdit(currentTriggerBlock, initialTriggerBlock),
});

const validationStateWhenTriggerHouseMode = (currentDeviceBlock, initialDeviceBlock) => {
    return {
        id: currentDeviceBlock.id,
        isValid: validationStateWhenTriggerHouseModeIsValid(currentDeviceBlock),
        isEdited: validationStateWhenTriggerHouseModeIsEdited(currentDeviceBlock, initialDeviceBlock),
    };
};

const validationStateWhenExpressionComparison = (
    currentExpressionComparisonBlock,
    initialExpressionComparisonBlock,
    triggerType,
) => {
    return {
        id: currentExpressionComparisonBlock?.id,
        isValid: validationStateWhenExpressionComparisonIsValid(currentExpressionComparisonBlock, triggerType),
        isEdited: validationStateWhenExpressionComparisonIsEdited(
            currentExpressionComparisonBlock,
            initialExpressionComparisonBlock,
        ),
    };
};

const validationStateWhenTriggerDateAndTime = (currentDateAndTimeBlock, initialDateAndTimeBlock) => {
    return {
        id: currentDateAndTimeBlock.id,
        isValid: validationStateWhenTriggerDateAndTimeIsValid(currentDateAndTimeBlock), // вынести в отдельную функцию
        // eslint-disable-next-line
        isEdited: validationStateWhenTriggerDateAndTimeIsEdited(currentDateAndTimeBlock, initialDateAndTimeBlock), // вынести в отдельную функцию
    };
};

const validationStateThenActionDevice = (currentDeviceBlock, initialDeviceBlock, interfaceJSONItems) => {
    return {
        id: currentDeviceBlock.id,
        isValid: validationStateActionIsValid(currentDeviceBlock, interfaceJSONItems), // вынести в отдельную функцию
        isEdited: validationStateActionIsEdited(currentDeviceBlock, initialDeviceBlock), // вынести в отдельную функцию
    };
};

const validationStateThenNotification = (currentNotificationBlock, initialNotificationBlock) => {
    return {
        id: currentNotificationBlock.id,
        isValid: validationStateNotificationIsValid(currentNotificationBlock), // вынести в отдельную функцию
        // eslint-disable-next-line
        isEdited: validationStateNotificationIsEdited(currentNotificationBlock, initialNotificationBlock), // вынести в отдельную функцию
    };
};

const validationStateThenMeshBot = (currentMeshBotBlock, initialMeshBotBlock) => {
    return {
        id: currentMeshBotBlock.id,
        isValid: validationStateActionIsValid(currentMeshBotBlock),
        isEdited: validationStateActionIsEdited(currentMeshBotBlock, initialMeshBotBlock),
    };
};

const validationStateUnlatchMeshBotIsValid = (currentMeshBotBlock) => {
    return !!(
        currentMeshBotBlock.blocks[0]?.fields[0]?.value?.length &&
        currentMeshBotBlock.blocks[0]?.fields[1]?.value?.length
    );
};

const validationStateAllUnlatchMeshBotIsValid = (currentMeshBotBlock) => {
    return !!currentMeshBotBlock.blocks[0]?.fields[0]?.value?.length;
};

export const validationStateAllUnlatchMeshBotIsEdited = (currentMeshBotBlock, initialMeshBotBlock) => {
    const isExecPolicyEdited = validationExecPolicyIsEdited(currentMeshBotBlock, initialMeshBotBlock);

    return (
        isExecPolicyEdited ||
        JSON.stringify(currentMeshBotBlock?.blocks[0]?.fields) !==
            JSON.stringify(initialMeshBotBlock?.blocks[0]?.fields)
    );
};

const validationStateHouseModeActionIsValid = (currentMeshBotBlock) => {
    return Boolean(currentMeshBotBlock.blocks[0]?.fields[0]?.value) &&
        Object.keys(currentMeshBotBlock.blocks[0]).includes(DELAY)
        ? isDelayValues(currentMeshBotBlock.blocks[0])
        : true;
};

export const validationStateHouseModeActionIsEdited = (currentMeshBotBlock, initialMeshBotBlock) => {
    if (currentMeshBotBlock?.id !== initialMeshBotBlock?.id) {
        return true;
    }
    const isExecPolicyEdited = validationExecPolicyIsEdited(currentMeshBotBlock, initialMeshBotBlock);
    const isFieldsEdited = !isEqual(
        currentMeshBotBlock?.blocks[INDEX_SELECTED_BLOCKS_ELEMENT]?.fields,
        initialMeshBotBlock?.blocks[INDEX_SELECTED_BLOCKS_ELEMENT]?.fields,
    );
    const isDelayEdited = !isEqual(
        currentMeshBotBlock?.blocks[INDEX_SELECTED_BLOCKS_ELEMENT]?.delay,
        initialMeshBotBlock?.blocks[INDEX_SELECTED_BLOCKS_ELEMENT]?.delay,
    );

    return isExecPolicyEdited || isFieldsEdited || isDelayEdited;
};

export const validationStateUnlatchMeshBotIsEdited = (currentMeshBotBlock, initialMeshBotBlock) => {
    const isExecPolicyEdited = validationExecPolicyIsEdited(currentMeshBotBlock, initialMeshBotBlock);

    return (
        isExecPolicyEdited ||
        JSON.stringify(currentMeshBotBlock?.blocks[0]?.fields) !==
            JSON.stringify(initialMeshBotBlock?.blocks[0]?.fields)
    );
};

const validationStateUnlatchMeshBot = (currentMeshBotBlock, initialMeshBotBlock) => {
    return {
        id: currentMeshBotBlock.id,
        isValid: validationStateUnlatchMeshBotIsValid(currentMeshBotBlock),
        isEdited: validationStateUnlatchMeshBotIsEdited(currentMeshBotBlock, initialMeshBotBlock),
    };
};

const validationStateAllUnlatchMeshBot = (currentMeshBotBlock, initialMeshBotBlock) => {
    return {
        id: currentMeshBotBlock.id,
        isValid: validationStateAllUnlatchMeshBotIsValid(currentMeshBotBlock),
        isEdited: validationStateAllUnlatchMeshBotIsEdited(currentMeshBotBlock, initialMeshBotBlock),
    };
};

const validationStateActionHouseMode = (currentMeshBotBlock, initialMeshBotBlock) => {
    return {
        id: currentMeshBotBlock.id,
        isValid: validationStateHouseModeActionIsValid(currentMeshBotBlock),
        isEdited: validationStateHouseModeActionIsEdited(currentMeshBotBlock, initialMeshBotBlock),
    };
};

const validationStateThenActionScript = (currentScriptBlock, initialScriptBlock) => {
    return {
        id: currentScriptBlock.id,
        isValid: validationStateActionScriptIsValid(currentScriptBlock.blocks),
        isEdited: validationStateActionScriptIsEdited(currentScriptBlock, initialScriptBlock),
    };
};

const validationStateThenActionSendHttpRequest = (currentSendHttpRequestBlock, initialSendHttpRequestBlock) => {
    return {
        id: currentSendHttpRequestBlock.id,
        isValid: httpRequestIsValid(currentSendHttpRequestBlock.blocks),
        isEdited: validationStateActionSendHttpRequestIsEdited(
            currentSendHttpRequestBlock,
            initialSendHttpRequestBlock,
        ), // вынести в отдельную функцию
    };
};

const validationStateElseActionDevice = (currentDeviceBlock, initialDeviceBlock, interfaceJSONItems) => {
    return {
        id: currentDeviceBlock.id,
        isValid: validationStateActionIsValid(currentDeviceBlock, interfaceJSONItems), // вынести в отдельную функцию
        isEdited: validationStateActionIsEdited(currentDeviceBlock, initialDeviceBlock), // вынести в отдельную функцию
    };
};

const validationStateElseActionMeshBot = (currentMeshBot, initialMeshBot) => {
    return {
        id: currentMeshBot.id,
        isValid: validationStateActionIsValid(currentMeshBot),
        isEdited: validationStateActionIsEdited(currentMeshBot, initialMeshBot),
    };
};

const validationStateElseActionScript = (currentScriptBlock, initialScriptBlock) => {
    return {
        id: currentScriptBlock.id,
        isValid: validationStateActionScriptIsValid(currentScriptBlock.blocks),
        isEdited: validationStateActionScriptIsEdited(currentScriptBlock, initialScriptBlock),
    };
};

const validationStateElseActionSendHttpRequest = (currentSendHttpRequestBlock, initialSendHttpRequestBlock) => {
    return {
        id: currentSendHttpRequestBlock.id,
        isValid: httpRequestIsValid(currentSendHttpRequestBlock.blocks),
        isEdited: validationStateActionSendHttpRequestIsEdited(
            currentSendHttpRequestBlock,
            initialSendHttpRequestBlock,
        ), // вынести в отдельную функцию
    };
};

const validationStateElseNotification = (currentNotificationBlock, initialNotificationBlock) => {
    return {
        id: currentNotificationBlock.id,
        isValid: validationStateNotificationIsValid(currentNotificationBlock), // вынести в отдельную функцию
        // eslint-disable-next-line
        isEdited: validationStateNotificationIsEdited(currentNotificationBlock, initialNotificationBlock), // вынести в отдельную функцию
    };
};

const validationStateLocalVariable = (currentLocalVariableBlock, initialLocalVariableBlock) => {
    return {
        id: currentLocalVariableBlock.id,
        isValid: validationStateLocalVariableIsValid(currentLocalVariableBlock),
        isEdited: validationStateLocalVariableIsEdited(currentLocalVariableBlock, initialLocalVariableBlock),
    };
};

const validationStateCloudConnection = (currentCloudConnectedBlock, initialLocalCloudConnectedBlock) => {
    return {
        id: currentCloudConnectedBlock.id,
        isValid: validationCloudConnectionIsValid(currentCloudConnectedBlock),
        isEdited: validationCloudConnectionIsEdited(currentCloudConnectedBlock, initialLocalCloudConnectedBlock),
    };
};

const validationStateBatteryLevel = (currentCloudConnectedBlock, initialLocalCloudConnectedBlock) => {
    return {
        id: currentCloudConnectedBlock.id,
        isValid: validationBatteryLevelIsValid(currentCloudConnectedBlock),
        isEdited: validationBatteryLevelIsEdited(currentCloudConnectedBlock, initialLocalCloudConnectedBlock),
    };
};

const validationStateControllerNode = (currentControllerBlock, initialControllerBlock) => {
    return {
        id: currentControllerBlock.id,
        isValid: validationStateControllerNodeIsValid(currentControllerBlock),
        isEdited: validationStateControllerNodeIsEdited(currentControllerBlock, initialControllerBlock),
    };
};

export const validationStateWhenTrigger = (trigger, initialTrigger) => {
    const triggerType = getTriggerType(trigger);

    switch (triggerType) {
        case meshBot.TRIGGER_TYPES.IS_CLOUD_STATE:
        case meshBot.TRIGGER_TYPES.IS_BATTERY_STATE:
            return validationStateCloudConnection(trigger, initialTrigger);
        case meshBot.TRIGGER_TYPES.IS_BATTERY_LEVEL:
            return validationStateBatteryLevel(trigger, initialTrigger);
        case meshBot.TRIGGER_TYPES.IS_ITEM_STATE:
        case meshBot.TRIGGER_TYPES.IS_BUTTON_STATE:
        case meshBot.TRIGGER_TYPES.COMPARE_NUMBERS:
        case meshBot.TRIGGER_TYPES.COMPARE_NUMBER_RANGE:
        case meshBot.TRIGGER_TYPES.COMPARE_STRINGS:
        case meshBot.TRIGGER_TYPES.STRING_OPERATION:
        case meshBot.TRIGGER_TYPES.IS_DETECTED_IN_HOTZONE:
        case meshBot.TRIGGER_TYPES.IS_USER_LOCK_OPERATION:
        case meshBot.TRIGGER_TYPES.IS_DEVICE_STATE:
            if (isExpressionComparisonTrigger(trigger)) {
                return validationStateWhenExpressionComparison(trigger, initialTrigger, triggerType);
            }

            return validationStateWhenTriggerDevice({ trigger, initialTrigger, triggerType });
        case meshBot.TRIGGER_TYPES.IS_DATE:
        case meshBot.TRIGGER_TYPES.IS_DATE_RANGE:
        case meshBot.TRIGGER_TYPES.IS_INTERVAL:
        case meshBot.TRIGGER_TYPES.IS_SUN_STATE:
        case meshBot.TRIGGER_TYPES.IS_ONCE:
            return validationStateWhenTriggerDateAndTime(trigger, initialTrigger);
        case meshBot.TRIGGER_TYPES.IS_SCENE_STATE:
            return validationStateWhenTriggerMeshBot(trigger, initialTrigger);
        case meshBot.TRIGGER_TYPES.FUNCTION:
            return validationStateWhenTriggerFunction(trigger, initialTrigger);
        case meshBot.TRIGGER_TYPES.IS_ITEM_STATE_CHANGED:
            return validationStateWhenIsItemChanged(trigger, initialTrigger);
        case meshBot.TRIGGER_TYPES.IS_HOUSE_MODE_CHANGED_TO:
        case meshBot.TRIGGER_TYPES.IS_HOUSE_MODE_CHANGED_FROM:
            return validationStateWhenTriggerHouseMode(trigger, initialTrigger);
        case meshBot.TRIGGER_TYPES.COMPARE_VALUES:
            if (isExpressionComparisonTrigger(trigger)) {
                return validationStateWhenExpressionComparison(trigger, initialTrigger, triggerType);
            }

            return validationStateWhenTriggerDevice({ trigger, initialTrigger, triggerType });
        default:
            return {
                id: trigger.id,
                isValid: false,
                isEdited: true,
            };
    }
};

const validationPaasSaveResultIsEdited = (action, initialAction) => {
    const currentSaveResultField = JSON.stringify(action.blocks?.[0].saveResult || {});
    const previousSaveResultField = JSON.stringify(initialAction?.blocks?.[0]?.saveResult || {});

    return currentSaveResultField !== previousSaveResultField;
};

/**
 * Determines if exec_policy in action was edited
 * @param {Object} action - current action
 * @param {Object} initialAction - initial action
 * @returns {Boolean} returns true if exec_policy have changed otherwise returns false
 * */
export function validationExecPolicyIsEdited(action, initialAction) {
    if (!action) {
        throw new Error('Missing action in validationExecPolicyIsEdited fn');
    }
    const currentBlock = action?.blocks?.[INDEX_SELECTED_BLOCKS_ELEMENT];
    const initialBlock = initialAction?.blocks?.[INDEX_SELECTED_BLOCKS_ELEMENT];
    const isEqualExecPolicy = isEqual(currentBlock?.exec_policy, initialBlock?.exec_policy);

    return !isEqualExecPolicy;
}

export const validationStatePaasIsEdited = (action, initialAction) => {
    if (!action || !initialAction) {
        return true;
    }
    const currentBlock = action?.blocks[INDEX_SELECTED_BLOCKS_ELEMENT];
    const initialBlock = initialAction?.blocks[INDEX_SELECTED_BLOCKS_ELEMENT];
    const isExecPolicyEdited = validationExecPolicyIsEdited(action, initialAction);
    const currentFieldsValue = currentBlock?.fields?.[SELECTED_VALUE_FIELD_INDEX]?.value;
    const initialFieldsValue = initialBlock?.fields?.[SELECTED_VALUE_FIELD_INDEX]?.value;
    const currentFields = JSON.parse(isString(currentFieldsValue) ? currentFieldsValue : '{}');
    const previousFields = JSON.parse(isString(initialFieldsValue) ? initialFieldsValue : '{}');
    const isEqualFields = isEqual(currentFields, previousFields);
    const currentDelayValue = currentBlock?.delay;
    const initialDelayValue = initialBlock?.delay;
    const isEqualDelay = isEqual(currentDelayValue, initialDelayValue);
    const saveResultFieldIsEdited = validationPaasSaveResultIsEdited(action, initialAction);

    return !isEqualFields || saveResultFieldIsEdited || isExecPolicyEdited || !isEqualDelay;
};

const nuCalSaveResultIsValid = (blocks) => {
    return Boolean(blocks[0]?.saveResult && !blocks[0]?.saveResult?.args?.name);
};

const isCodeFieldValid = (parameters) => {
    let isValid = true;
    if (parameters.hasOwnProperty(CODE_KEY)) {
        const parsedSimpleCode = buildArrayOfParsedCodeFromString(parameters[CODE_KEY]);

        isValid =
            parsedSimpleCode &&
            parsedSimpleCode.length &&
            parsedSimpleCode.every((item) => item.capability !== CUSTOM_PREFIX && item.value);
    }

    return isValid;
};

const validationStateThenElsePAAS = (action, initialAction) => {
    const isFieldsRequired = Boolean(action.fieldToValidate?.length);
    let isRequiredFieldsValid = true;
    let isPaasValid = false;

    const isRequiredFieldsFilled = (fields, required) => {
        return required.every((fieldKey) => fields[fieldKey]);
    };

    if (isFieldsRequired) {
        isRequiredFieldsValid = Boolean(isRequiredFieldsFilled(action.fields, action.fieldToValidate));
    }

    const foundedFieldValue = action?.blocks[CURRENT_BLOCK_INDEX]?.fields.find(
        (field) => field.name === 'params',
    )?.value;

    if (foundedFieldValue) {
        const { uuid, parameters } = JSON.parse(foundedFieldValue);
        const { name } = parameters;
        isPaasValid = Boolean(uuid && name && !nuCalSaveResultIsValid(action.blocks) && isCodeFieldValid(parameters));
    }
    const isDelayValid = action?.blocks[CURRENT_BLOCK_INDEX]?.delay
        ? isDelayValues(action?.blocks[CURRENT_BLOCK_INDEX])
        : true;

    return {
        id: action.id,
        isValid: isRequiredFieldsValid && isPaasValid && isDelayValid,
        isEdited: validationStatePaasIsEdited(action, initialAction),
    };
};

const validationStateCloudAPI = (action, initialAction) => {
    if (action?.blocks?.[0]?.fields[0]?.value === 'controller_notify_user') {
        return validationStateThenNotification(action, initialAction);
    }

    if (action?.blocks?.[0]?.fields[0]?.value === ABSTRACT_COMMAND) {
        return validationStateThenElsePAAS(action, initialAction);
    }

    return {
        id: action?.id,
        isValid: false,
        isEdited: true,
    };
};

const validationStateElseCloudAPI = (action, initialAction) => {
    if (action?.blocks?.[0]?.fields[0]?.value === 'controller_notify_user') {
        return validationStateElseNotification(action, initialAction);
    }

    if (action?.blocks?.[0]?.fields[0]?.value === ABSTRACT_COMMAND) {
        return validationStateThenElsePAAS(action, initialAction);
    }

    return {
        id: action?.id,
        isValid: false,
        isEdited: true,
    };
};

const validationStateThenAction = (action, initialAction, interfaceJSONItems) => {
    const actionType = getActionType(action);

    switch (actionType) {
        case meshBot.ACTION_TYPES.SET_ITEM_VALUE:
        case meshBot.ACTION_TYPES.TOGGLE_VALUE:
            return validationStateThenActionDevice(action, initialAction && initialAction, interfaceJSONItems);
        case meshBot.ACTION_TYPES.RUN_CUSTOM_SCRIPT:
            return validationStateThenActionScript(action, initialAction && initialAction);
        case meshBot.ACTION_TYPES.SEND_HTTP_REQUEST:
            return validationStateThenActionSendHttpRequest(action, initialAction && initialAction);
        case meshBot.ACTION_TYPES.RUN_SCENE:
        case meshBot.ACTION_TYPES.SET_SCENE_STATE:
            return validationStateThenMeshBot(action, initialAction);
        case meshBot.ACTION_TYPES.SET_DEVICE_ARMED:
            return validationStateThenActionDevice(action, initialAction && initialAction);
        case meshBot.ACTION_TYPES.CLOUD_API:
            return validationStateCloudAPI(action, initialAction && initialAction);
        case meshBot.ACTION_TYPES.RESET_LATCH:
            return validationStateUnlatchMeshBot(action, initialAction);
        case meshBot.ACTION_TYPES.RESET_SCENE_LATCHES:
            return validationStateAllUnlatchMeshBot(action, initialAction);
        case meshBot.ACTION_TYPES.SWITCH_HOUSE_MODE:
            return validationStateActionHouseMode(action, initialAction);
        case meshBot.ACTION_TYPES.LOCAL_VARIABLE:
            return validationStateLocalVariable(action, initialAction);
        case meshBot.ACTION_TYPES.REBOOT_HUB:
        case meshBot.ACTION_TYPES.RESET_HUB:
            return validationStateControllerNode(action, initialAction);
        default:
            return {
                id: action.id,
                isValid: false,
                isEdited: true,
            };
    }
};

const validationStateElseAction = (action, initialAction, interfaceJSONItems) => {
    const actionType = getActionType(action);

    switch (actionType) {
        case meshBot.ACTION_TYPES.SET_ITEM_VALUE:
        case meshBot.ACTION_TYPES.TOGGLE_VALUE:
            return validationStateElseActionDevice(action, initialAction && initialAction, interfaceJSONItems);
        case meshBot.ACTION_TYPES.RUN_CUSTOM_SCRIPT:
            return validationStateElseActionScript(action, initialAction && initialAction);
        case meshBot.ACTION_TYPES.SEND_HTTP_REQUEST:
            return validationStateElseActionSendHttpRequest(action, initialAction && initialAction);
        case meshBot.ACTION_TYPES.RUN_SCENE:
        case meshBot.ACTION_TYPES.SET_SCENE_STATE:
            return validationStateElseActionMeshBot(action, initialAction);
        case meshBot.ACTION_TYPES.SET_DEVICE_ARMED:
            return validationStateElseActionDevice(action, initialAction && initialAction);
        case meshBot.ACTION_TYPES.CLOUD_API:
            return validationStateElseCloudAPI(action, initialAction && initialAction);
        case meshBot.ACTION_TYPES.SWITCH_HOUSE_MODE:
            return validationStateActionHouseMode(action, initialAction);
        case meshBot.ACTION_TYPES.LOCAL_VARIABLE:
            return validationStateLocalVariable(action, initialAction);
        case meshBot.ACTION_TYPES.REBOOT_HUB:
        case meshBot.ACTION_TYPES.RESET_HUB:
            return validationStateControllerNode(action, initialAction);
        default:
            return {
                id: action.id,
                isValid: false,
                isEdited: true,
            };
    }
};

const validationStateGroupName = (groupName) => {
    return groupName !== '';
};

const validationStateWhenGroupIsValid = (group) => {
    let isValid = false;
    if (group?.function && triggerFunctionsValidation(group) === false) {
        return false;
    }

    if (group.blocks) {
        for (let i = 0; i < group.blocks.length; i++) {
            if (group.blocks[i]?.function && triggerFunctionsValidation(group.blocks[i]) === false) {
                isValid = false;
                break;
            }

            if (group.blocks[i].isValid === false) {
                isValid = false;
                break;
            }

            if (group.blockName) {
                isValid = true;
            }
        }
    }

    return isValid;
};

const validationStateWhenGroupIsEdited = (
    blocks,
    blockName,
    initialName,
    not,
    initialNot,
    optionType,
    initialOptionType,
    groupBlocks,
    initialGroupBlocks,
    group,
    initialGroup,
) => {
    let isEdited = false;
    if (initialGroup && group) {
        if (isTriggerFunctionBlockEdited(group, initialGroup)) {
            return true;
        }

        initialGroup.blocks.map((block, index) => {
            if (!isEqual(block?.blocks[0]?.fields, group?.blocks[index]?.blocks[0]?.fields)) {
                isEdited = true;
            }

            if (block?.function && !isEqual(block?.function, group?.blocks?.[index]?.function)) {
                isEdited = true;
            }

            if (!isEqual(block?.not, group?.blocks?.[index]?.not)) {
                isEdited = true;
            }
        });
    }

    if (blocks) {
        for (let i = 0; i < blocks.length; i++) {
            if (blocks[i].isEdited === true) {
                isEdited = true;
                break;
            }
        }
    }

    if (initialName && blockName !== initialName) {
        isEdited = true;
    }

    if (not !== initialNot) {
        isEdited = true;
    }

    if (initialOptionType && optionType !== initialOptionType) {
        isEdited = true;
    }

    if (groupBlocks?.length !== initialGroupBlocks?.length) {
        isEdited = true;
    }

    return isEdited;
};

const validationStateWhenGroup = (group, initialGroup) => {
    const innerWhen = group.blocks;
    const innerInitialWhen = initialGroup && initialGroup.blocks;

    const result = {
        id: group.id,
        blockName: validationStateGroupName(group.blockName, initialGroup && initialGroup.name),
        blocks: validationStateWhen(innerWhen, innerInitialWhen),
    };
    if (group?.function) {
        result.function = group.function;
    }
    result.isValid = validationStateWhenGroupIsValid(result);
    result.isEdited = validationStateWhenGroupIsEdited(
        result.blocks,
        group.blockName,
        initialGroup && initialGroup.blockName,
        group.not,
        initialGroup && initialGroup.not,
        group.optionType,
        initialGroup && initialGroup.optionType,
        group.blocks,
        initialGroup && initialGroup.blocks,
        group,
        initialGroup && initialGroup,
    );

    return result;
};

const findInvalidBlocks = (blocks) => {
    if (blocks) {
        for (let i = 0; i < blocks.length; i++) {
            if (blocks[i].isValid === false) {
                return false;
            }
        }

        return true;
    }
};

const validationStateIsValid = (whenBlocks, thenBlocks, elseBlocks, exceptionWhenBlocks, currentRule) => {
    let isValid = true;

    if (!findInvalidBlocks(whenBlocks)) {
        isValid = false;
    }

    if (!findInvalidBlocks(thenBlocks)) {
        isValid = false;
    }

    if (!findInvalidBlocks(elseBlocks)) {
        isValid = false;
    }

    if (!findInvalidBlocks(exceptionWhenBlocks)) {
        isValid = false;
    }

    if (currentRule?.function && !triggerFunctionsValidation(currentRule)) {
        isValid = false;
    }

    return isValid;
};

const findEditedBlocks = (blocks) => {
    if (blocks) {
        for (let i = 0; i < blocks.length; i++) {
            if (blocks[i].isEdited === true) {
                return true;
            }
        }

        return false;
    }
};

const validationStateIsEdit = (
    whenBlocks,
    thenBlocks,
    elseBlocks,
    exceptionWhenBlocks,
    name,
    initialName,
    optionType,
    initialOptionType,
    currentWhen,
    initialWhen,
    currentThen,
    initialThen,
    currentElse,
    initialElse,
    currentTopFunction,
    initialTopFunction,
    currentExecPolicy,
    initialExecPolicy,
    currentMeta,
    initialMeta,
) => {
    let isEdited = false;

    if (findEditedBlocks(whenBlocks)) {
        isEdited = true;
    }

    if (findEditedBlocks(thenBlocks)) {
        isEdited = true;
    }

    if (findEditedBlocks(elseBlocks)) {
        isEdited = true;
    }

    if (findEditedBlocks(exceptionWhenBlocks)) {
        isEdited = true;
    }

    if (name !== initialName) {
        isEdited = true;
    }

    if (currentExecPolicy !== initialExecPolicy) {
        isEdited = true;
    }

    if (optionType !== initialOptionType) {
        isEdited = true;
    }

    if (currentWhen?.length !== initialWhen?.length) {
        isEdited = true;
    }

    if (currentThen?.length !== initialThen?.length) {
        isEdited = true;
    }

    if (currentElse?.length !== initialElse?.length) {
        isEdited = true;
    }

    if (!isEqual(currentTopFunction, initialTopFunction)) {
        isEdited = true;
    }

    if (JSON.stringify(currentMeta) !== JSON.stringify(initialMeta)) {
        isEdited = true;
    }

    return isEdited;
};

const validationStateName = (currentRuleName) => {
    return currentRuleName !== '';
};

const validationStateWhen = (currentWhen, initialWhen) => {
    if (currentWhen) {
        return currentWhen.map((block) => {
            let initialBlock = null;

            if (initialWhen) {
                initialBlock = initialWhen?.find((i) => i?.id === block?.id);
            }

            return block?.type !== meshBot.GROUP
                ? validationStateWhenTrigger(block, initialBlock)
                : validationStateWhenGroup(block, initialBlock);
        });
    }
};

/**
 * Validates the exceptions based on certain criteria and generates a list of exception validation state objects.
 *
 * @param {Array.<Object>} exceptions - The array of exception objects. Each object should have '_id', 'triggers', 'function', and 'optionType' properties.
 * @param {string} exceptions._id - The exception id.
 * @param {Array.<Object>} exceptions.triggers - The array of triggers objects of exception.
 * @param {Object} [exceptions.function] - The function block.
 * @param {string} [exceptions.optionType] - The option type(or, xor, and).
 * @param {Array.<Object>} initialExceptions - The initial array of exception objects to compare with the exceptions array.
 *
 * @returns {Array.<Object>} An array of validation states for each validated exception. Each state object contains 'id', 'isValid', and 'isEdited' properties.
 * 'id' - The _id of the exception.
 * 'isValid' - A boolean indicating if the exception passed the validation i.e. if it has a function and triggerFunctionsValidation passes.
 * 'isEdited' - A boolean indicating if the exception function or optionType has been edited compared to initialExceptions.
 *
 * 'highestLevelExceptionsValidationState' array objects structure:
 * {id: <string>, isValid: <boolean>, isEdited: <boolean>}
 */
export const validationStateOfHighestLevelExceptions = (exceptions, initialExceptions) => {
    const highestLevelExceptionsValidationState = [];

    exceptions?.forEach((exception) => {
        if (exception.triggers?.length < GROUP_LENGTH) {
            return;
        }
        const initialException = initialExceptions?.find(({ _id }) => _id === exception._id);
        const exceptionValidationState = {
            id: exception._id,
            isValid: true,
            isEdited: false,
        };

        if (exception?.function && !triggerFunctionsValidation(exception)) {
            exceptionValidationState.isValid = false;
        }

        if (!isEqual(exception?.function, initialException?.function)) {
            exceptionValidationState.isEdited = true;
        }

        if (!isEqual(exception?.optionType, initialException?.optionType)) {
            exceptionValidationState.isEdited = true;
        }

        highestLevelExceptionsValidationState.push(exceptionValidationState);
    });

    initialExceptions?.forEach((initialException) => {
        const currentException = exceptions?.find(({ _id }) => _id === initialException._id);

        if (initialException?.triggers?.length !== currentException?.triggers?.length) {
            highestLevelExceptionsValidationState.push({
                isEdited: true,
            });
        }
    });

    return highestLevelExceptionsValidationState;
};

const validationStateExceptionWhen = (currentRuleExceptions, initialRuleExceptions) => {
    const getExceptionsTriggers = (exceptionsList) => exceptionsList.flatMap((exception) => exception.triggers);

    const currentRuleExceptionWhen = getExceptionsTriggers(currentRuleExceptions);
    const initialRuleExceptionWhen = getExceptionsTriggers(initialRuleExceptions);
    const exceptionsValidationState = validationStateWhen(currentRuleExceptionWhen, initialRuleExceptionWhen);
    const highestLevelExceptionsValidationState = validationStateOfHighestLevelExceptions(
        currentRuleExceptions,
        initialRuleExceptions,
    );

    return exceptionsValidationState.concat(highestLevelExceptionsValidationState);
};

const validationStateElse = (currentElse, initialElse, interfaceJSONItems) => {
    if (currentElse) {
        return currentElse.map((block, index) => {
            let initialBlock = null;

            if (initialElse) {
                // initialBlock = initialElse.find((i) => i.id === block.id);
                initialBlock = initialElse[index];
            }

            return validationStateElseAction(block, initialBlock, interfaceJSONItems);
        });
    }
};

const validationStateThen = (currentThen, initialThen, interfaceJSONItems) => {
    if (currentThen) {
        return currentThen.map((block, index) => {
            let initialBlock = null;

            if (initialThen) {
                // initialBlock = initialThen.find((i) => i.id === block.id);
                initialBlock = initialThen[index];
            }

            return validationStateThenAction(block, initialBlock, interfaceJSONItems);
        });
    }
};

export const buildLocalMeshBotValidationState = (currentRule, initialRule, interfaceJSONItems) => {
    const result = {
        name: validationStateName(currentRule && currentRule.name),
        then: validationStateThen(currentRule && currentRule.then, initialRule && initialRule.then, interfaceJSONItems),
        else: validationStateElse(currentRule && currentRule.else, initialRule && initialRule.else, interfaceJSONItems),
        when: validationStateWhen(currentRule && currentRule.when, initialRule && initialRule.when),
        exceptionWhen: validationStateExceptionWhen(currentRule?.exceptions, initialRule?.exceptions),
    };

    result.isValid = validationStateIsValid(result.when, result.then, result.else, result.exceptionWhen, currentRule);
    result.isEdit = validationStateIsEdit(
        result.when,
        result.then,
        result.else,
        result.exceptionWhen,
        currentRule?.name,
        initialRule?.name,
        currentRule?.optionType,
        initialRule?.optionType,
        currentRule?.when,
        initialRule?.when,
        currentRule?.then,
        initialRule?.then,
        currentRule?.else,
        initialRule?.else,
        currentRule?.function,
        initialRule?.function,
        currentRule.exec_policy,
        initialRule.exec_policy,
        currentRule.meta,
        initialRule.meta,
    );

    return result;
};

/**
 * Returns the value for validation
 * @param {array} list - Initial list triggers
 * @param {string} value - Node value
 * @param {string} id - Trigger id
 * @param {string} idGroup - Group id
 * @return {boolean} returned value for showing message
 * */

export const validationForNodeDateAndTime = (list, value, id, idGroup, isMultipleSupportedDateAndTime, optionType) => {
    if (idGroup) {
        const group = list.find((item) => item.id === idGroup);

        if (group) {
            return checkNodeForDateAndTime(group.blocks, isMultipleSupportedDateAndTime, group.optionType, value);
        }

        for (const block of list) {
            if (block.hasOwnProperty(meshBot.BLOCK_NAME)) {
                if (
                    !validationForNodeDateAndTime(
                        block.blocks,
                        value,
                        id,
                        idGroup,
                        isMultipleSupportedDateAndTime,
                        block.optionType,
                    )
                ) {
                    return false;
                }
            }
        }

        return true;
    }

    return checkNodeForDateAndTime(list, isMultipleSupportedDateAndTime, optionType, value);
};
/**
 * Build correct options to select depending on valueType of selectedCapability
 * @param {Object} selectedCapability - selected capability. example: {valueType: 'token', enum: ['one', 'two']}
 * @returns {Array<Object>} - Options for selection in correct format(Depending on valueType)
 */
export const itemStateValuesToSelect = (selectedCapability) => {
    switch (selectedCapability?.valueType) {
        case meshBot.VALUE_TYPES.TOKEN:
        case meshBot.VALUE_TYPES.BUTTON_STATE:
            return selectedCapability.enum.map((enumItem) => ({ label: enumItem, value: enumItem }));
        case meshBot.VALUE_TYPES.BOOLEAN:
            return meshBot.BOOLEAN_OPTIONS;
        default:
            return [];
    }
};

/**
 * Parses the passed string and returns the boolean value as a string if the passed string turned out to be the boolean key for translation, otherwise the function will return undefined
 * @param {string} key - translation key. format: 'ezlogic.title.true'
 * @returns {undefined | 'true' | 'false'}
 */
export function getBooleanValueFromTranslationKey(key) {
    if (!isString(key)) {
        return undefined;
    }

    const parts = key.split('.');
    const lastPart = parts[parts.length - 1];

    if (BOOLEAN_VALUES_AS_STRING.includes(lastPart)) {
        return lastPart;
    }
}

/**
 * Parses the comparingValue and returns the proper comparingValue value depending on the presence of value or non-correct comparingValue
 * @param {string} comparingValue - comparison value
 * @returns {string} - proper comparison value
 */
export function getComparingValue(comparingValue) {
    if (!comparingValue) {
        return '';
    }
    const booleanValueFromKey = getBooleanValueFromTranslationKey(comparingValue);

    return booleanValueFromKey || comparingValue;
}

/**
 * Generates data for armedState field
 * @param {string} armedStateValue - the value entered by the user in armedState field
 * @returns {Object} - The generated data for armedState field. format: {selectedComparator: { method: 'isItemState' }, armedState: 'some value', comparingValue: null}
 */
export const generateArmedStateFieldData = (armedStateValue) => ({
    selectedComparator: { method: COMPARISON_DATA.METHOD.IS_ITEM_STATE },
    armedState: armedStateValue,
    comparingValue: null,
});
/**
 * Generates data for "comparison value" field
 * @param {string} comparingValue - the value entered by the user in "comparison value" field
 * @returns {Object} - The generated data for "comparison value" field. format: {selectedComparator: { method: 'isItemState' }, comparingValue: 'some value'}
 */
export const generateComparisonValueFieldData = (comparingValue) => ({
    selectedComparator: { method: COMPARISON_DATA.METHOD.IS_ITEM_STATE },
    comparingValue: comparingValue,
    compareTo: VALUE,
});

export const generateTriggerData = (type) => {
    let triggerData = {};

    if (type === meshBot.TRIGGER) {
        triggerData = { id: hash(), not: false };
    }

    if (type === meshBot.GROUP) {
        triggerData = {
            id: hash(),
            not: false,
            blockName: '',
            type: meshBot.GROUP,
            optionType: 'and',
            blocks: [
                { id: hash(), not: false },
                { id: hash(), not: false },
            ],
        };
    }

    return { triggerData };
};

const handleWarningCall = (nodeName, meshbotType) => {
    if (nodeName === meshBot.IS_ONCE) {
        toast(meshBot.WARNING_FOR_THE_ONCE_NODE_AND_OPERATOR_AND, {
            type: TOAST_TYPE.WARNING,
        });
    }

    if (nodeName === meshBot.INTERVAL) {
        toast(meshBot.WARNING_FOR_THE_INTERVAL_NODE_AND_OPERATOR_AND, {
            type: TOAST_TYPE.WARNING,
            autoClose: meshBot.DELAY_FOR_WARNING_FOR_THE_INTERVAL,
        });
    }

    if (nodeName !== meshBot.IS_ONCE && nodeName !== meshBot.INTERVAL && meshbotType !== MESHBOT_TYPES.EZLOPI) {
        toast(t(EZLOGIC_TOAST_WARNING_MULTIPLE_DATE_TIME_LOCAL), {
            type: TOAST_TYPE.WARNING,
        });
    } else if (meshbotType === MESHBOT_TYPES.EZLOPI) {
        toast(t(EZLOGIC_TOAST_WARNING_MULTIPLE_DATE_TIME_EZLOPI), {
            type: TOAST_TYPE.WARNING,
        });
    }
};

export const getTriggerValue = (
    value,
    id,
    idGroup,
    triggers,
    isMultipleSupportedDateAndTime,
    optionType,
    isSupportsIntervalTogetherWithOtherTimeEvents,
    meshbotType,
) => {
    if (value === meshBot.DATE_AND_TIME) {
        if (!isMultipleSupportedDateAndTime) {
            const result = validationForNodeDateAndTime(
                triggers,
                value,
                id,
                idGroup,
                isMultipleSupportedDateAndTime,
                optionType,
            );

            if (result) {
                handleWarningCall(meshBot.EMPTY_STRING, meshbotType);

                return;
            }
        }
        const result = validationForNodeDateAndTime(
            triggers,
            value,
            id,
            idGroup,
            isMultipleSupportedDateAndTime,
            optionType,
        );

        if (result && result === meshBot.IS_ONCE) {
            handleWarningCall(meshBot.IS_ONCE);

            return;
        }

        if (result && result === meshBot.INTERVAL && !isSupportsIntervalTogetherWithOtherTimeEvents) {
            handleWarningCall(meshBot.INTERVAL);

            return;
        }
    }

    if (value === meshBot.IS_ONCE) {
        const result = validationForNodeDateAndTime(
            triggers,
            value,
            id,
            idGroup,
            isMultipleSupportedDateAndTime,
            optionType,
        );

        if (result && result === meshBot.IS_ONCE) {
            handleWarningCall(meshBot.IS_ONCE);

            return;
        }
    }

    if (value === meshBot.INTERVAL && !isSupportsIntervalTogetherWithOtherTimeEvents) {
        const result = validationForNodeDateAndTime(
            triggers,
            value,
            id,
            idGroup,
            isMultipleSupportedDateAndTime,
            optionType,
        );

        if (result && result === meshBot.INTERVAL) {
            handleWarningCall(meshBot.INTERVAL);

            return;
        }
    }

    return value;
};

/**
 * Get action exception trigger
 * @param {array} exceptionsList - exceptions list
 * @param {string} actionId  - action id
 * @returns {object} returned exception
 * @example
 * getActionExceptionTriggers(exceptionsList, actionId)
 */

export const getActionExceptionTriggers = (exceptionsList, actionId) => {
    if (!Array.isArray(exceptionsList)) {
        throw meshBot.TYPE_ERROR;
    }

    return exceptionsList.find((exception) => exception.actionId === actionId);
};

export const isDisabledSaveButton = (
    validationStateObject,
    subscriptionStatus,
    itemGroupsStatus,
    isApiProcessStarted,
) => {
    return (
        !validationStateObject.name ||
        !validationStateObject.isValid ||
        !validationStateObject.isEdit ||
        subscriptionStatus === SUBSCRIPTIONS_STATUS.IN_PROGRESS ||
        itemGroupsStatus === ITEM_GROUPS_STATUS.IN_PROGRESS ||
        !!isApiProcessStarted
    );
};

/**
 * Adding exception
 * @param {array} list - array exception
 * @param {string} actionId - action id
 * @param {object} trigger - trigger object
 * @param {string} exceptionId - exception id
 * @returns {array} returned exception with new trigger
 * @example
 * addException(list, actionId, trigger, exceptionId)
 */

export const addException = (list, actionId, trigger, exceptionId) => {
    if (!Array.isArray(list)) {
        throw meshBot.TYPE_ERROR;
    }

    return [...list, { _id: exceptionId, triggers: [trigger], actionId, optionType: '' }];
};

/**
 * Generates a new global restriction block with a given trigger.
 *
 * @param {object} trigger - The trigger associated with the restriction block.
 * @return {object} - An object representing a global restriction block.
 */
export const getGlobalRestrictionBlock = (trigger) => {
    return {
        _id: `\${${hash()}}`,
        triggers: [trigger],
        blockType: MESHBOT_SECTION_TYPE.GLOBAL_RESTRICTION,
        actionId: MESHBOT_SECTION_TYPE.GLOBAL_RESTRICTION,
    };
};

/**
 * Adding trigger in exception
 * @param {array} list - array exception
 * @param {string} actionId - action id
 * @param {object} trigger - trigger object
 * @param {string} idGroup - group id
 * @returns {array} returned exception with new trigger
 * @example
 * addTriggerInException(list, actionId, trigger)
 */

export const addTriggerInException = (list, actionId, trigger, idGroup) => {
    if (!Array.isArray(list)) {
        throw meshBot.TYPE_ERROR;
    }

    return list.map((item) => {
        if (item.actionId === actionId) {
            item.triggers = idGroup
                ? addTriggerInExceptionGroup(item.triggers, trigger, idGroup)
                : [...item.triggers, trigger];

            if (!item.optionType) {
                item.optionType = [...item.triggers, trigger].length > 1 && meshBot.AND;
            }
        }

        return item;
    });
};

/**
 * Adds a trigger to the global restrictions in the list of exceptions.
 * If the global restriction has a group, it will add the trigger to that group.
 * If the global restriction does not have a group, it will create a new one.
 * It will also ensure that the 'optionType' property is set to `meshBot.AND`
 * if there are more than one triggers in the global restriction.
 * @param {Array<Object>} exceptions - The list of exceptions to add the trigger to.
 * @param {Object} trigger - The trigger to be added.
 * @param {string|number} idGroup - The identifier of the group where the trigger will be added.
 * @returns {Array<Object>} - The array of exceptions after the trigger has been added.
 * @throws {string} - Will throw an error if exceptions is not an array.
 */
export const addTriggerInGlobalRestriction = (exceptions, trigger, idGroup) => {
    if (!isArray(exceptions)) {
        throw meshBot.TYPE_ERROR;
    }

    return exceptions.map((exception) => {
        if (exception.blockType === MESHBOT_SECTION_TYPE.GLOBAL_RESTRICTION) {
            exception.triggers = idGroup
                ? addTriggerInExceptionGroup(exception.triggers, trigger, idGroup)
                : [...exception.triggers, trigger];

            if (!exception.optionType) {
                exception.optionType = [...exception.triggers, trigger].length > 1 && meshBot.AND;
            }
        }

        return exception;
    });
};

/**
 * Creating control in action then or else
 * @param {array} list - array actions
 * @param {string} actionId - action id
 * @param {string} exceptionId - exception id
 * @returns {array} returned actions with control
 * @example
 * creatingControl(list, actionId, exceptionId)
 */

export const creatingControl = (list, actionId, exceptionId) => {
    if (!Array.isArray(list)) {
        throw meshBot.TYPE_ERROR;
    }

    return list.map((action) => {
        if (action.id === actionId) {
            action.blocks[0].control = {
                exception: { type: 'block_reference', blockId: exceptionId },
            };
        }

        return action;
    });
};

/**
 * Removing exception
 * @param {array} list - array actions
 * @param {string} actionId - action id
 * @returns {array} returned exceptions
 * @example
 * removingException(list, actionId)
 */

export const removingException = (list, actionId) => {
    if (!Array.isArray(list)) {
        throw meshBot.TYPE_ERROR;
    }

    return list.filter((except) => except.actionId !== actionId);
};

/**
 * Removing exception trigger
 * @param {array} list - array exception
 * @param {string} id - trigger id
 * @param {string} actionId - action id
 * @param {string} idGroup - group id
 * @returns {array} returned exceptions
 * @example
 * removingExceptionItem(list, actionId)
 */

export const removingExceptionTrigger = (list, id, actionId, idGroup) => {
    if (!Array.isArray(list)) {
        throw meshBot.TYPE_ERROR;
    }

    return list.map((except) => {
        if (except.actionId === actionId) {
            except.triggers = idGroup
                ? removingExceptionTriggerInGroup(except.triggers, id, idGroup)
                : except.triggers.filter((item) => item.id !== id);

            if (except.triggers.length < 2) {
                except.optionType = '';
                delete except.function;
            }
        }

        return except;
    });
};

/**
 * Removing control in actions
 * @param {array} list - array actions
 * @param {string} actionId - action id
 * @returns {array} returned actions
 * @example
 * removingControl(list, actionId)
 */

export const removingControl = (list = [], actionId) => {
    if (!Array.isArray(list)) {
        throw meshBot.TYPE_ERROR;
    }

    return list.map((item) => {
        if (item.id === actionId) {
            delete item.blocks[0].control;
        }

        return item;
    });
};

/**
 * Clear fields date in selectedRule
 * @param {array} list - array triggers
 * @param {string} id - trigger id
 * @returns {array} returned triggers
 * @example
 * clearFieldsDateSelectedRule(list, actionId)
 */

export const clearFieldsDateSelectedRule = (list, id) => {
    if (!Array.isArray(list)) {
        throw meshBot.TYPE_ERROR;
    }

    return list.map((item) => {
        if (item.id === id) {
            item.blocks = [];
        }

        return item;
    });
};

/**
 * Clear fields date in exceptions
 * @param {array} list - array triggers
 * @param {string} id - trigger id
 * @returns {array} returned triggers
 * @example
 * clearFieldsDateExceptions(list, actionId)
 */

export const clearFieldsDateExceptions = (list, id) => {
    if (!Array.isArray(list)) {
        throw meshBot.TYPE_ERROR;
    }

    return list.map((item) => {
        if (item.id === id) {
            item.selectedSpecificLabel = '';
            item.selectedSpecificDate = '';
            item.selectedTimeType = '';
            item.selectDay = '';
            item.selectMonth = '';
            item.selectYear = '';
            item.blocks = [];
        }

        return item;
    });
};

/**
 * Update date selected rule
 * @param {array} list - array selected rule exceptions
 * @param {string} id - trigger id
 * @param {object} block - new block
 * @param {boolean} value - value
 * @param {string} field - field name
 * @returns {array} returned triggers
 * @example
 * updateDateSelectedRule(list, id, block)
 */

export const updateDateSelectedRule = (list, id, block, value, field) => {
    if (!Array.isArray(list)) {
        throw meshBot.TYPE_ERROR;
    }

    return list.map((item) => {
        if (item.id === id) {
            if (field === meshBot.NOT) {
                item.not = value;
            }

            if (block) {
                item.blocks = block;
            }

            if (item?.blocks?.[0]?.blockMeta?.ruleTrigger) {
                item.blocks[0].blockMeta.ruleTrigger.not = value;
            }
        }

        return item;
    });
};

/**
 * Update option type in exceptions
 * @param {array} list - array exceptions
 * @param {string} id - exception id
 * @param {string} value - value option type
 * @returns {array} returned exception
 * @example
 * updateOptionTypeInException(list, id, block)
 */

export const updateOptionTypeInException = (list, id, value) => {
    if (!Array.isArray(list)) {
        throw meshBot.TYPE_ERROR;
    }

    return list.map((item) => {
        if (item.actionId === id) {
            item.optionType = value;
        }

        return item;
    });
};

/**
 * Update function in exceptions
 * @param {array} list - array exceptions
 * @param {string} id - exception id
 * @param {object} value - value function
 * @returns {array} returned exception
 * @example
 * updateFunctionInException(list, id, block)
 */

export const updateFunctionInException = (list, id, value) => {
    if (!Array.isArray(list)) {
        throw meshBot.TYPE_ERROR;
    }

    return list.map((item) => {
        if (item.actionId === id) {
            if (value.type === meshBot.SELECT_FUNCTION) {
                delete item.function;
            }

            if (value.type !== meshBot.SELECT_FUNCTION) {
                item.function = createTriggerFunctionObject(value);
            }
        }

        return item;
    });
};

/**
 * Update date exceptions
 * @param {array} list - array triggers
 * @param {string} id - trigger id
 * @param {object} block - new block
 * @param {string} value - value field
 * @param {string} field - name field
 * @returns {array} returned triggers
 * @example
 * updateDateExceptions(list, id, block, value, field)
 */
export const updateDateExceptions = (list, id, block, value, field) => {
    if (!Array.isArray(list)) {
        throw meshBot.TYPE_ERROR;
    }

    return list.map((item) => {
        if (item.id === id) {
            if (value === meshBot.DAYS || value === meshBot.WEEKDAYS || value === meshBot.END_YEAR) {
                item.selectedSpecificDate = meshBot.IS_DATE;
            }

            if (value === meshBot.INTERVAL || value === meshBot.IS_ONCE) {
                item.selectedSpecificDate = '';
            }

            if (value === meshBot.AFTER || value === meshBot.BEFORE || value === meshBot.BETWEEN) {
                item.selectedSpecificDate = value;
            }

            if (value === meshBot.TIME_OF_DAY) {
                item.selectedTimeType = meshBot.AM;
            }

            if (field && value !== meshBot.PLUS && value !== meshBot.MINUS && value !== meshBot.EMPTY_STRING) {
                item[field] = value;
            }

            if (block) {
                item.blocks = block;
            }
        }

        return item;
    });
};

/**
 * Observer trigger exception
 * @param {object} { blocks, id, idGroup, value } - Required data for subscription update exception
 * @returns {array} returns exceptions
 * @example
 * observerTriggerException({ blocks, id, idGroup, value })
 */

const observerTriggerException = ({ blocks, id, idGroup, value }) => {
    if (idGroup) {
        return blocks.map((item) => {
            if (item.id === idGroup) {
                item.blocks = updateFunctionInExceptionsTrigger({ triggers: item.blocks, id, value });

                return item;
            } else if (item.type === at.GROUP) {
                observerTriggerException({ blocks: item.blocks, id, idGroup, value });
            }

            return item;
        });
    }

    return updateFunctionInExceptionsTrigger({ triggers: blocks, id, value });
};

/**
 * Generate object by function type
 * @param {object} functionData - Function value
 * @returns {object} returns function
 * @example
 * generateObjectByFunctionType(functionData)
 */

const generateObjectByFunctionType = (functionData) => {
    const { type, method, timesNumber, seconds, pulseFunctionPayload, latchName } = functionData;
    const objectsByFunctionType = {
        [FOR]: { method, seconds },
        [REPEAT]: { times: timesNumber, seconds },
        [FOLLOW]: { delayReset: seconds },
        [PULSE]: { ...pulseFunctionPayload },
        [LATCH]: { enabled: true, name: latchName },
    };

    return { ...objectsByFunctionType[type] };
};

/**
 * Update function in exceptions trigger
 * @param {object} {triggers, id, value} - Function value, id trigger and triggers
 * @returns {array} returns triggers
 * @example
 * updateFunctionInExceptionsTrigger({ triggers, id, value })
 */

const updateFunctionInExceptionsTrigger = ({ triggers, id, value }) => {
    return triggers.map((trigger) => {
        if (trigger.id === id) {
            if (value.type === meshBot.SELECT_FUNCTION) {
                delete trigger.function;
            }

            if (value.type !== meshBot.SELECT_FUNCTION) {
                trigger.function = createTriggerFunctionObject(value);
            }
        }

        return trigger;
    });
};

/**
 * Create trigger function object
 * @param {object} functionData - Function value
 * @returns {object} returns function object
 * @example
 * createTriggerFunctionObject(functionData)
 */

export const createTriggerFunctionObject = (functionData) => {
    if (!functionData) {
        throw meshBot.TYPE_ERROR;
    }

    return {
        [functionData.type]: generateObjectByFunctionType(functionData),
    };
};

// add test
export const getExceptionsWithNewFunction = ({ list, actionId, id, idGroup, value }) => {
    return list.map((item) => {
        if (item.actionId === actionId) {
            item.triggers = observerTriggerException({
                blocks: item.triggers,
                id,
                idGroup,
                value,
            });
        }

        return item;
    });
};

/**
 * Updating the meshbot selected rule trigger
 * @param {array} list - triggers array
 * @param {string} id - trigger id
 * @param {object} block - trigger block
 * @returns {array} returns list of triggers
 * @example
 * updateMeshBotSelectedRuleTrigger(list, id, block)
 */

export const updateMeshBotSelectedRuleTrigger = (list, id, block) =>
    list.map((item) => {
        if (item.id === id && block) {
            item.blocks = block;
        }

        return item;
    });

/**
 * Updating the meshbot trigger
 * @param {array} list - triggers array
 * @param {string} id - trigger id
 * @param {object} block - trigger block
 * @param {string} value - trigger state value
 * @returns {array} returns list of triggers
 * @example
 * updateMeshBotTrigger(list, id, block, value)
 */

export const updateMeshBotTrigger = (list, id, block, value) =>
    list.map((item) => {
        if (item.id === id) {
            if (block) {
                item.blocks = block;
            }

            item.meshBotStateValue = value;
        }

        return item;
    });

/**
 * Reseting the meshbot trigger blocks
 * @param {array} list - triggers array
 * @param {string} id - trigger id
 * @returns {array} returns list of triggers
 * @example
 * resetMeshBotTriggerBlocks(list, id)
 */

export const resetMeshBotTriggerBlocks = (list, id) =>
    list.map((item) => {
        if (item.id === id) {
            item.blocks = [];
            delete item.meshBotStateValue;
        }

        return item;
    });

/**
 * Reseting the meshbot selected rule trigger blocks
 * @param {array} list - triggers array
 * @param {string} id - trigger id
 * @returns {array} returns list of triggers
 * @example
 * resetMeshBotSelectedRuleTriggerBlocks(list, id)
 */

export const resetMeshBotSelectedRuleTriggerBlocks = (list, id) =>
    list.map((item) => {
        if (item.id === id) {
            item.blocks = [];
        }

        return item;
    });

/**
 * Updating the meshbot trigger value
 * @param {array} list - triggers array
 * @param {string} id - trigger id
 * @param {string} value - trigger value
 * @returns {array} returns list of triggers
 * @example
 * updateMeshBotTriggerValue(list, id, value)
 */

export const updateMeshBotTriggerValue = (list, id, value) =>
    list.map((item) => {
        if (item.id === id) {
            item.meshBotTriggerValue = value;
        }

        return item;
    });

/**
 * Group name validation
 * @param {object} foundValidatedWhenGroup
 * @param {string} value - new name
 * @param {string} name - old name
 * @returns {boolean} returns result
 * @example
 * groupNameValidation(foundValidatedWhenGroup, value, name)
 */

export const groupNameValidation = (foundValidatedWhenGroup, value, name) => {
    if (!foundValidatedWhenGroup) {
        throw meshBot.TYPE_ERROR;
    }

    const valid = foundValidatedWhenGroup?.isValid;

    if ((!valid && value && !name) || (valid && !value) || (!valid && !value)) {
        return true;
    }

    return false;
};

/**
 * Updating the house mode trigger
 * @param {array} list - triggers array
 * @param {string} id - trigger id
 * @param {object} block - trigger block
 * @returns {array} returns list of triggers
 * @example
 * updateHouseModeTrigger(list, id, block)
 */

export const updateHouseModeTrigger = (list, id, block) =>
    list.map((item) => {
        if (item.id === id && block) {
            item.blocks = block;
        }

        return item;
    });

export const checkMeshbotEditingFormFields = (
    editing,
    nameMeshBot,
    whenBlockSelectedRule,
    thenBlockSelectedRule,
    elseBlockSelectedRule,
) => {
    if (!editing && nameMeshBot && (whenBlockSelectedRule || thenBlockSelectedRule || elseBlockSelectedRule)) {
        return true;
    }
};

/**
 * Updating the device trigger value
 * @param {object} data - object with triggers key values and a list of triggers array
 * @returns {array} returns list of triggers
 * @example
 * updateMeshBotTriggerValue(list, id, value)
 */

export const updateDeviceTriggerHelper = (data) => {
    const {
        list,
        ruleId,
        deviceId,
        selectedCapability,
        selectedComparator,
        compareTo,
        comparingValue,
        armedState,
        isCompareToVariable,
        name,
        reachableState,
    } = data;

    return list.map((item) => {
        if (item.id === ruleId) {
            const updatedItem = cloneDeep(item);

            // Clearing ruleTrigger Fields on Device change
            if (deviceId && updatedItem.deviceId && deviceId !== updatedItem.deviceId) {
                updatedItem.deviceId = deviceId;
                updatedItem.name = name;
                updatedItem.selectedCapability = {};
                updatedItem.selectedComparator = {};

                return updatedItem;
            }

            deviceId && (updatedItem.deviceId = deviceId);
            name && (updatedItem.name = name);
            selectedCapability && (updatedItem.selectedCapability = selectedCapability);
            selectedComparator && (updatedItem.selectedComparator = selectedComparator);
            compareTo && (updatedItem.compareTo = compareTo);
            comparingValue !== undefined && (updatedItem.comparingValue = comparingValue);
            armedState && (updatedItem.armedState = armedState);
            isCompareToVariable !== undefined && (updatedItem.isCompareToVariable = isCompareToVariable);
            reachableState && (updatedItem.reachableState = reachableState);
            armedState === ARMED_SELECT_VALUES.ANY &&
                updatedItem.reachableState === REACHABLE_SELECT_VALUES.ANY &&
                (updatedItem.reachableState = REACHABLE_SELECT_VALUES.REACHABLE);

            if (updatedItem.blocks === undefined) {
                delete updatedItem.blocks;
            }

            if (isAllObjectValuesNonEmpty(updatedItem)) {
                const blockData = generateDeviceAdvancedWhenBlock(updatedItem);
                delete blockData.blockMeta;
                updatedItem.blocks = [blockData];
            }

            return updatedItem;
        }

        return item;
    });
};

export const updateControllerTriggerHelper = (data) => {
    const {
        list,
        ruleId,
        selectedControllerCapability,
        selectedControllerCapabilityComparator,
        selectedControllerCapabilityComparatorValue,
        selectedControllerCapabilityValue,
    } = data;

    return list.map((item) => {
        if (item.id === ruleId) {
            const updatedItem = cloneDeep(item);
            selectedControllerCapability && (updatedItem.selectedControllerCapability = selectedControllerCapability);
            selectedControllerCapability && (updatedItem.selectedControllerCapabilityValue = '');
            selectedControllerCapability && (updatedItem.selectedControllerCapabilityComparatorValue = '');
            selectedControllerCapability && (updatedItem.selectedControllerCapabilityComparator = '');
            selectedControllerCapabilityComparator &&
                (updatedItem.selectedControllerCapabilityComparator = selectedControllerCapabilityComparator);
            selectedControllerCapabilityComparatorValue &&
                (updatedItem.selectedControllerCapabilityComparatorValue = selectedControllerCapabilityComparatorValue);
            selectedControllerCapabilityValue !== undefined &&
                (updatedItem.selectedControllerCapabilityValue = selectedControllerCapabilityValue);

            if (updatedItem.blocks === undefined) {
                delete updatedItem.blocks;
            }

            if (isAllObjectValuesNonEmpty(updatedItem)) {
                const blockData = generateDeviceAdvancedWhenBlock(updatedItem);
                delete blockData.blockMeta;
                updatedItem.blocks = [blockData];
            }

            return updatedItem;
        }

        return item;
    });
};

/**
 * Set function fields
 * @param {object} exception - object with triggers
 * @param {object} exceptionAction - current exception without function and idFunction
 * @returns {object} returns current exception with function and idFunction
 * @example
 * setFunctionFields(exception, exceptionAction)
 */

export const setFunctionFields = (exception, exceptionAction) => {
    if (exception?.triggersSelect?.function) {
        exceptionAction.function = exception.triggersSelect.function;
    }

    if (exception?.triggersSelect?.idFunction) {
        exceptionAction.idFunction = exception.triggersSelect.idFunction;
    }

    return exceptionAction;
};

/** Getting the control
 * @param {object} item - an action
 * @returns {object} returns the control as an object
 * @example
 * getControl(item)
 */

export const getControl = (item) => {
    if (item.blocks[0]?.control) {
        const [{ control }] = item.blocks;

        return control;
    }
};

/**
 * Collects the payload for nucal subscribing to the hub
 * @param {Object} ruleTrigger - nucal trigger data
 * @param {string} capability - abstract capability
 * @returns {Object} payload to request to create nucal subscription on the hub
 * */
const getHubSubscriptionPayloadForNucal = (ruleTrigger, capability) => {
    return {
        uuid: ruleTrigger.accountUuid,
        capability,
        variable: CAPABILITY_SETTINGS.VARIABLE,
        valueType: CAPABILITY_SETTINGS.VALUE_TYPE,
        dictionaryType: false,
        value: '',
    };
};

/**
 * Collects the payload for variable subscribing to the hub
 * @param {Object} ruleTrigger - variable trigger data
 * @returns {Object} payload to request to create variable subscription on the hub
 * */
const getHubSubscriptionPayloadForVariable = (ruleTrigger) => {
    return {
        uuid: ruleTrigger?.selectedAbstract.uuid,
        capability: ruleTrigger?.selectedVariable,
        variable: VALUE,
        valueType: ruleTrigger?.typeVariable,
        dictionaryType: false,
        value: ruleTrigger?.abstractStateGetResult || '',
    };
};

/**
 * Collects data to create a nucal subscription and sets this data in subscriptionsStore
 * @param {Object} subscriptionsStore - storage in which data is stored for subscriptions to the cloud and to the hub
 * @param {Object} ruleTrigger - nucal trigger data
 * @param {Object} ezlo - ezlo from redux store
 * */
const setNucalDataForSubscriptions = (subscriptionsStore, ruleTrigger, ezlo) => {
    const { abstractsUuids, hubSubscriptions, cloudSubscriptions } = subscriptionsStore;
    const { subscriptionId, item, capability } = collectSubscriptionData(ruleTrigger, ezlo);

    if (!item?._id && !abstractsUuids.includes(ruleTrigger.accountUuid)) {
        abstractsUuids.push(ruleTrigger.accountUuid);
    }

    if (!item?._id) {
        hubSubscriptions.push(getHubSubscriptionPayloadForNucal(ruleTrigger, capability));
    }

    cloudSubscriptions.push({ ...ruleTrigger, subscriptionId, item, capability });
};

/** Getting data for hub's and cloud's subscriptions
 * @param {array} whenBlocks - list of selectedRule.when
 * @param { Object } ezlo - ezlo from redux
 * @returns { Object } returns data for hub's and cloud's subscriptions with used abstractsUuids
 * @example
 * getDataForSubscriptions(array, ezlo)
 */
export const getDataForSubscriptions = (whenBlocks, ezlo) => {
    const subscriptionsStore = { hubSubscriptions: [], cloudSubscriptions: [], abstractsUuids: [] };

    (function startRecursive(whenBlocks) {
        for (const item of whenBlocks) {
            const blockMeta = item?.blockMeta;
            const ruleTrigger = blockMeta?.ruleTrigger;
            if (item.blocks && Array.isArray(item.blocks) && item.blocks.length > 0) {
                startRecursive(item.blocks);
            } else if (
                blockMeta &&
                blockMeta.metaType === CLOUD_VARIABLES_META_TYPE &&
                !ruleTrigger.selectedCapability?._id
            ) {
                const payload = getHubSubscriptionPayloadForVariable(ruleTrigger);
                subscriptionsStore.hubSubscriptions.push(payload);
            }

            if (item.blockMeta && item.blockMeta.metaType === meshbot.WHEN_BLOCK.META_TYPE.NUCAL_SUBSCRIPTION) {
                setNucalDataForSubscriptions(subscriptionsStore, ruleTrigger, ezlo);
            }
        }
    })(whenBlocks);

    return subscriptionsStore;
};

/** Checks that the added element belongs to the subscription that was created
 * @param {Object} addedItem - item that have been added
 * @param { Object } subscription - subscription object with data about it
 * @returns { Boolean } if item belongs to the subscription then true will be returned
 */
const isItemOfCreatedSubscription = (addedItem, subscription) => {
    const { ruleTrigger } = subscription.blockMeta;

    return Boolean(
        addedItem.abstract_uuid === ruleTrigger.accountUuid &&
            addedItem.variableName.split('.')[0] === ruleTrigger.capability,
    );
};

/** Iterates through whenBloks and writes in 'value' field with value of _id taken from the added item
 * @param {Array<Object>} whenBlocks - list of when blocks
 * @param { Array<Object> } addedItems - item that have been added
 * @returns { Array<Object> } returns whenBlocks with 'value' fields filled
 */
export const updateSelectedWhenBlock = (whenBlocks, addedItems) => {
    (function startRecursive(whenBlocks) {
        for (const whenBlock of whenBlocks) {
            const blockMeta = whenBlock?.blockMeta;
            const ruleTrigger = blockMeta?.ruleTrigger;

            if (whenBlock.blocks && Array.isArray(whenBlock.blocks) && whenBlock.blocks.length > 0) {
                startRecursive(whenBlock.blocks);
            } else if (blockMeta && blockMeta.metaType === CLOUD_VARIABLES) {
                const foundValue = addedItems.find(
                    (variable) =>
                        variable.abstract_uuid === ruleTrigger.selectedAbstract.uuid &&
                        variable.variableName.split('.')[0] === ruleTrigger.selectedVariable,
                );

                if (foundValue && foundValue.variableName.split('.')[0] === ruleTrigger.selectedVariable) {
                    ruleTrigger.selectedCapability._id = foundValue._id;
                    whenBlock.fields[0].value = foundValue._id;
                }
            }

            if (blockMeta && blockMeta.metaType === meshbot.WHEN_BLOCK.META_TYPE.NUCAL_SUBSCRIPTION) {
                const foundAddedItem = addedItems.find((addedItem) =>
                    isItemOfCreatedSubscription(addedItem, whenBlock),
                );

                if (foundAddedItem?.variableName.split('.')[0] === ruleTrigger.capability) {
                    whenBlock.fields[0].value = foundAddedItem._id;
                }
            }
        }
    })(whenBlocks);

    return whenBlocks;
};

/** Sets the item's _id and capability to when block if this data is in found subscription
 * @param {Object} whenBlock - nucal's when block
 * @param { Array<Object> } subscriptions - subscriptions' list
 */
const setSubscriptionDataInWhenBlock = (subscriptions, whenBlock) => {
    const foundSubscription = subscriptions.find(({ id }) => id === whenBlock.blockMeta.ruleTrigger.id);

    if (foundSubscription?.item?._id) {
        whenBlock.fields[0].value = foundSubscription.item._id;
    }

    if (foundSubscription) {
        whenBlock.blockMeta.ruleTrigger.capability = foundSubscription.capability;
    }
};

/** Iterates through whenBloks and writes in 'value' field with value of _id taken from the added item and writes capability
 * @param {Array<Object>} whenBlocks - list of when blocks
 * @param { Array<Object> } subscriptions - subscriptions' list
 * @returns { Array<Object> } returns whenBlocks with 'value' fields filled and capability
 */
export const scatterItemPropertyInWhenNucalBlocks = (whenBlocks, subscriptions) => {
    (function startRecursive(whenBlocks) {
        for (const whenBlock of whenBlocks) {
            const blockMeta = whenBlock?.blockMeta;
            const blocks = whenBlock?.blocks;

            if (blocks && Array.isArray(blocks) && blocks.length > 0) {
                startRecursive(whenBlock.blocks);
            }

            if (blockMeta && blockMeta.metaType === meshbot.WHEN_BLOCK.META_TYPE.NUCAL_SUBSCRIPTION) {
                setSubscriptionDataInWhenBlock(subscriptions, whenBlock);
            }
        }
    })(whenBlocks);

    return whenBlocks;
};

export const checkListForIsOnceOrIntervalNodes = (filteredList, nodeName) => {
    if (filteredList && filteredList.length > meshBot.SINGLE_ELEMENT_ARRAY_LENGTH) {
        return filteredList?.find((elem) => elem.selectedFieldDate === nodeName);
    }
};

export const verifyForAbsenceIsOnceNodeForGroups = (triggersList, idGroup) => {
    const group = triggersList.find((item) => item.id === idGroup);

    if (group && group.blocks.length <= meshBot.SINGLE_ELEMENT_ARRAY_LENGTH) {
        return true;
    }
    const filteredList = group?.blocks?.filter((trigger) => trigger.selectedFieldTrigger === meshBot.DATE_AND_TIME);

    if (!checkListForIsOnceOrIntervalNodes(filteredList, meshBot.IS_ONCE)) {
        return true;
    }

    return false;
};

/**
 * verifying for absence isOnce node
 * @param {array} triggersList - triggers array
 * @param {string} idGroup - group id
 * @returns {boolean} returns boolean value
 * @example
 * verifyForAbsenceIsOnceNode(triggersList, idGroup)
 */

export const verifyForAbsenceIsOnceNode = (triggersList, idGroup) => {
    if (idGroup) {
        return verifyForAbsenceIsOnceNodeForGroups(triggersList, idGroup);
    }

    if (triggersList.length <= meshBot.SINGLE_ELEMENT_ARRAY_LENGTH) {
        return true;
    }
    const filteredList = triggersList?.filter((trigger) => trigger.selectedFieldTrigger === meshBot.DATE_AND_TIME);
    if (!checkListForIsOnceOrIntervalNodes(filteredList, meshBot.IS_ONCE)) {
        return true;
    }

    return false;
};

export const verifyForAbsenceIntervalNodeForGroups = (triggersList, idGroup) => {
    const group = triggersList.find((item) => item.id === idGroup);

    if (group && group.blocks.length <= meshBot.SINGLE_ELEMENT_ARRAY_LENGTH) {
        return true;
    }
    const filteredList = group?.blocks?.filter((trigger) => trigger.selectedFieldTrigger === meshBot.DATE_AND_TIME);

    if (!checkListForIsOnceOrIntervalNodes(filteredList, meshBot.INTERVAL)) {
        return true;
    }

    return false;
};

/**
 * verifying for absence interval node
 * @param {array} triggersList - triggers array
 * @param {string} idGroup - group id
 * @returns {boolean} returns boolean value
 * @example
 * verifyForAbsenceIntervalNode(triggersList, idGroup)
 */

export const verifyForAbsenceIntervalNode = (triggersList, idGroup) => {
    if (idGroup) {
        return verifyForAbsenceIntervalNodeForGroups(triggersList, idGroup);
    }

    if (triggersList.length <= meshBot.SINGLE_ELEMENT_ARRAY_LENGTH) {
        return true;
    }
    const filteredList = triggersList.filter((trigger) => trigger.selectedFieldTrigger === meshBot.DATE_AND_TIME);
    if (!checkListForIsOnceOrIntervalNodes(filteredList, meshBot.INTERVAL)) {
        return true;
    }

    return false;
};

const getCapabilityData = (ruleTrigger) => {
    const { id } = ruleTrigger;
    const { name, valueType } = ruleTrigger.selectedCapability;

    if (valueType === 'token') {
        const { enum: capabilityEnum } = ruleTrigger.selectedCapability;

        return {
            name,
            enum: capabilityEnum,
            blockIds: [id],
            status: false,
            valueType,
        };
    }

    return {
        name,
        blockIds: [id],
        status: false,
        valueType,
    };
};

const addCapability = (capabilitiesList, capabilityData) => {
    if (capabilitiesList.hasOwnProperty(capabilityData.name)) {
        Object.values(capabilitiesList).forEach((elem) => {
            if (elem.name === capabilityData.name) {
                elem.blockIds = [...elem.blockIds, ...capabilityData.blockIds];
            }
        });

        return capabilitiesList;
    }

    return { ...capabilitiesList, [capabilityData.name]: { ...capabilityData } };
};

/** Getting data for creating missing item groups
 * @param {Array} whenBlocks - list "when blocks"
 * @returns { Object } returns capabilities list for creating missing item groups
 * getMissingItemGroupsCapabilitiesList(array)
 */

export const getMissingItemGroupsCapabilitiesList = (whenBlocks) => {
    let capabilitiesList = {};

    (function startRecursive(whenBlocks) {
        for (const item of whenBlocks) {
            const blockMeta = item?.blockMeta;
            const ruleTrigger = blockMeta?.ruleTrigger;
            const itemGroupId = ruleTrigger?.itemGroupId;

            if (item.blocks && Array.isArray(item.blocks) && item.blocks.length) {
                startRecursive(item.blocks);
            } else if (
                blockMeta &&
                blockMeta.metaType === meshbot.WHEN_BLOCK.META_TYPE.DEVICE_GROUP &&
                itemGroupId === ''
            ) {
                const capabilityData = getCapabilityData(ruleTrigger);

                capabilitiesList = addCapability(capabilitiesList, capabilityData);
            }
        }
    })(whenBlocks);

    return capabilitiesList;
};

/** Checking if an item group exists in the item groups list
 * @param {Object} data - Data of controllers registered on the account
 * @param {string} serial - Controller serial number
 * @param {Object} response - Broadcast about creation item group
 * @returns { Boolean }
 * isItemGroupInList(data, serial, response)
 */

export const isItemGroupInList = (data, serial, response) => {
    if (!data) {
        throw new Error('No initial data');
    }
    const itemGroupId = response.result._id;
    const itemGroupsList = data[serial].itemGroups?.itemGroupsList;

    if (itemGroupsList) {
        return !!itemGroupsList.find((itemGroup) => itemGroup._id === itemGroupId);
    }
};

/** Update When blocks
 * @param {Array} whenBlocks - list "when blocks"
 * @param {Object} capabilitiesList - Collected capabilities to create corresponding item groups
 * @param {Object} response - Broadcast about creation item group
 * @returns {void}
 * updateWhenBlocks(whenBlocks, capabilitiesList, response)
 */

export const updateWhenBlocks = (whenBlocks, capabilitiesList, response) => {
    const itemGroupName = response.result.name;
    const itemGroupId = response.result._id;

    Object.values(capabilitiesList)
        .find((capabilityData) => capabilityData.name === itemGroupName)
        .blockIds.forEach((id) => {
            (function startRecursive(whenBlocks) {
                for (const item of whenBlocks) {
                    const blockMeta = item?.blockMeta;
                    const ruleTrigger = blockMeta?.ruleTrigger;

                    if (item.blocks && Array.isArray(item.blocks) && item.blocks.length) {
                        startRecursive(item.blocks);
                    } else if (blockMeta && ruleTrigger?.id === id) {
                        item.fields.find((field) => field.name === FIELDS_NAMES.ITEM_GROUP).value = itemGroupId;
                    }
                }
            })(whenBlocks);
        });
};

/** Determine the general status of the capabilities list - are all item groups created?
 * @param {Object} capabilitiesList - Collected capabilities to create corresponding item groups
 * @returns { Boolean }
 * defineCapabilitiesGeneralStatus(capabilitiesList)
 */

export const defineCapabilitiesGeneralStatus = (capabilitiesList) => {
    if (Object.keys(capabilitiesList).length) {
        return Object.values(capabilitiesList).filter((capabilityData) => capabilityData.status === false).length;
    }
};

/**
 * generates an action block with fields from the current block
 * @param {Object} currentBlock - current action block
 * @param {Object} newBlock - new action block
 * @returns {Object} returns action block with fields from current action
 * @example
 * generateActionBlock(currentBlock, newBlock)
 */
export const generateActionBlock = (currentBlock, newBlock) => {
    if (currentBlock?.exec_policy && newBlock) {
        newBlock.exec_policy = currentBlock.exec_policy;
    }

    if (currentBlock?.exec_policy && !newBlock) {
        return { exec_policy: currentBlock.exec_policy };
    }

    if (currentBlock?.blockOptions?.method?.name === SET_HTTP_REQUEST && currentBlock?.saveResult) {
        return { ...newBlock, saveResult: currentBlock.saveResult };
    }

    return newBlock || {};
};

/**
 * Finds exec_policy value in the store(redux)
 * @param {Object} selectedRule - selectedRule from store
 * @param {string} executionPolicyLevel - execution policy level
 * @param {string} id - action id
 * @param {string} blockType - action type(then/else)
 * @returns {string} returns value of execution policy
 * @example
 * getActionExecutionPolicy(currentBlock, newBlock)
 */
export function getActionExecutionPolicy(selectedRule, executionPolicyLevel, id, blockType) {
    if (!executionPolicyLevel) {
        throw new Error('Missing executionPolicyLevel in getActionExecutionPolicy fn');
    }

    if (!selectedRule) {
        throw new Error('Missing selectedRule in getActionExecutionPolicy fn');
    }

    if (executionPolicyLevel === ACTION_EXECUTION_POLICY_LEVELS.EACH_ACTION) {
        if (!blockType) {
            throw new Error('Missing blockType in getActionExecutionPolicy fn');
        }

        if (!id) {
            throw new Error('Missing id in getActionExecutionPolicy fn');
        }

        const action = selectedRule[blockType].find((action) => action.id === id);

        return action?.blocks?.[INDEX_SELECTED_BLOCKS_ELEMENT]?.exec_policy || ACTION_EXECUTION_POLICY_BY_DEFAULT;
    }

    if (executionPolicyLevel === ACTION_EXECUTION_POLICY_LEVELS.SCENE) {
        return selectedRule.exec_policy || '';
    }
}

/**
 * Provides a list based on execution policy level
 * @param {string} level - execution policy level
 * @returns {Array} returns list of execution policies
 * @example
 * getExecutionPoliciesByLevel(currentBlock, newBlock)
 */
export function getExecutionPoliciesByLevel(level) {
    if (!level) {
        throw new Error('missing the argument "level" in the function getExecutionPoliciesByLevel');
    }

    if (level === ACTION_EXECUTION_POLICY_LEVELS.SCENE) {
        return EXECUTION_POLICIES_OF_ALL_ACTIONS;
    }

    if (level === ACTION_EXECUTION_POLICY_LEVELS.EACH_ACTION) {
        return EXECUTION_POLICIES_OF_EACH_ACTION;
    }
}

/**
 * Provides Execution Policy info based on execution policy level
 * @param {string} level - execution policy level
 * @returns {string} returns Execution Policy info
 * @example
 * getExecutionPolicyInfoByLevel(currentBlock, newBlock)
 */
export function getExecutionPolicyInfoByLevel(level) {
    if (!level) {
        throw new Error('missing the argument "level" in the function getExecutionPolicyInfoByLevel');
    }

    if (level === ACTION_EXECUTION_POLICY_LEVELS.SCENE) {
        return EZLOGIC_TITLE_ACTIONS_EXECUTION_POLICY_INFO;
    }

    if (level === ACTION_EXECUTION_POLICY_LEVELS.EACH_ACTION) {
        return EZLOGIC_TITLE_ACTION_EXECUTION_POLICY_INFO;
    }
}

/**
 * This function determines whether to disable the "Add Action" button for "Then" block
 * @param {string} meshbotType - meshbot type
 * @param {array} groupActions - rule actions of "Then" block
 * @returns {boolean}
 */
export const disableAddActionButtonForThenBlock = (meshbotType, groupActions) => {
    if (meshbotType === MESHBOT_TYPES.EZLOPI && groupActions.length) {
        return true;
    }

    return false;
};

/**
 * This function determines whether to disable the "Add Action" button for "Else" block
 * @param {string} meshbotType - meshbot type
 * @param {array} groupElseActions - rule actions of "Else" block
 * @returns {boolean}
 */
export const disableAddActionButtonForElseBlock = (meshbotType, groupElseActions) => {
    if (meshbotType === MESHBOT_TYPES.EZLOPI && groupElseActions.length) {
        return true;
    }

    return false;
};

const getSwitchActionTemplateFields = (idValue, booleanValue) => {
    return {
        blockOptions: {
            method: {
                args: {
                    item: NAME_ARGUMENT_FIELDS.ITEM,
                    value: VALUE,
                },
                name: SET_ITEM_VALUE,
            },
        },
        blockType: ACTION_THEN,
        fields: [
            {
                name: NAME_ARGUMENT_FIELDS.ITEM,
                type: NAME_ARGUMENT_FIELDS.ITEM,
                value: idValue,
            },
            {
                name: VALUE,
                enum: [],
                type: DATA_TYPES_LIST.TYPE_BOOLEAN,
                value: booleanValue,
            },
        ],
    };
};
export const getTemplateForSwitch = (copyAction, value, itemId) => {
    if (!copyAction) {
        throw new Error('There is no data');
    } else if (copyAction?.blocks?.[INDEX_SELECTED_BLOCKS_ELEMENT]) {
        const idValue =
            copyAction?.blocks?.[INDEX_SELECTED_BLOCKS_ELEMENT]?.fields?.[INDEX_SELECTED_BLOCKS_ELEMENT]?.value;

        copyAction.blocks[INDEX_SELECTED_BLOCKS_ELEMENT] = generateActionBlock(
            copyAction.blocks[INDEX_SELECTED_BLOCKS_ELEMENT],
            getSwitchActionTemplateFields(idValue, value),
        );

        return copyAction;
    } else if (copyAction?.blockOptions) {
        const idValue = copyAction?.fields?.[INDEX_SELECTED_BLOCKS_ELEMENT]?.value;
        const actionBlockTemplate = {
            ...getSwitchActionTemplateFields(idValue, value),
            label: { lang_tag: THEN_SWITCH, text: SWITCH },
            _tempId: itemId,
        };
        copyAction = generateActionBlock(copyAction, actionBlockTemplate);

        return copyAction;
    }
};
/**
 * Returns either a constant value 'GLOBAL_RESTRICTION' if actionId matches MESHBOT_SECTION_TYPE.GLOBAL_RESTRICTION, otherwise the provided value for typeTrigger.
 *
 * @param {string} typeTrigger - Initial trigger type.
 * @param {string} actionId - Action identifier that may affect result trigger type.
 * @returns {string} - Final trigger type based on actionId.
 */
export const getMeshBotTriggerType = (typeTrigger, actionId) => {
    if (actionId === MESHBOT_SECTION_TYPE.GLOBAL_RESTRICTION) {
        return MESHBOT_SECTION_TYPE.GLOBAL_RESTRICTION;
    }

    return typeTrigger;
};
/**
 * Generates the arguments for onAddTrigger function based on the trigger type.
 * @param {string} triggerType - The type of the trigger based on which the arguments should be created.
 * @param {string|number} actionId - The identifier of the action.
 * @param {string} actionBlockName - The block name of the action.
 * @param {string} blockName - The name of the block where the trigger should be added.
 * @returns {Array} - An array of arguments to be used with the onAddTrigger function.
 */
export const getArgsForAddTrigger = (triggerType, actionId, actionBlockName, blockName) => {
    switch (triggerType) {
        case meshBot.EXCEPTION:
            return [actionId, actionBlockName, blockName];
        default:
            return [blockName];
    }
};
/**
 * Returns the appropriate button title based on the given trigger type.
 *
 * @param {string} [actionId] - The id of the actions.
 * @param {string} triggerType - The type of the trigger.
 * @returns {string} The title to be used on the button for the specified trigger type.
 */
export const getButtonTitleByTriggerTypeAndActionId = (actionId, triggerType) => {
    if (actionId === MESHBOT_SECTION_TYPE.GLOBAL_RESTRICTION) {
        return EZLOGIC_TITLE_ADD_CONDITION;
    }

    if (triggerType === meshBot.EXCEPTION) {
        return EZLOGIC_TITLE_ADD_EXCEPTION;
    }

    return EZLOGIC_TITLE_ADD_TRIGGER;
};
