import {
    convertActionsToRequestStructure,
    convertTriggersToRequestStructure,
} from '../EzloMeshbot/MeshbotCloud/MeshBotCloudCreate/createMeshSceneRequestPayload';
import { FIRST_BLOCK } from '../../../constants/Variables';
import { getEditTriggers } from '../EzloMeshbot/utils';
import {
    ARRAY_WITH_PARAMETERS,
    CAPABILITY_FOR_NOTIFICATION_TEMPLATE,
    DATA_FROM_BLOCK_WITH_NAME_AV,
    DATA_FROM_BLOCK_WITH_NAME_STATIC,
    DATA_FROM_FIRST_BLOCK,
    DATA_FROM_VALUE_FROM_FIRST_BLOCK,
    DATA_FROM_VALUE_FROM_SECOND_BLOCK,
    NOTIFICATION_TYPE,
    NUMBER_OF_OBJECT_WITH_CAPABILITY_DATA,
} from '../../../constants/NotificationTemplates';
import { SYSTEM_EVENTS_CAPABILITIES, SYSTEM } from '../../../constants/Notifications';
import oemManager from '../../../services/oem';

export const getListOfCapabilityNames = (allCapabilities = []) => {
    const arrayOfCapabilities = Array.from(Object.entries(allCapabilities)).filter(
        (capability) =>
            capability[NUMBER_OF_OBJECT_WITH_CAPABILITY_DATA].read_write === CAPABILITY_FOR_NOTIFICATION_TEMPLATE,
    );

    return arrayOfCapabilities.map((capability) => {
        return capability[NUMBER_OF_OBJECT_WITH_CAPABILITY_DATA];
    });
};

// TODO: IMPORTANT TO COVER BY GOOD SET OF UNIT TESTS, REQUIRED WITH NEXT REFACTORING OR ANY CHANGE!
export const createRequestBody = ({
    typeAction,
    name,
    actions,
    meshBotDefinition,
    auto_create,
    id,
    uuid,
    triggers,
    templateCapability,
    capability,
    type,
    optionType,
}) => {
    const updatedTemplateName = name.replace('{abstract.name}', '');

    return {
        action: typeAction,
        title: name,
        description: `Template for ${capability}`,
        capability: findSelectedCapabilityValue(capability, SYSTEM_EVENTS_CAPABILITIES, type) || templateCapability,
        meshbot_definition: meshBotDefinition
            ? {
                  ...JSON.parse(meshBotDefinition),
                  name,
                  meta: {
                      type: NOTIFICATION_TYPE,
                      used_by_abstract: '{abstract.uuid}',
                      dynamic_name: `%abstract.name%${updatedTemplateName}`,
                  },
              }
            : {
                  name,
                  triggers: convertTriggersToRequestStructure(triggers, optionType),
                  actions: convertActionsToRequestStructure(actions),
                  meta: {
                      type: NOTIFICATION_TYPE,
                      used_by_abstract: '{abstract.uuid}',
                      dynamic_name: `%abstract.name%${updatedTemplateName}`,
                  },
              },
        auto_create,
        id,
        uuid,
        type: type,
    };
};

export const currentNotificationTemplate = (notificationTemplates, meshBotName) => {
    return notificationTemplates?.filter(({ title }) => title === meshBotName);
};

export const isNotificationTemplateTriggerValid = (ruleCloudTriggers) => {
    return (
        ruleCloudTriggers?.filter((blocks = []) => {
            return !!(
                (blocks?.blocks?.[ARRAY_WITH_PARAMETERS]?.parameters?.[DATA_FROM_BLOCK_WITH_NAME_AV]?.parameters?.[
                    DATA_FROM_VALUE_FROM_FIRST_BLOCK
                ]?.capability?.length &&
                    blocks?.blocks?.[ARRAY_WITH_PARAMETERS]?.parameters?.[DATA_FROM_BLOCK_WITH_NAME_AV]?.parameters?.[
                        DATA_FROM_VALUE_FROM_FIRST_BLOCK
                    ]?.variable?.length &&
                    blocks?.blocks?.[ARRAY_WITH_PARAMETERS]?.parameters?.[DATA_FROM_BLOCK_WITH_NAME_STATIC]
                        ?.parameters?.[DATA_FROM_VALUE_FROM_FIRST_BLOCK]?.value?.length) ||
                (blocks?.blocks?.[ARRAY_WITH_PARAMETERS]?.parameters?.[DATA_FROM_BLOCK_WITH_NAME_AV]?.parameters?.[
                    DATA_FROM_VALUE_FROM_FIRST_BLOCK
                ]?.parameters?.[DATA_FROM_VALUE_FROM_FIRST_BLOCK]?.capability &&
                    blocks?.blocks?.[ARRAY_WITH_PARAMETERS]?.parameters?.[DATA_FROM_BLOCK_WITH_NAME_AV]?.parameters?.[
                        DATA_FROM_VALUE_FROM_FIRST_BLOCK
                    ]?.parameters?.[DATA_FROM_VALUE_FROM_FIRST_BLOCK]?.variable &&
                    blocks?.blocks?.[ARRAY_WITH_PARAMETERS]?.parameters?.[DATA_FROM_BLOCK_WITH_NAME_AV]?.parameters?.[
                        DATA_FROM_VALUE_FROM_SECOND_BLOCK
                    ]?.parameters?.[DATA_FROM_VALUE_FROM_FIRST_BLOCK]?.value)
            );
        })?.length === ruleCloudTriggers?.length
    );
};

export const isNotificationTemplateActionValid = (ruleCloudActions) => {
    return ruleCloudActions.filter(({ notification }) => {
        return !!notification?.parameters?.[0]?.body?.length;
    }).length;
};

export const isActionsEdit = (actions, initialScene) => {
    return JSON.stringify(actions[0]?.notification?.parameters) !== JSON.stringify(initialScene);
};

export const isNotificationCapabilityEdited = (capability, initialCapability) => {
    return capability !== initialCapability;
};

export const isNotificationTemplateEdited = (
    actions,
    triggers,
    initialScene,
    capability,
    initialCapability,
    parentTriggerOptionType,
    nameBeforeEditing,
    meshBotName,
) => {
    return (
        isActionsEdit(actions, initialScene?.actions?.[0]?.parameters) ||
        getEditTriggers(triggers).length ||
        isNotificationCapabilityEdited(capability, initialCapability) ||
        nameBeforeEditing !== meshBotName ||
        isOperatorForAFewTriggersChanged(triggers, parentTriggerOptionType, initialScene)
    );
};

export const isOperatorForAFewTriggersChanged = (triggers, parentTriggerOptionType, initialScene) => {
    return triggers?.length > 1 && parentTriggerOptionType !== initialScene?.triggers?.name;
};

export const createUuid = () => {
    let dt = new Date().getTime();

    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);

        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
};

export const getCategoriesListByUuid = (parameters, notificationCategoriesList = []) => {
    const res = [];
    const categoriesListFromParams = parameters.filter((parameter) => parameter.category);
    notificationCategoriesList.forEach((categoryFromCategoriesList) => {
        categoriesListFromParams.forEach((categoryFromParams) => {
            if (categoryFromParams.category === categoryFromCategoriesList.uuid) {
                res.push(categoryFromCategoriesList.display_name);
            }
        });
    });

    return res;
};

export const getChannels = (values, channels) => {
    const allChannels = channels.map((item) => item.id);
    const channelsFromParameters = values?.parameters?.[1]?.targets?.[0]?.channels;
    if (channelsFromParameters) {
        return channelsFromParameters;
    } else {
        return allChannels;
    }
};

export const returnUuidArray = (values) => {
    const { targets } = values?.parameters?.[FIRST_BLOCK];

    return targets.map((item) => item.uuid);
};

export const getAllUsersFromAccount = (usersData) => {
    return usersData.map((data) => {
        return data.Username;
    });
};

export const getArrayForDeleteCategory = ({ notificationCategoriesList, uuid }) => {
    return notificationCategoriesList.filter((category) => category.uuid !== uuid);
};

export const getArrayForEditCategory = ({ notificationCategoriesList, uuid, name }) => {
    return notificationCategoriesList.map((currentCategory) => {
        if (currentCategory.uuid === uuid) {
            currentCategory = { ...currentCategory, display_name: name };
        }

        return currentCategory;
    });
};

export const setIsEditCategory = ({ notificationCategoriesList, uuid, value }) => {
    return notificationCategoriesList.map((currentCategory) => {
        if (currentCategory.uuid === uuid && value) {
            currentCategory = { ...currentCategory, isEdit: value, nameBeforeEditing: currentCategory.display_name };
        } else if (currentCategory.uuid === uuid && !value) {
            currentCategory = {
                ...currentCategory,
                isEdit: value,
            };
        }

        return currentCategory;
    });
};

export const setCancelEditingCategory = ({ notificationCategoriesList, uuid, value }) => {
    return notificationCategoriesList.map((currentCategory) => {
        if (currentCategory.uuid === uuid) {
            currentCategory = {
                ...currentCategory,
                isEdit: value,
                display_name: currentCategory.nameBeforeEditing,
            };
        }

        return currentCategory;
    });
};

export const isDuplicate = (notificationCategoriesList, name) => {
    return notificationCategoriesList.filter(
        (category) => category.display_name === name || category.nameBeforeEditing === name,
    ).length;
};

export const getVariables = (trigger, objWithVariables) => {
    if (
        (objWithVariables.length &&
            objWithVariables.capability_name ===
                trigger?.parameters?.[DATA_FROM_FIRST_BLOCK]?.parameters?.[DATA_FROM_BLOCK_WITH_NAME_AV]?.parameters?.[
                    DATA_FROM_VALUE_FROM_FIRST_BLOCK
                ]?.capability) ||
        objWithVariables.capability_name ===
            trigger?.parameters?.[DATA_FROM_FIRST_BLOCK]?.parameters?.[DATA_FROM_BLOCK_WITH_NAME_AV]?.capability
    ) {
        return objWithVariables?.[DATA_FROM_FIRST_BLOCK]?.definition
            ? Object.keys(objWithVariables?.[DATA_FROM_FIRST_BLOCK]?.definition?.variables)
            : {};
    }
};

export const getDisplayNameForCategory = (getCorrectCategoryOnState, element) => {
    return getCorrectCategoryOnState?.isEdit ? getCorrectCategoryOnState?.display_name : element?.display_name;
};

export const getCapabilitiesForNotificationTemplate = (capabilities) => {
    return capabilities.map((capability) => capability.capability_name).sort();
};

export const findSelectedCapabilityValue = (capability, capabilities, type) => {
    if (type === SYSTEM) {
        return capabilities?.find((el) => el.capability_name === capability)?.value;
    }

    return capability;
};

export const findSelectedCapabilityName = (capability, capabilities) => {
    if (capability && capability.startsWith(SYSTEM)) {
        return capabilities?.find((el) => el.value === capability)?.capability_name;
    }

    return capability;
};

export const getNameBlockLabel = (isNotificationTemplatePage) => {
    return isNotificationTemplatePage ? 'ezlogic.label.template_name' : 'ezlogic.label.meshBot_name';
};

export const getFirstStepText = (notificationType) => {
    return notificationType === SYSTEM
        ? 'ezlogic.title.notification_system_template_choose_device_capability'
        : 'ezlogic.title.notification_template_choose_device_capability';
};
export const getSecondStepText = (notificationType) => {
    return notificationType === SYSTEM ? 'ezlogic.title.customize_notification' : 'ezlogic.title.define_your_meshbot';
};

/**
 * Checks if the given current PK account matches the OEM template master PK account.
 *
 * @param {string} currentPkAccount - The current PK account to be checked.
 * @returns {boolean} - True if the current PK account matches the OEM template master PK account, false otherwise.
 */
export const isCurrentPkAccountOemTemplateMaster = (currentPkAccount) => {
    const oemTemplateMasterPkAccount = oemManager.oem.getOemTemplateMasterPkAccount();

    return oemTemplateMasterPkAccount != null && oemTemplateMasterPkAccount === currentPkAccount?.toString();
};

/**
 * Checks if there are unsaved changes in creating a notification template.
 *
 * @param {string} meshBotName - The name of the meshbot.
 * @param {Array} ruleCloudTriggers - Array of cloud triggers for the notification template.
 * @param {Array} ruleCloudActions - Array of cloud actions for the notification template.
 * @param {string} notificationSelectedCapability - The selected capability for the notification.
 * @param {boolean} isValidOrChanged - Indicates if the input is valid or has changed.
 * @returns {boolean} - True if there are unsaved changes, false otherwise.
 */
export const checkCreateUnsavedNotificationTemplateChanges = (
    meshBotName,
    ruleCloudTriggers,
    ruleCloudActions,
    notificationSelectedCapability,
    isValidOrChanged,
) =>
    Boolean(
        meshBotName?.length ||
            ruleCloudTriggers?.length ||
            ruleCloudActions?.length ||
            notificationSelectedCapability?.length ||
            !isValidOrChanged,
    );

/**
 * Checks if there are unsaved changes in the system template.
 *
 * @param {boolean} isEdited - Indicates if the template has been edited.
 * @param {boolean} isValidOrChanged - Indicates if the template is valid or has changed.
 * @param {Array} ruleCloudActions - Array of actions for the rule.
 * @param {string} meshBotName - The name of the meshbot.
 * @returns {boolean} - True if there are unsaved changes, false otherwise.
 */
export const IsSystemTemplateUnsavedChanges = (isEdited, isValidOrChanged, ruleCloudActions, meshBotName) => {
    return isEdited || !isValidOrChanged || (!isEdited && (!ruleCloudActions?.length || !meshBotName?.length));
};

/**
 * Checks if there are unsaved changes in the meshbot template.
 *
 * @param {boolean} isEdited - Indicates if the template has been edited.
 * @param {boolean} isValidOrChanged - Indicates if the template is valid or has changed.
 * @param {Array} ruleCloudActions - Array of actions for the rule.
 * @param {string} meshBotName - The name of the meshbot.
 * @param {Array} ruleCloudTriggers - Array of triggers for the rule.
 * @returns {boolean} - True if there are unsaved changes, false otherwise.
 */
export const IsMeshBotTemplateUnsaveChanges = (
    isEdited,
    isValidOrChanged,
    ruleCloudActions,
    meshBotName,
    ruleCloudTriggers,
) => {
    return (
        isEdited ||
        !isValidOrChanged ||
        (!isEdited && !ruleCloudTriggers?.length) ||
        !ruleCloudActions?.length ||
        !meshBotName?.length
    );
};

/**
 * Checks if there are unsaved changes in the system and meshbot templates based on the template type.
 *
 * @param {boolean} isSystemNotificationTemplate - Indicates if the template is a system notification template.
 * @param {boolean} isEdited - Indicates if the template has been edited.
 * @param {boolean} isValidOrChanged - Indicates if the template is valid or has changed.
 * @param {Array} ruleCloudActions - Array of actions for the rule.
 * @param {string} meshBotName - The name of the meshbot.
 * @param {Array} ruleCloudTriggers - Array of triggers for the rule.
 * @returns {boolean} - True if there are unsaved changes, false otherwise.
 */
export const IsSystemAndMeshbotUnsaveChanges = (
    isSystemNotificationTemplate,
    isEdited,
    isValidOrChanged,
    ruleCloudActions,
    meshBotName,
    ruleCloudTriggers,
) => {
    if (isSystemNotificationTemplate) {
        return IsSystemTemplateUnsavedChanges(isEdited, isValidOrChanged, ruleCloudActions, meshBotName);
    }

    return IsMeshBotTemplateUnsaveChanges(isEdited, isValidOrChanged, ruleCloudActions, meshBotName, ruleCloudTriggers);
};

/**
 * Checks if there are unsaved changes in editing a notification template.
 *
 * @param {string} meshBotName - The name of the meshbot.
 * @param {Array} ruleCloudTriggers - Array of cloud triggers for the notification template.
 * @param {Array} ruleCloudActions - Array of cloud actions for the notification template.
 * @param {boolean} isValidOrChanged - Indicates if the input is valid or has changed.
 * @param {Array} isEditedTriggerArray - Array of edited trigger array.
 * @param {Array} isEditedActionArray - Array of edited action array.
 * @param {boolean} isSystemNotificationTemplate - Indicates if the system notification template.
 *
 * @returns {boolean} - True if there are unsaved changes, false otherwise.
 */
export const checkEditUnsavedNotificationTemplateChanges = (
    meshBotName,
    ruleCloudTriggers,
    ruleCloudActions,
    isValidOrChanged,
    isEditedTriggerArray,
    isEditedActionArray,
    isSystemNotificationTemplate,
) => {
    const isEdited = isEditedTriggerArray?.length || isEditedActionArray?.length;
    if (
        IsSystemAndMeshbotUnsaveChanges(
            isSystemNotificationTemplate,
            isEdited,
            isValidOrChanged,
            ruleCloudActions,
            meshBotName,
            ruleCloudTriggers,
        )
    ) {
        return true;
    }

    return false;
};
