import update from 'immutability-helper';
import { CloudMeshbotActionTypes } from '../constants/ActionTypes/CloudMeshbot';
import at from '../constants/ActionTypes/MeshBot';
import { LOGOUT } from '../constants/ActionTypes';
import * as itemGroups from '../constants/ActionTypes/ItemGroups';
import { ACTIONS_EXECUTION_POLICY_BY_DEFAULT } from '../containers/Ezlo/EzloMeshbot/constants';

const defaultState = {
    local: {
        isLocalMeshbotPage: false,
        isApiProcessStarted: null,
        scriptNotification: 'default',
        singleLuaScript: null,
        errorsScript: '',
        ruleTriggers: [],
        currentBlocks: [],
        listDevicesBlocks: [],
        scenesStatus: [],
        [at.MESHBOT_LOCAL_ACTION_BLOCKS.then.actions]: [],
        [at.MESHBOT_LOCAL_ACTION_BLOCKS.else.actions]: [],
        exceptions: [],
        sceneData: {},
        selectedRule: {
            enabled: true,
            group_id: null,
            is_group: false,
            name: '',
            parent_id: null,
            user_notifications: [],
            house_modes: ['1', '2', '3', '4'],
            then: [],
            when: [],
            else: [],
            exceptions: [],
            exec_policy: ACTIONS_EXECUTION_POLICY_BY_DEFAULT,
            meta: { labels: [] },
        },
        initialRule: {
            enabled: true,
            group_id: null,
            is_group: false,
            name: '',
            parent_id: null,
            user_notifications: [],
            house_modes: ['1', '2', '3', '4'],
            then: [],
            when: [],
            else: [],
            exceptions: [],
            meta: { labels: [] },
        },
        selectedMeshBotLabels: [],
    },
    cloud: {
        name: '',
        nameBeforeEditing: '',
        parentTriggerOptionType: 'and',
        cloudMeshBot: [],
        ruleCloudTriggers: [],
        ruleCloudAction: [],
        currentScene: {},
        isDuplicate: false,
        isLoading: false,
        meta: { labels: [] },
        selectedMeshBotLabels: [],
    },
    expressionList: [],
    abstractsDevice: [],
    capabilities: [],
    listLuaScripts: [],
    loaderScriptList: false,
    isWsRequestStatus: '',
    isWsRequestStatusMessage: '',
    errors: [],
    interfaceJSONItems: [],
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case at.UPDATE_CONTROLLER_CAPABILITY:
            return update(state, {
                local: {
                    selectedRule: { when: { $set: action.data } },
                },
            });

        case at.SET_WS_REQUEST_STATUS.success:
            return update(state, {
                isWsRequestStatus: { $set: 'success' },
                isWsRequestStatusMessage: { $set: action.payload },
            });

        case at.SET_WS_REQUEST_STATUS.rejected:
            return update(state, {
                isWsRequestStatus: { $set: 'rejected' },
                isWsRequestStatusMessage: { $set: action.payload },
            });
        case at.SET_WS_REQUEST_STATUS.clear:
            return update(state, {
                isWsRequestStatus: { $set: '' },
                isWsRequestStatusMessage: { $set: '' },
            });

        case at.ADD_TRIGGER.success:
            return update(state, {
                local: {
                    ruleTriggers: { $set: action.data.trigger },
                    selectedRule: { $set: action.data.selected },
                },
            });

        case at.UPDATE_TRIGGER.success:
            return update(state, {
                local: { ruleTriggers: { $set: action.data } },
            });

        case at.UPDATE_DEVICE_ADVANCED_SELECTED_RULE_WHEN:
            return update(state, {
                local: { selectedRule: { when: { $set: action.data } } },
            });

        case at.UPDATE_DEVICE_GROUP_SELECTED_RULE_WHEN:
            return update(state, {
                local: { selectedRule: { when: { $set: action.data } } },
            });

        case at.ADD_EXCEPTION.success:
            return update(state, {
                local: {
                    selectedRule: { $set: action.data.selected },
                    exceptions: { $set: action.data.exceptions },
                },
            });

        case at.DELETE_EXCEPTION.success:
            return update(state, {
                local: {
                    selectedRule: { $set: action.selectedRule },
                    exceptions: { $set: action.exceptions },
                },
            });

        case at.TOGGLE_EXCEPTION_BLOCK.success:
            return update(state, {
                local: {
                    [at.MESHBOT_LOCAL_ACTION_BLOCKS[action.blockName].actions]: {
                        $set: action.data.groupActions,
                    },
                },
            });

        case at.ADD_EXCEPTION_TRIGGER.success:
            return update(state, {
                local: {
                    selectedRule: { $set: action.selectedRule },
                    exceptions: { $set: action.exceptions },
                },
            });

        case at.DELETE_EXCEPTION_TRIGGER.success:
            return update(state, {
                local: {
                    selectedRule: { $set: action.selectedRule },
                    exceptions: { $set: action.exceptions },
                },
            });

        case at.UPDATE_EXCEPTION_TRIGGER.success:
            return update(state, {
                local: { exceptions: { $set: action.data } },
            });

        case at.UPDATE_SELECT_MESHBOT_EXCEPTION_TRIGGER.success:
            return update(state, {
                local: {
                    selectedRule: { $set: action.data.selected },
                    exceptions: { $set: action.data.exceptions },
                },
            });

        case at.UPDATE_CONTROLLER_EXCEPTION_TRIGGER.success:
            return update(state, {
                local: {
                    selectedRule: { $set: action.data.selected },
                    exceptions: { $set: action.data.exceptions },
                },
            });

        case at.UPDATE_SELECT_DEVICE_EXCEPTION_TRIGGER.success:
            return update(state, {
                local: {
                    selectedRule: { $set: action.data.selected },
                    exceptions: { $set: action.data.exceptions },
                },
            });

        case at.RESET_MESHBOT_EXCEPTION_TRIGGER_STATE_VALUE.success:
            return update(state, {
                local: {
                    exceptions: { $set: action.data.exceptions },
                    selectedRule: { $set: action.data.selectedRule },
                },
            });

        case at.CLEAR_RULE_SELECTED_BLOCK.success:
            return update(state, {
                local: { selectedRule: { $set: action.data } },
            });

        case at.GET_DEVICE_BLOCKS.success:
            return update(state, {
                local: { listDevicesBlocks: { $set: action.data } },
            });

        case at.GET_EXPRESSION_LIST.success:
            return update(state, {
                expressionList: { $set: action.data },
            });

        case at.GET_SCENE_STATUS.success:
            return update(state, {
                local: {
                    scenesStatus: { $set: action.data[0] },
                    groupActions: { $set: action.data[1] },
                },
            });

        case at.UPDATE_SELECTED_RULE_NOT.success:
            return update(state, {
                local: { selectedRule: { $set: action.data } },
            });

        case at.UPDATE_TRIGGER_SECTION_FUNCTION:
            return update(state, {
                local: { selectedRule: { $set: action.newSelectedRule } },
            });

        case at.UPDATE_NAME_GROUP.success:
            return update(state, {
                local: {
                    ruleTriggers: { $set: action.data.trigger },
                    selectedRule: { $set: action.data.selected },
                },
            });
        case at.UPDATE_FIELD_IN_GROUP_EXCEPTION.success:
            return update(state, {
                local: {
                    exceptions: { $set: action.exceptions },
                    selectedRule: { $set: action.selectedRule },
                },
            });

        case at.UPDATE_SELECT_MESHBOT_TRIGGER.success:
            return update(state, {
                local: {
                    ruleTriggers: { $set: action.data.trigger },
                    selectedRule: { $set: action.data.selected },
                },
            });
        case at.UPDATE_SELECT_CLOUD_VARIABLES_TRIGGER:
            return update(state, {
                local: { selectedRule: { when: { $set: action.data } } },
            });

        case at.RESET_MESHBOT_TRIGGER_STATE_VALUE.success:
            return update(state, {
                local: {
                    ruleTriggers: { $set: action.data.ruleTriggers },
                    selectedRule: { $set: action.data.selectedRule },
                },
            });

        case at.UPDATE_SELECT_DATE.success:
            return update(state, {
                local: {
                    ruleTriggers: { $set: action.data.trigger },
                    selectedRule: { $set: action.data.selected },
                },
            });

        case at.SET_EXCEPTION_DATE_NODE.success:
            return update(state, {
                local: {
                    exceptions: { $set: action.exceptions },
                    selectedRule: { $set: action.selectedRule },
                },
            });

        case at.UPDATE_FIELD_BLOCK.success:
            return update(state, {
                local: { ruleTriggers: { $set: action.data } },
            });

        case at.UPDATE_FIELD_SELECT_RULE.success:
            return update(state, {
                local: { selectedRule: { $set: action.data } },
            });

        case at.ADD_BLOCK_WHEN.success:
            return update(state, {
                local: { selectedRule: { $set: action.data } },
            });

        case at.CHANGE_ACTIVE_BLOCK.success:
            return update(state, {
                local: { ruleTriggers: { $set: action.data } },
            });

        case at.UPDATE_DATE_VALUE.success:
            return update(state, {
                local: {
                    ruleTriggers: { $set: action.data.trigger },
                    selectedRule: { $set: action.data.selected },
                },
            });

        case at.SET_EXCEPTION_DATE_VALUE.success:
            return update(state, {
                local: {
                    exceptions: { $set: action.exceptions },
                    selectedRule: { $set: action.selectedRule },
                },
            });

        case at.UPDATE_TRIGGER_BLOCK.success:
            return update(state, {
                local: {
                    ruleTriggers: { $set: action.newRuleTriggers },
                    selectedRule: { $set: action.newSelectedRule },
                },
            });

        case at.DELETE_TRIGGER.success:
            return update(state, {
                local: {
                    selectedRule: { $set: action.data.selected },
                    ruleTriggers: { $set: action.data.ruleTriggers },
                },
            });

        case at.UPDATE_EXPRESSION.success:
            return update(state, {
                local: { ruleTriggers: { $set: action.data } },
            });

        case at.ADD_TRIGGER_ACTION.success:
            return update(state, {
                local: {
                    [at.MESHBOT_LOCAL_ACTION_BLOCKS[action.blockName].actions]: {
                        $push: [action.data.trigger],
                    },
                    selectedRule: { $set: action.data.selected },
                },
            });

        case at.DELETE_TRIGGER_ACTION.success:
            return update(state, {
                local: {
                    [at.MESHBOT_LOCAL_ACTION_BLOCKS[action.blockName].actions]: {
                        $set: action.data.actions,
                    },
                    selectedRule: { $set: action.data.selectedRule },
                    exceptions: { $set: action.data.exceptions },
                },
            });

        case at.UPDATE_ACTION_NODE.success:
            return update(state, {
                local: {
                    [at.MESHBOT_LOCAL_ACTION_BLOCKS[action.blockName].actions]: {
                        $set: action.actionsList,
                    },
                    selectedRule: {
                        [action.blockName]: { $set: action.selectedRule },
                    },
                },
            });

        case at.ADD_LUA_SCRIPT.success:
            if (action.blockName) {
                return update(state, {
                    local: {
                        [at.MESHBOT_LOCAL_ACTION_BLOCKS[action.blockName].actions]: {
                            $set: action.actionsList,
                        },
                        selectedRule: {
                            [action.blockName]: { $set: action.selectedRule },
                        },
                        scriptNotification: { $set: action.notification },
                    },
                });
            } else {
                return update(state, {
                    local: {
                        scriptNotification: { $set: action.notification },
                    },
                });
            }

        case at.GET_LUA_SCRIPT.success:
            if (action.blockName) {
                return update(state, {
                    local: {
                        [at.MESHBOT_LOCAL_ACTION_BLOCKS[action.blockName].actions]: {
                            $set: action.actionsList,
                        },
                        selectedRule: {
                            [action.blockName]: { $set: action.selectedRule },
                        },
                    },
                });
            } else {
                return update(state, {
                    local: {
                        singleLuaScript: { $set: action.payload },
                    },
                });
            }

        case at.GET_LUA_SCRIPTS.success:
            return update(state, {
                listLuaScripts: { $set: action.payload },
                loaderScriptList: { $set: false },
            });

        case at.GET_LUA_SCRIPTS.pending:
            return update(state, {
                loaderScriptList: { $set: true },
            });

        case at.GET_LUA_SCRIPTS.rejected:
            return update(state, {
                loaderScriptList: { $set: false },
            });

        case at.SET_LUA_SCRIPT.success:
            return update(state, {
                local: {
                    scriptNotification: { $set: action.payload },
                },
            });

        case at.RUN_LUA_SCRIPT.success:
            return update(state, {
                local: {
                    scriptNotification: { $set: action.payload },
                },
            });

        case at.REMOVE_LUA_SCRIPT.success:
            return update(state, {
                local: {
                    scriptNotification: { $set: action.payload },
                },
            });

        case at.SET_LUA_SCRIPT.rejected:
        case at.RUN_LUA_SCRIPT.rejected:
        case at.ADD_LUA_SCRIPT.rejected:
        case at.REMOVE_LUA_SCRIPT.rejected:
            return update(state, {
                local: {
                    scriptNotification: { $set: action.status },
                    errorsScript: { $set: action.errorBody },
                },
            });

        case at.CLEAR_SCRIPT_NOTIFICATION:
            return update(state, {
                local: {
                    scriptNotification: { $set: 'default' },
                    errorsScript: { $set: '' },
                },
            });
        case at.CLEAR_EDIT_SCRIPT:
            return update(state, {
                local: {
                    singleLuaScript: { $set: null },
                },
            });

        case at.SET_ACTION_FIELD.success:
            return update(state, {
                local: {
                    [at.MESHBOT_LOCAL_ACTION_BLOCKS[action.blockName].actions]: {
                        $set: action.actionsList,
                    },
                    selectedRule: {
                        [action.blockName]: { $set: action.selectedRule },
                    },
                },
            });

        case at.UPDATE_TRIGGER_ACTION.success:
            return update(state, {
                local: {
                    [at.MESHBOT_LOCAL_ACTION_BLOCKS[action.blockName].actions]: {
                        $set: action.data,
                    },
                    selectedRule: { $set: action.selectedRule },
                },
            });

        case at.CLEAR_ACTION_LIST:
            return update(state, {
                local: {
                    [at.MESHBOT_LOCAL_ACTION_BLOCKS[action.blockName].actions]: {
                        $set: action.actionsList,
                    },
                },
            });

        case at.CLEAR_ACTION_SELECTED:
            return update(state, {
                local: {
                    selectedRule: { [action.blockName]: { $set: action.selectedRule } },
                },
            });

        case at.GET_DEVICE_BLOCKS_ACTION.success:
            return update(state, {
                local: { selectedRule: { $set: action.data } },
            });

        case at.MANAGE_ACTION_DELAY:
            return update(state, {
                local: {
                    selectedRule: {
                        [action.blockName]: { $set: action.newSelectedRule },
                    },
                },
            });

        case at.SET_NAME_MESH_BOT.success:
            return update(state, {
                local: { selectedRule: { $set: action.data } },
            });
        case at.SET_EXEC_POLICY_FOR_ACTIONS:
            return update(state, {
                local: { selectedRule: { exec_policy: { $set: action.value } } },
            });
        case at.SET_EXEC_POLICY_FOR_ACTION:
            return update(state, {
                local: { selectedRule: { [action.blockType]: { $set: action.payload } } },
            });

        case at.ADD_BLOCK_THEN.success:
            return update(state, {
                local: { selectedRule: { $set: action.data } },
            });

        case at.CHANGE_ACTIVE_BLOCK_ACTION.success:
            return update(state, {
                local: {
                    [at.MESHBOT_LOCAL_ACTION_BLOCKS[action.blockName].actions]: {
                        $set: action.data,
                    },
                },
            });

        case at.UPDATE_FIELD_BLOCK_ACTION.success:
            return update(state, {
                local: {
                    [at.MESHBOT_LOCAL_ACTION_BLOCKS[action.blockName].actions]: {
                        $set: action.data,
                    },
                },
            });

        case at.UPDATE_FIELD_SELECT_RULE_FOR_ACTIONS.success:
            return update(state, {
                local: { selectedRule: { $set: action.data } },
            });

        case at.UPDATE_ACTION_DRAG_AND_DROP.success:
            return update(state, {
                local: {
                    selectedRule: { $set: action.data },
                    groupActions: { $set: action.groupActions },
                    groupElseActions: { $set: action.groupElseActions },
                },
            });

        case at.SET_INITIAL_MESHBOT_TRIGGERS:
            return update(state, {
                local: {
                    //TODO remove selectedRule & initialRule
                    ruleTriggers: { $set: action.triggersList },
                    selectedRule: { $set: action.selectedRule },
                    initialRule: { $set: action.selectedRule },
                },
            });

        case at.SET_EXCEPTION_OPTION_TYPE.success:
            return update(state, {
                local: {
                    exceptions: { $set: action.exceptions },
                    selectedRule: { $set: action.selectedRule },
                },
            });

        case at.SET_EXCEPTION_FUNCTION.success:
            return update(state, {
                local: {
                    exceptions: { $set: action.exceptions },
                    selectedRule: { $set: action.selectedRule },
                },
            });
        case at.SET_EXCEPTION_FUNCTION_IN_TRIGGER.success:
            return update(state, {
                local: {
                    exceptions: { $set: action.exceptions },
                    selectedRule: { $set: action.selectedRule },
                },
            });

        case at.SET_INITIAL_MESHBOT_EXCEPTIONS:
            return update(state, {
                local: {
                    exceptions: { $set: action.exceptions },
                    selectedRule: {
                        exceptions: { $set: action.selectedRuleExceptions },
                    },
                    initialRule: {
                        exceptions: { $set: action.selectedRuleExceptions },
                    },
                },
            });

        case at.SET_INITIAL_MESHBOT_ACTIONS:
            return update(state, {
                local: {
                    // TODO: fix else section
                    //TODO remove selectedRule & initialRule
                    groupActions: { $set: action.groupActions },
                    groupElseActions: { $set: action.groupElseActions },
                    selectedRule: { $set: action.selectedRule },
                    initialRule: { $set: action.selectedRule },
                },
            });

        case at.GET_DEVICE_BLOCKS_EDIT.success:
            return update(state, {
                local: {
                    listDevicesBlocks: { $set: action.blocks },
                    ruleTriggers: { $set: action.ruleTriggers },
                },
            });
        case at.UPDATE_INTERFACE_JSON_ITEMS:
            return update(state, {
                interfaceJSONItems: { $set: action.interfaceJSONItems },
            });
        case at.CLEAR_MESH_BOT:
            return update(state, {
                local: {
                    // TODO: check
                    ruleTriggers: { $set: [] },
                    exceptions: { $set: [] },
                    [at.MESHBOT_LOCAL_ACTION_BLOCKS.then.actions]: { $set: [] },
                    [at.MESHBOT_LOCAL_ACTION_BLOCKS.else.actions]: { $set: [] },
                    currentBlocks: { $set: [] },
                    listDevicesBlocks: { $set: {} },
                    selectedRule: {
                        $set: {
                            ...defaultState.local.selectedRule,
                            name: action.skipFields.name
                                ? state.local.selectedRule.name
                                : defaultState.local.selectedRule.name,
                            meta: { labels: [] },
                        },
                    },
                    initialRule: {
                        $set: { ...defaultState.local.initialRule },
                    },
                    selectedMeshBotLabels: { $set: [] },
                },
                cloud: {
                    ruleCloudTriggers: { $set: [] },
                    ruleCloudAction: { $set: [] },
                    meta: { labels: { $set: [] } },
                    selectedMeshBotLabels: { $set: [] },
                    name: { $set: '' },
                },
            });

        case CloudMeshbotActionTypes.CLEAR_TRIGGER:
            return update(state, {
                cloud: {
                    ruleCloudTriggers: { $set: action.ruleCloudTriggers },
                    ruleCloudAction: [],
                },
            });

        case at.GET_CLOUD_MESHBOT_LIST.success:
            return update(state, {
                cloud: { cloudMeshBot: { $set: action.data } },
            });

        case at.SET_CLOUD_MESHBOT_LIST:
            return update(state, {
                cloud: { cloudMeshBot: { $set: action.updatedCloudMeshBotsList } },
            });

        case at.GET_ABSTRACT_LIST.success:
            return update(state, {
                abstractsDevice: { $set: action.data },
            });

        case at.GET_ABSTRACT_LIST.rejected:
            return update(state, {
                errors: { $push: [action.data] },
            });

        case at.GET_CAPABILITIES_LIST.success:
            return update(state, {
                capabilities: { $set: action.data },
            });

        case at.GET_CAPABILITIES_LIST.rejected:
            return update(state, {
                errors: { $push: [action.data] },
            });

        case at.SET_LOCAL_MESHBOT_PAGE:
            return update(state, {
                local: {
                    isLocalMeshbotPage: { $set: action.value },
                },
            });

        case at.SET_ID_OF_EDITED_MESHBOT:
            return update(state, {
                local: {
                    idOfEditedMeshBot: { $set: action.id },
                },
            });

        case at.SET_VALIDATION_WARNINGS:
            return update(state, {
                local: {
                    validationWarnings: { $set: action.warnings },
                },
            });

        case at.SET_VALIDATION_ERRORS:
            return update(state, {
                local: {
                    validationErrors: { $set: action.errors },
                },
            });
        case at.IS_START_API_PROCESS:
            return update(state, {
                local: {
                    isApiProcessStarted: { $set: action.value },
                },
            });

        // TODO move to separate reducer
        case CloudMeshbotActionTypes.ADD_CLOUD_TRIGGER:
            return update(state, {
                cloud: {
                    ruleCloudTriggers: { $set: action.ruleCloudTriggers },
                },
            });

        case CloudMeshbotActionTypes.DELETE_CLOUD_TRIGGER:
            return update(state, {
                cloud: {
                    ruleCloudTriggers: { $set: action.ruleCloudTriggers },
                },
            });

        case CloudMeshbotActionTypes.UPDATE_CLOUD_TRIGGER:
            return update(state, {
                cloud: { ruleCloudTriggers: { $set: action.data } },
            });

        case CloudMeshbotActionTypes.UPDATE_CLOUD_TRIGGER_NUCAL:
            return update(state, {
                cloud: { ruleCloudTriggers: { $set: action.data } },
            });

        case CloudMeshbotActionTypes.UPDATE_CLOUD_NUCAL_SUBSCRIPTION_IN_KVS:
            return update(state, {
                cloud: { ruleCloudTriggers: { $set: action.data } },
            });

        case CloudMeshbotActionTypes.UPDATE_CLOUD_VARIABLE_TRIGGER:
            return update(state, {
                cloud: { ruleCloudTriggers: { $set: action.data } },
            });

        case CloudMeshbotActionTypes.UPDATE_CLOUD_VARIABLE_TRIGGER_BLOCKS:
            return update(state, {
                cloud: { ruleCloudTriggers: { $set: action.data } },
            });

        case CloudMeshbotActionTypes.UPDATE_SELECTED_PARENT_OPERATOR:
            return update(state, {
                cloud: { parentTriggerOptionType: { $set: action.data } },
            });

        case CloudMeshbotActionTypes.UPDATE_CLOUD_TRIGGER_BLOCK:
            return update(state, {
                cloud: {
                    ruleCloudTriggers: { $set: action.ruleCloudTriggers },
                },
            });

        case CloudMeshbotActionTypes.UPDATE_CLOUD_REQUIRED_TRIGGER:
            return update(state, {
                cloud: {
                    ruleCloudTriggers: { $set: action.data },
                },
            });

        case CloudMeshbotActionTypes.UPDATE_DATE_TRIGGER_CLOUD:
            return update(state, {
                cloud: {
                    ruleCloudTriggers: { $set: action.ruleCloudTriggers },
                },
            });

        case CloudMeshbotActionTypes.ADD_CLOUD_ACTIONS:
            return update(state, {
                cloud: {
                    ruleCloudAction: { $set: action.ruleCloudAction },
                },
            });

        case CloudMeshbotActionTypes.DELETE_CLOUD_ACTIONS:
            return update(state, {
                cloud: {
                    ruleCloudAction: { $set: action.ruleCloudAction },
                },
            });

        case CloudMeshbotActionTypes.UPDATE_CLOUD_ACTIONS:
            return update(state, {
                cloud: {
                    ruleCloudAction: { $set: action.data },
                },
            });

        case CloudMeshbotActionTypes.UPDATE_CLOUD_REQUIRED_ACTION:
            return update(state, {
                cloud: {
                    ruleCloudAction: { $set: action.data },
                },
            });

        case CloudMeshbotActionTypes.UPDATE_CLOUD_ACTION_DRAG_AND_DROP:
            return update(state, {
                cloud: {
                    ruleCloudAction: { $set: action.data },
                },
            });

        case CloudMeshbotActionTypes.UPDATE_CLOUD_META:
            return update(state, {
                cloud: {
                    meta: { $set: action.data },
                },
            });

        case CloudMeshbotActionTypes.SET_CLOUD_MESHBOT_NAME:
            return update(state, {
                cloud: {
                    name: { $set: action.name },
                },
            });

        case CloudMeshbotActionTypes.SET_CLOUD_MESHBOT_NAME_BEFORE_EDITING:
            return update(state, {
                cloud: {
                    nameBeforeEditing: { $set: action.name },
                },
            });

        case CloudMeshbotActionTypes.CLEAR_CLOUD_CURRENT_SCENE:
            return update(state, {
                cloud: {
                    currentScene: { $set: {} },
                    ruleCloudTriggers: { $set: [] },
                    ruleCloudAction: { $set: [] },
                    name: { $set: '' },
                    meta: { labels: { $set: [] } },
                    selectedMeshBotLabels: { $set: [] },
                },
            });

        case CloudMeshbotActionTypes.UPDATE_CURRENT_MESH_SCENE:
            return update(state, {
                cloud: {
                    currentScene: { $set: action.currentScene },
                },
            });

        case CloudMeshbotActionTypes.DUPLICATE_CURRENT_MESHBOT_SCENE:
            return update(state, {
                cloud: {
                    isDuplicate: { $set: action.data },
                },
            });

        case CloudMeshbotActionTypes.DUPLICATE_CURRENT_MESHBOT_SCENE_NAME:
            return update(state, {
                cloud: {
                    name: { $set: action.data },
                },
            });

        case CloudMeshbotActionTypes.SET_CLOUD_TRIGGERS_FOR_EDIT:
            return action.data.parentOperator
                ? update(state, {
                      cloud: {
                          ruleCloudTriggers: { $set: action.data.ruleCloudTriggers },
                          parentTriggerOptionType: { $set: action.data.parentOperator },
                          currentScene: {
                              triggers: {
                                  parameters: { $set: action.data.currentSceneTriggers },
                              },
                          },
                      },
                  })
                : update(state, {
                      cloud: {
                          ruleCloudTriggers: { $set: action.data.ruleCloudTriggers },
                      },
                  });

        case CloudMeshbotActionTypes.SET_CLOUD_ACTIONS_FOR_EDIT:
            return update(state, {
                cloud: {
                    ruleCloudAction: { $set: action.ruleCloudAction },
                },
            });
        case CloudMeshbotActionTypes.CLOUD_SCENE_SET:
            return update(state, {
                cloud: {
                    isLoading: { $set: true },
                },
            });
        case CloudMeshbotActionTypes.CLOUD_SCENE_SUCCESSFULLY_FINISHED:
            return update(state, {
                cloud: {
                    isLoading: { $set: false },
                },
            });
        case CloudMeshbotActionTypes.CLOUD_SCENE_SET_CAUGHT:
            return update(state, {
                cloud: {
                    isLoading: { $set: false },
                },
            });
        case at.SET_LOCAL_MESH_BOT_LABELS:
            return update(state, {
                local: {
                    selectedRule: { meta: { labels: { $set: action.data } } },
                },
            });
        case at.SET_SELECTED_LOCAL_MESH_BOT_LABELS:
            return update(state, {
                local: {
                    selectedMeshBotLabels: { $set: action.data },
                },
            });
        case at.SET_SELECTED_CLOUD_MESH_BOT_LABELS:
            return update(state, {
                cloud: {
                    selectedMeshBotLabels: { $set: action.data },
                },
            });
        case at.SET_CLOUD_MESH_BOT_LABELS:
            return update(state, {
                cloud: {
                    meta: { labels: { $set: action.data } },
                },
            });
        case at.SET_INITIAL_LOCAL_MESH_BOT_LABELS:
            return update(state, {
                local: {
                    selectedRule: { meta: { labels: { $set: action.data } } },
                    initialRule: { meta: { labels: { $set: action.data } } },
                },
            });
        case at.SET_INITIAL_SELECTED_LOCAL_MESH_BOT_LABELS:
            return update(state, {
                local: {
                    selectedMeshBotLabels: { $set: action.data },
                },
            });

        case at.SET_INITIAL_SELECTED_CLOUD_MESH_BOT_LABELS:
            return update(state, {
                cloud: {
                    selectedMeshBotLabels: { $set: action.data },
                },
            });

        case itemGroups.SET_ITEM_GROUP_ID.success:
            return update(state, {
                local: { selectedRule: { when: { $set: action.whenBlocks } } },
            });

        case at.UPDATE_ADDED_SCENE_BROADCAST_DATA:
            return update(state, {
                local: { sceneData: { $set: action.payload } },
            });
        case LOGOUT:
            return defaultState;
        default:
            return state;
    }
};
