import { isEmpty } from 'lodash';
import { KVS_KEY, LOCATION } from '../containers/Ezlo/EzloCustomization/constants';
// import { getCloudEnv, CLOUD_ENVS, getOem, OEM_IDS } from './envs';
import sha1 from 'sha1';
import { HASH_STRING } from '../services/auth';
import oemManager from '../services/oem';
import { MENU_ITEMS_IDS } from '../containers/Navigation/constants';

export const STATIC_SERVER_URL = '//static.ezlo.com';
export const IMG_URL = STATIC_SERVER_URL + '/api/image/';
export const THUMBNAIL_URL = STATIC_SERVER_URL + '/api/thumbnail/';
export const UPLOAD_URL = STATIC_SERVER_URL + '/api/upload/';
export const LOCALIZATION_URL = STATIC_SERVER_URL + '/api/localization/';
export const SUPPORT_URL = '//help.mios.com/';
// export const STORE_URL = '//www.ezlo.com/shop/';
export const TERM_OF_USE_URL = '//www.ezlo.com/terms-and-conditions/';
export const PROXY_APP = 'https://cloud-stg.ezlo.com/cors_proxy?to_url=';
export const EZLOGIC_PRODUCTION_URL = 'https://ezlogic.mios.com';
// export const KVS_QA_URL = 'https://mms1.qa.mios.com/account/kvs/kvs?';
// export const KVS_STG_URL = 'https://mms1.stg.mios.com/account/kvs/kvs?';
// export const KVS_PRODUCTION_URL = 'https://vera-us-oem-autha11.mios.com/account/kvs/kvs?';
export const EZLOGIC2_URL = 'ezlogic2.mios.com';
export const EZLOGIC3_URL = 'ezlogic3.mios.com';
export const EZLOGIC4_URL = 'ezlogic4.mios.com';
export const EZLOGIC5_URL = 'ezlogic5.mios.com';
export const EZLOGIC6_URL = 'ezlogic6.mios.com';
export const EZLOGIC7_URL = 'ezlogic7.mios.com';
export const EZLOGIC8_URL = 'ezlogic8.mios.com';
export const EZLOGIC9_URL = 'ezlogic9.mios.com';
export const EZLOGIC10_URL = 'ezlogic10.mios.com';
export const EZLOGIC_MIOS_COM = 'ezlogic.mios.com';
export const EZLOGIC_QA_MIOS_COM = 'ezlogic-qa.mios.com';
export const QA_EZLOGIC_MIOS_COM = 'qa-ezlogic.mios.com';
export const EZLOGIC_STG_MIOS_COM = 'ezlogic-stg.mios.com';
export const STG_EZLOGIC_MIOS_COM = 'stg-ezlogic.mios.com';
export const EZLOGIC_HORUS_STG_MIOS_COM = 'ezlogic-horus-stg.mios.com';
export const WWW_HORUSEXPERT_COM = 'www.horusexpert.com';
export const EZLOGIC_HORUS_MIOS_COM = 'ezlogic-horus.mios.com';
export const HORUSEXPERT_COM = 'horusexpert.com';
export const PROTECT_MIOS_COM = 'protect.mios.com';
export const PROTECT_STG_MIOS_COM = 'protect-stg.mios.com';
export const PROTECT_QA_MIOS_COM = 'protect-qa.mios.com';
export const RESIDENT_MIOS_COM = 'resident.mios.com';
export const RESIDENT_STG_MIOS_COM = 'resident-stg.mios.com';
export const RESIDENT_QA_MIOS_COM = 'resident-qa.mios.com';
export const PK_Language = 1;

/**
 * List of internal Ezlogic domain URLs.
 *
 * @constant
 * @type {Array<string>}
 * @description Contains a list of URLs representing internal Ezlogic domains.
 * @example
 * const internalDomainUrls = INTERNAL_EZLOGIC_DOMAIN_URLS;
 * // Result: ['ezlogic2.mios.com', 'ezlogic3.mios.com', ...]
 */
export const INTERNAL_EZLOGIC_DOMAIN_URLS = [
    EZLOGIC2_URL,
    EZLOGIC3_URL,
    EZLOGIC4_URL,
    EZLOGIC5_URL,
    EZLOGIC6_URL,
    EZLOGIC7_URL,
    EZLOGIC8_URL,
    EZLOGIC9_URL,
    EZLOGIC10_URL,
];
// const EZLO_REQUEST_API = 'https://api-cloud.ezlo.com/v1/request';
// const EZLO_QA_REQUEST_API = 'https://api-cloud-qa.ezlo.com/v1/request';
// const EZLO_STG_REQUEST_API = 'https://api-cloud-stg.ezlo.com/v1/request';
// const EZLO_UPLOAD_API = 'https://upload-cloud.ezlo.com';
// const EZLO_STG_UPLOAD_API = 'https://upload-cloud-stg.ezlo.com';
// const EZLO_QA_UPLOAD_API = 'https://upload-cloud-qa.ezlo.com';
// const EZLO_DOWNLOAD_API = 'https://download-cloud.ezlo.com';
// const EZLO_QA_DOWNLOAD_API = 'https://download-cloud-qa.ezlo.com';
// const EZLO_STG_DOWNLOAD_API = 'https://download-cloud-stg.ezlo.com';
// const EZLO_TOKEN_EXCHANGE_API = 'https://cloud.ezlo.com/mca-router/token/exchange/legacy-to-cloud';
// const EZLO_QA_TOKEN_EXCHANGE_API = 'https://cloud-qa.ezlo.com/mca-router/token/exchange/legacy-to-cloud';
// const EZLO_STG_TOKEN_EXCHANGE_API = 'https://cloud-stg.ezlo.com/mca-router/token/exchange/legacy-to-cloud';
// const EZLO_QA_AUTH_API = 'https://mms1.qa.mios.com/autha/auth/username/';
// const EZLO_STG_AUTH_API = 'https://mms-stg11.mios.com/autha/auth/username/';
// const EZLO_AUTH_API = 'https://vera-us-oem-autha11.mios.com/autha/auth/username/';
// const EZLO_QA_GET_USERS_API = 'https://mms1.qa.mios.com/account/user/users';
// const EZLO_STG_GET_USERS_API = 'https://mms-stg14.mios.com/account/user/users';
// const EZLO_GET_USERS_API = 'https://vera-us-oem-account11.mios.com/account/user/users';
// const EZLO_QA_ACCOUNT_API = 'https://mms1.qa.mios.com/account/account/account/';
// const EZLO_STG_ACCOUNT_API = 'https://mms-stg14.mios.com/account/account/account/';
// const EZLO_ACCOUNT_API = 'https://vera-us-oem-account11.mios.com/account/account/account/';
// const EZLO_QA_CONTROLLER_API = 'https://mms1.qa.mios.com/account/installation/installation/0/device/';
// const EZLO_STG_CONTROLLER_API = 'https://mms-stg14.mios.com/account/installation/installation/0/device/';
// const EZLO_CONTROLLER_API = 'https://vera-us-oem-device12.mios.com/account/installation/installation/0/device/';
// const EZLO_QA_DEVICE_API = 'https://mms1.qa.mios.com/device/device/device/';
// const EZLO_STG_DEVICE_API = 'https://mms-stg14.mios.com/device/device/device/';
// const EZLO_DEVICE_API = 'https://vera-us-oem-device12.mios.com/device/device/device/';
// const EZLO_QA_USER_INFO_API = 'https://mms1.qa.mios.com/account/user/user/';
// const EZLO_STG_USER_INFO_API = 'https://mms-stg14.mios.com/account/user/user/';
// const EZLO_USER_INFO_API = 'https://vera-us-oem-device11.mios.com/account/user/user/';
// const EZLO_QA_DASHBOARD_API = 'https://ezlodashboard-qa.mios.com/';
// const EZLO_STG_DASHBOARD_API = 'https://ezlodashboard-stg.mios.com/';
// const EZLO_DASHBOARD_API = 'https://ezlodashboard.mios.com/';
// const EZLO_REGISTER_URL = 'https://vera-us-oem-autha11.mios.com/autha/auth/username';
// const QA_BASE_URL_PART = 'https://mms1.qa.mios.com';
// const BASE_ACCOUNT11_URL_PART = 'https://vera-us-oem-account11.mios.com';
// const BASE_AUTHA11_URL_PART = 'https://vera-us-oem-autha11.mios.com';
// const STG_BASE_URL_PART = 'https://mms-stg11.mios.com';

// OK, remove
// const EZLO_SECURITY_OR_PROPERTY_BASE_ACCOUNT11_URL = () => {
//     const oem = getOem();
//
//     return `https://ezlo-${oem.oemValue}-us-oem-account11.mios.com`;
// };

// OK, remove
// const EZLO_SECURITY_OR_PROPERTY_BASE_AUTHA1_URL = () => {
//     const oem = getOem();
//
//     return `https://ezlo-${oem.oemValue}-us-oem-autha1.mios.com`;
// };

// OK
// const EZLO_SECURITY_OR_PROPERTY_AUTH_API = (oem) => {
//     return `https://ezlo-${oem}-us-oem-autha1.mios.com/autha/auth/username/`;
// };

// OK
// const EZLO_SECURITY_OR_PROPERTY_GET_USERS_API = (oem) => {
//     return `https://ezlo-${oem}-us-oem-account11.mios.com/account/user/users`;
// };

// OK
// const EZLO_SECURITY_OR_PROPERTY_ACCOUNT_API = (oem) => {
//     return `https://ezlo-${oem}-us-oem-account11.mios.com/account/account/account/`;
// };

// OK
// const EZLO_SECURITY_OR_PROPERTY_DEVICE_API = (oem) => {
//     return `https://ezlo-${oem}-us-oem-device11.mios.com/device/device/device/`;
// };

// OK
// const EZLO_SECURITY_OR_PROPERTY_USER_INFO_API = (oem) => {
//     return `https://ezlo-${oem}-us-oem-device11.mios.com/account/user/user/`;
// };

// OK
// const EZLO_SECURITY_OR_PROPERTY_REGISTER_URL = (oem) => {
//     return `https://ezlo-${oem}-us-oem-autha1.mios.com/autha/auth/username`;
// };

// OK
// export const EZLO_FORGOT_PASSWORD_URL = (email) =>
//     `https://vera-us-oem-autha11.mios.com/autha/auth/email/${email}/password_reset`;

// OK
// const EZLO_SECURITY_OR_PROPERTY_EZLO_FORGOT_PASSWORD_URL = (oem, email) => {
//     return `https://ezlo-${oem}-us-oem-autha1.mios.com/autha/auth/email/${email}/password_reset`;
// };

// TODO: move to OEM config, currently used only for Security OEM
export const EZLO_GET_TICKET_PRIORITIES = 'https://api.servicedeskv2.dmdemo.comodo.com/api/v1/ticketpriorities';
export const EZLO_GET_TICKET_HELPTOPICS = 'https://api.servicedeskv2.dmdemo.comodo.com/api/v1/helptopics';
export const EZLO_CREATE_SUPPORT_TICKET = 'https://api.servicedeskv2.dmdemo.comodo.com/api/v1/tickets';
export const EZLO_GET_USERS_FROM_KVS = 'https://api.servicedeskv2.dmdemo.comodo.com/api/v1/users';
export const KVS_GET = 'kvs_get';
export const KVS_SET = 'kvs_set';
export const LEGACY_PARTNER_USER_CREATE = 'legacy_partner_user_create';
export const PARTNER_DOMAIN_TO_PARTNER_UUID = 'partner_domain_to_partner_uuid';
export const MIOS_SMART_HOME_TITLE = 'MiOS Smart Home';

export const returnKVSObject = (unique_id) => {
    return {
        list: [
            {
                key: KVS_KEY,
                entity_uuid: unique_id,
                type: LOCATION,
            },
        ],
    };
};

export const returnKVSObjectForSubscription = (unique_id) => {
    return {
        list: [
            {
                key: unique_id,
                type: LOCATION,
            },
        ],
    };
};

/**
 * Get Partner User Signup Params to Make API call of user creation.
 *  @param {string} name - name of user.
 *  @param {string} email - email of user.
 *  @param {string} pass - password of user.
 *  @param {string} pUuid - partner uuid of partner.
 *  @param {string} envUuid - environment uuid.
 * @returns {Object} get params for user creation api call.
 * */
export const getSignUpPartnerUserParams = (name, email, pass, partnerUuid, envUuid) => {
    return {
        parent: {
            uuid: partnerUuid,
        },
        user: {
            username: name,
            password: pass,
            email: email,
        },
        environment: {
            uuid: envUuid,
        },
    };
};

/**
 * Get Partner top level domain params for call Api partner_domain_to_partner_uuid.
 * @param {string} hostname - top level domain.
 * @returns {Object} returns params for call Api partner_domain_to_partner_uuid.
 * */
export const getParamsForPartnerDomain = (hostname) => {
    return {
        domain: hostname,
    };
};

/**
 * Get Kvs Params for User to Make API call of kvs get.
 * @param {string} kvsUserKey - kvs user key.
 * @param {string} userType - user type.
 * @returns {Object} returns params for kvs get api call.
 * */

export const returnKVSParams = (kvsUserKey, userType) => {
    return {
        list: [
            {
                key: kvsUserKey,
                type: userType,
            },
        ],
    };
};

export const API_CLOUD_EZLO_REQUEST_URL = () => {
    return oemManager.oem.getCloudApiUrl();
};

export const GET_PARTNER_UUID_KVS = (PK_Account) => {
    const PK_Oem = oemManager.oem.getPkOem();
    const params = { Key: 'partner-uuid', PK_Account, PK_Oem };

    return oemManager.oem.getMMSAccountKvs(params);
};

export const GET_EULA_URL = () => {
    const PK_Oem = oemManager.oem.getPkOem();
    const params = { PK_Language, PK_Oem };

    return oemManager.oem.getMMSAccountEulaUrl(params);
};

export const SET_EULA_URL = () => {
    const params = { PK_Language };

    return oemManager.oem.getMMSAccountUserEulaUrl(params);
};

export const GET_NOTICES_URL = () => {
    return oemManager.oem.getMMSAccountNoticesUrl();
};

export const SET_NOTICES_URL = () => {
    const params = { PK_Language };

    return oemManager.oem.getMMSAccountUserNoticesUrl(params);
};

export const SET_CONSENT_URL = () => {
    const params = { PK_Language };

    return oemManager.oem.getMMSAccountUserConsentUrl(params);
};

export const SET_COUNTRY_URL = () => {
    return oemManager.oem.getMMSAccountUserCountryUrl();
};

export const GET_CONSENT_URL = () => {
    return oemManager.oem.getMMSAccountConsentUrl();
};

export const API_CLOUD_EZLO_UPLOAD_FILE = () => {
    return oemManager.oem.getCloudUploadUrl();
};

export const API_CLOUD_EZLO_MEDIA_URL = () => {
    return oemManager.oem.getCloudMediaUrl();
};

// TODO: OK, replace by API_CLOUD_EZLO_UPLOAD_FILE
export const API_CLOUD_EZLO_UPDATE_PLUGIN_URL = () => {
    return oemManager.oem.getCloudUploadUrl();
};

/**
 * Represents the raw download URL for a plugin from the EZLO Cloud API.
 * This URL can be used to download the plugin package associated with the given parameters.
 * @see {[Download request doc](https://confluence.mios.com/pages/viewpage.action?spaceKey=EPD&title=Storage+v3+-+Download)}
 */
export const API_CLOUD_EZLO_DOWNLOAD_PLUGIN_URL = (params) => {
    return oemManager.oem.getCloudDownloadUrl(params);
};

export const API_CLOUD_EZLO_DOWNLOAD_IMG_URL = (params) => {
    return oemManager.oem.getCloudDownloadUrl(params);
};

// OK
export const CLOUD_EZLO_TOKEN_EXCHANGE_URL = (PK_User) => {
    // TODO: check and optimize, move to utils!
    // TODO: improve check here, PK_User - it's a number
    const params = PK_User ? { pk_user: PK_User } : null;

    return oemManager.oem.getCloudTokenExchangeUrl(params);
};

export const SYSTEM_HISTORY_URL = (params) => {
    return oemManager.oem.getSystemHistory(params);
};

export const AUTH_URL = (userName, hash, hashCS) => {
    const PK_Oem = oemManager.oem.getPkOem();
    const params = { SHA1Password: hash, SHA1PasswordCS: hashCS, PK_Oem };

    return oemManager.oem.getMMSLoginUrl(userName, params);
};

export const GET_ALL_USER = () => {
    return oemManager.oem.getMMSUsersUrl();
};

export const REGISTER_URL = () => {
    return oemManager.oem.getMMSCreateUserUrl();
};

export const FORGOT_PASSWORD_URL = (email) => {
    return oemManager.oem.getMMSPasswordResetUrl(email);
};

export const DEVICES_URL = (acc) => {
    const PK_Oem = oemManager.oem.getPkOem();
    const params = { PK_Oem };

    // TODO: check
    return oemManager.oem.getMMSAccountDevicesUrl(acc, params);
};

export const DELETE_CONTROLLER_URL = (serial) => {
    return oemManager.oem.getMMSDeleteControllerUrl(serial);
};

export const DEVICE_INFO_URL = (device) => {
    const PK_Oem = oemManager.oem.getPkOem();
    const params = { PK_Oem };

    return oemManager.oem.getMMSDeviceInfoUrl(device, params);
};

// TODO: OK, CHECK PARAMS, the same as USER_DETAILS_ - let's replace by user info
export const USER_INFO_URL = (user) => {
    const PK_Oem = oemManager.oem.getPkOem();
    const params = { PK_Oem };

    return oemManager.oem.getMMSGetAccountUserUrl(user, params);
};

export const DASHBOARD_URL = (token) => {
    const params = { token };

    return oemManager.oem.getDashboardLoginUserWithTokenUrl(params);
};

export const GET_EZLO = (ezloSerial, params) =>
    `/ezlo${isEmpty(ezloSerial) ? '' : '/' + ezloSerial}${
        isEmpty(params)
            ? ''
            : Object.keys(params).reduce(
                  (result, param, index) =>
                      (result += `${param}=${params[param]}${Object.keys(params).length - 1 !== index ? '&' : ''}`),
                  '?',
              )
    }`;
export const GET_EZLO_BY_USER_EMAIL = (ezloSerial, userEmail) => `/ezlo/${ezloSerial}/user/${userEmail}`;
export const GET_EZLO_HISTORY = (ezloSerial) => `/ezlo/${ezloSerial}/history`;
export const GET_EZLO_MAINTENANCE = (ezloSerial) => `/ezlo/${ezloSerial}/maintenance`;
export const GET_EZLO_SHARE = (ezloSerial) => `/ezlo/${ezloSerial}/share`;
export const GET_DEVICE_INTO_EZLO = (ezloSerial, deviceId, searchParams = '') =>
    `/ezlo/${ezloSerial}/device/${deviceId}${searchParams}`;
export const GET_DEVICES_INTO_EZLO = (ezloSerial) => `/ezlo/${ezloSerial}/devices`;
export const GET_PROFILE = (ezloSerial) => `/ezlo/${ezloSerial}/profile`;
export const GET_ABOUT = (ezloSerial) => `/ezlo/${ezloSerial}/about`;
export const GET_NEW_RULE_INTO_EZLO = (ezloSerial) => `/ezlo/${ezloSerial}/rule/new`;
export const GET_RULE_INTO_EZLO = (ezloSerial, ruleId) => `/ezlo/${ezloSerial}/rule/${ruleId}`;
export const GET_RULES_INTO_EZLO = (ezloSerial) => `/ezlo/${ezloSerial}/rules`;
export const GET_EZLO_PAGE = (ezloSerial, page) => `/ezlo/${ezloSerial}/${page}`;
export const GET_EZLO_ROOM = (ezloSerial, roomId) => `ezlo/${ezloSerial}/room/${roomId}`;
export const GET_EZLO_RULES_FROM_ROOM = (ezloSerial, roomId) => `/ezlo/${ezloSerial}/room/${roomId}/rule`;
export const GET_EZLO_RULE_FROM_ROOM = (ezloSerial, roomId, ruleId) =>
    `/ezlo/${ezloSerial}/room/${roomId}/rule/${ruleId}`;
export const GET_OFFLINE_EZLO = (ezloSerial) => `/offline/${ezloSerial}`;
export const GET_AUTOMATION_EZLO = (ezloSerial, automationId) =>
    `/ezlo/${ezloSerial}/automation${isEmpty(automationId) ? '' : '/' + automationId}`;
export const GET_AUTOMATION_RULE_EZLO = (ezloSerial, automationId) =>
    `/ezlo/${ezloSerial}/automation/${automationId}/rule`;
export const GET_AUTOMATION_RULEID_EZLO = (ezloSerial, automationId, ruleId) =>
    `/ezlo/${ezloSerial}/automation/${automationId}/rule/${ruleId}`;
export const GET_VIRTUAL_DEVICE_INTO_EZLO = (ezloSerial, virtualDeviceId, searchParams = '') =>
    `/ezlo/${ezloSerial}/virtual-device/${virtualDeviceId}${searchParams}`;
export const GET_VIRTUAL_CONTAINER = () => '/ezlo/virtual-container';
export const GET_VIRTUAL_DEVICE_PAGE_INTO_EZLO = (page) => `/ezlo/virtual-container/${page}`;
export const GET_EZLO_PARJECTS_PAGE = (page = '') => `/ezlo/parjects/${page}`;
export const EZLO_PARJECTS = `/ezlo/parjects`;
export const MESH_BOT_EZLO_PI_NEW = '/ezlo/automation/ezlopi/new';
export const MESH_BOT_LOCAL_NEW = '/ezlo/automation/local/new';
export const MESH_BOT_CLOUD_NEW = '/ezlo/automation/cloud/new';
export const MESH_BOT_NOTIFICATION_NEW = '/ezlo/automation/notification/new';
export const MESH_BOT_INTERACTION_NEW = '/ezlo/automation/interaction/new';
export const MESH_BOT_EDIT = (id, type) => `/ezlo/automation/${type}/${id}`;
export const NOTIFICATIONS = '/ezlo/notifications';
export const NOTIFICATIONS_CREATE = '/ezlo/notifications/create';
export const NOTIFICATIONS_EDIT = (id) => `/ezlo/notifications/${id}`;
export const EZLOS = '/ezlos';
export const EZLO_NEW = '/ezlo/new';
export const EZLO_CONTROLLER_GROUPS = '/ezlo/groups';
export const EZLO_CUSTOMIZATION = '/ezlo/customization';
export const EZLO_DASHBOARD = '/ezlo/dashboard';
export const EZLO_MESHBOTS = '/ezlo/meshbots';
export const LOGIN = '/login';
export const REGISTER = '/register';
export const EMAIL_CONFIRMATION = '/email-confirmation';
export const RESET_PASSWORD = '/reset-password';
export const FORGOT_PASSWORD = '/forgot-password';
export const RESEND_CONFIRMATION = '/resend-confirmation';
export const MAIN = '/';
export const POLICIES_AGREEMENT_PATH = '/policies-agreement';
export const EZLOGIC2 = 'ezlogic2';
export const EZLOGIC6 = 'ezlogic6';
export const EZLOGIC = 'ezlogic';
export const HINTS_MESHBOT_URL = 'https://help.mios.com/docs/automation/quickstart/basics/what-is-a-meshbot/';
export const HINTS_TRIGGER_URL = 'https://help.mios.com/docs/automation/triggers/overview/';
export const AUTO_CREATE_ACTIVE = 1;
export const PAGE_PATHS_BY_ID_FOR_REDIRECT_WHILE_LOGIN = {
    [MENU_ITEMS_IDS.DASHBOARD]: EZLO_DASHBOARD,
    [MENU_ITEMS_IDS.MESHBOTS]: EZLO_MESHBOTS,
    [MENU_ITEMS_IDS.CUSTOMIZATION]: EZLO_CUSTOMIZATION,
};
export const GET_CREATE_USER_URL = () => {
    return oemManager.oem.getMMSCreateUserUrl();
};

export const GET_UPDATE_USER_URL = (PK_User) => {
    return oemManager.oem.getMMSUpdateAccountUserUrl(PK_User);
};

export const GET_DELETE_USER_URL = (PK_Account_Parent, PK_User) => {
    return oemManager.oem.getMMSDeleteUserUrl(PK_Account_Parent, PK_User);
};

export const ALLOW_ACCESS_ON_CONTROLLERS_URL = (PK_User) => {
    return oemManager.oem.getMMSUserPermissionsUrl(PK_User);
};

// TODO: REMOVE, the same as USER_DETAILS_ - let's replace by user info
export const GET_USER_DETAILS_URL = (PK_User) => {
    const PK_Oem = oemManager.oem.getPkOem();
    const params = { PK_Oem };

    return oemManager.oem.getMMSGetAccountUserUrl(PK_User, params);
};

export const GET_CHANGE_USER_PASSWORD_URL = (changePasswordData) => {
    const { username, NewPassword, CurrentPassword } = changePasswordData;

    const hashCS = sha1(username.toLowerCase() + CurrentPassword + HASH_STRING);
    const hash = sha1(username + CurrentPassword + HASH_STRING);

    const PK_Oem = oemManager.oem.getPkOem();
    const params = { SHA1Password: hash, NewPassword, SHA1PasswordCS: hashCS, PK_Oem };

    return oemManager.oem.getMMSChangeUserPasswordUrl(username, params);
};

export const GET_RESEND_EMAIL_URL = (PK_User) => {
    return oemManager.oem.getMMSGetResendEmailUrl(PK_User);
};

export const GET_ALL_INSTALLATIONS_URL = (PK_Account) => {
    return oemManager.oem.getMMSInstallationsUrl(PK_Account);
};

export const GET_GET_OR_UPDATE_MAILING_BILLING_ADDRESSES_URL = (PK_Account) => {
    return oemManager.oem.getMMSMailingBillingUrl(PK_Account);
};

export const UPDATE_INSTALLATION_URL = (PK_Account, PK_Installation) => {
    return oemManager.oem.getMMSInstallationUrl(PK_Account, PK_Installation);
};

export const GET_ADDRESS_ELEMENT_URL = (type, PK_Country, PK_Region) => {
    const ADDRESS_URLS = {
        COUNTRIES: oemManager.oem.getMMSCountriesUrl(),
        REGIONS: oemManager.oem.getMMSRegionsUrl(PK_Country),
        CITIES: oemManager.oem.getMMSCitiesUrl(PK_Country, PK_Region),
    };

    return ADDRESS_URLS[type];
};

export const EZLOGIC_KNOWN_DOMAINS = {
    [EZLOGIC_MIOS_COM]: 'ezlogic.mios.com',
    [EZLOGIC_QA_MIOS_COM]: 'ezlogic-qa.mios.com',
    [QA_EZLOGIC_MIOS_COM]: 'qa-ezlogic.mios.com',
    [EZLOGIC_STG_MIOS_COM]: 'ezlogic-stg.mios.com',
    [STG_EZLOGIC_MIOS_COM]: 'stg-ezlogic.mios.com',
    [EZLOGIC2_URL]: 'ezlogic2.mios.com',
    [EZLOGIC3_URL]: 'ezlogic3.mios.com',
    [EZLOGIC4_URL]: 'ezlogic4.mios.com',
    [EZLOGIC5_URL]: 'ezlogic5.mios.com',
    [EZLOGIC6_URL]: 'ezlogic6.mios.com',
    [EZLOGIC7_URL]: 'ezlogic7.mios.com',
    [EZLOGIC8_URL]: 'ezlogic8.mios.com',
    [EZLOGIC9_URL]: 'ezlogic9.mios.com',
    [EZLOGIC10_URL]: 'ezlogic10.mios.com',
    [PROTECT_MIOS_COM]: 'protect.mios.com',
    [PROTECT_QA_MIOS_COM]: 'protect-qa.mios.com',
    [PROTECT_STG_MIOS_COM]: 'protect-stg.mios.com',
    [RESIDENT_MIOS_COM]: 'resident.mios.com',
    [RESIDENT_QA_MIOS_COM]: 'resident-qa.mios.com',
    [RESIDENT_STG_MIOS_COM]: 'resident-stg.mios.com',
    [HORUSEXPERT_COM]: 'horusexpert.com',
    [WWW_HORUSEXPERT_COM]: 'www.horusexpert.com',
    [EZLOGIC_HORUS_STG_MIOS_COM]: 'ezlogic-horus-stg.mios.com',
    [EZLOGIC_HORUS_MIOS_COM]: 'ezlogic-horus.mios.com',
};

export const EZLOPI_AUTOMATION_PATH = '/ezlo/automation/ezlopi/new';
export const EZLOPI_DYNAMIC_URL_REGEX = /^\/ezlo\/automation\/ezlopi\/[a-f0-9]+$/i;

export const UNSUPPORTED_PAGES_URLS = [
    '/ezlo/variables',
    '/ezlo/expressions',
    '/ezlo/virtualDevices/create',
    '/ezlo/device-associations',
    '/ezlo/automation/local/new',
];
